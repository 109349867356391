const meta = { authorize: [] }

export default [
    {
        path: '/doctor/activate',
        name: 'doctor-activate',
        props: route => ({ query: route.query.activation }),
        meta,
        component: () => import('@molecules/m-activation'),
    },
    {
        path: '/users/email/apply',
        name: 'users-email-apply',
        props: route => ({ query: route.query.apply }),
        meta,
        component: () => import('@molecules/m-doctor-email-apply'),
    },
    {
        path: '/patient/activate',
        name: 'patient-activate',
        props: route => ({
            query: route.query.activation,
            lang: route.query.lang,
        }),
        meta,
        component: () => import('@atoms/a-patient-activation'),
    },
    {
        path: '/patient/reset-password',
        name: 'patient-reset-password',
        props: route => ({
            query: route.query.activation,
            lang: route.query.lang,
        }),
        meta,
        component: () => import('@atoms/a-patient-reset-password'),
    },
    {
        path: '/distributor/activate',
        name: 'distributor-activate',
        props: route => ({ query: route.query.activate }),
        meta,
        component: () => import('@molecules/m-activation'),
    },
    {
        path: '/medical-facility-manager/activate',
        name: 'manager-activate',
        props: route => ({ query: route.query.activate }),
        meta,
        component: () => import('@molecules/m-activation'),
    },
    {
        path: '/manager-failed-activation',
        name: 'manager-failed-activation',
        meta,
        component: () => import('@molecules/m-manager-failed-activation'),
    },
    {
        path: '/terms-of-use',
        name: 'terms-of-use',
        props: route => ({
            lang: route.query.lang,
            role: route.query.role,
        }),
        meta,
        component: () => import('@organisms/o-terms-of-use'),
    },
    {
        path: '/delete',
        name: 'delete',
        props: route => ({
            code: route.query.code,
        }),
        meta,
        component: () => import('@organisms/o-account-delete'),
    },
    {
        path: '/delete-complete',
        name: 'complete',
        meta,
        component: () => import('@molecules/m-delete-complete'),
    },
    {
        path: '/login',
        component: () => import('@pages/p-login'),
        meta,
        children: [
            {
                path: '/subscription/activate',
                name: 'subscription-activate',
                props: route => ({
                    query: route.query.token,
                    lang: route.query.lang,
                }),
                meta,
                component: () => import('@molecules/m-subscription-activate'),
            },
            {
                path: '',
                name: 'login-welcome',
                meta,
                component: () => import('@molecules/m-form-auth-user'),
            },
            {
                path: '/registration',
                name: 'registration',
                props: route => ({
                    code: route.query.code,
                    facility: route.query.facility,
                }),
                meta,
                component: () => import('@organisms/o-auth'),
            },
            // {
            //     path: '/delete',
            //     name: 'delete',
            //     props: route => ({
            //         code: route.query.code,
            //     }),
            //     meta,
            //     component: () => import('@organisms/o-account-delete'),
            // },
            // {
            //     path: '/complete',
            //     name: 'complete',
            //     meta,
            //     component: () => import('@molecules/m-delete-complete'),
            // },
            {
                path: '/recovery/:email',
                name: 'recovery',
                props: route => ({
                    code: route.query.code,
                    facility: route.query.facility,
                }),
                meta,
                component: () => import('@molecules/m-form-recovery'),
            },
            {
                path: '/password/confirm',
                props: route => ({ query: route.query.token }),
                name: 'new-password',
                meta,
                component: () => import('@molecules/m-form-new-password'),
            },
            {
                path: '/password/manager',
                props: route => ({ query: route.query.token }),
                name: 'new-password-manager',
                meta,
                component: () =>
                    import('@molecules/m-form-new-password-manager'),
            },
        ],
    },
]
