import { $axios, $callAxios } from './base-service'
import store from '@state/store'

const helper = {
    getHeaders() {
        if (store.state.auth.isRememberMe) {
            return {
                'x-token-access': store.state.auth.access_token,
                'x-token-refresh': store.state.auth.refresh_token,
            }
        } else {
            return {
                'x-token-access': store.state.auth.access_token,
                'x-token-refresh': store.state.auth.refresh_token,
                'x-token-storage': store.state.auth.storage_token,
            }
        }
    },
}

export default {
    startCall(data, event) {
        const resource = '/video/calls/start/'
        return $axios.post(
            `${resource}${data}?event=${event}`,
            {},
            {
                headers: helper.getHeaders(),
            }
        )
    },

    getContacts() {
        const resource = '/users/contacts'
        return $axios.get(`${resource}`, {
            headers: helper.getHeaders(),
        })
    },
    getTurnServer() {
        return $callAxios.get(`/video/turns`)
    },
}
