import { render, staticRenderFns } from "./o-notifications-api.vue?vue&type=template&id=1ccee17e&scoped=true"
import script from "./o-notifications-api.vue?vue&type=script&lang=js"
export * from "./o-notifications-api.vue?vue&type=script&lang=js"
import style0 from "./o-notifications-api.vue?vue&type=style&index=0&id=1ccee17e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ccee17e",
  null
  
)

export default component.exports