import { getFiles } from '@services/base-service'
import store from '@state/store'
import {
    getMeasurements,
    getSubscriptionSummary,
    getSubscriptionSummaryAll,
    getSubscriptionDetails,
    getSubscriptionAdministratorDetails,
    getSubscriptionRanked,
    getSubscriptionAdministratorRanked
} from '@services/subscription-services'
import moment from 'moment'
import Vue from 'vue'
import {
    WEIGHT_KG_TYPE_ID,
    WEIGHT_LB_TYPE_ID,
    BLOOD_GLUCOSE_LEVEL_MEASUREMENT_TYPE_ID,
    BLOOD_GLUCOSE_LEVEL_MEASUREMENT_MG_TYPE_ID,
    DIASTOLIC_MEASURE_ID,
    FHR_MEASUREMENT_TYPE_ID,
    OXYGEN_MEASURE_ID,
    OXYGEN_MEASUREMENT_TYPE_ID,
    PRESSURE_MEASUREMENT_TYPE_ID,
    PULSE_MEASURE_ID,
    SYSTOLIC_MEASURE_ID,
    TEMPERATURE_MEASUREMENT_TYPE_ID,
} from '@constants/measurementsIDs'

export const state = {
    measurementsList: [],
    subscriptionList: [],
    subscriptionListAdministrator: [],
    patientGenderData: [],
    subscriptionCopied: {},
    subscriptionEdited: {},
    subscriptionDrafted: {},
    subscriptionRanked: [],
    changedDate: false,
}

export const mutations = {
    GET_MEASUREMENTS_LIST(state, payload) {
        state.measurementsList = payload
    },
    GET_SUBSCRIPTION_LIST(state, payload) {
        state.subscriptionList = payload
    },
    GET_SUBSCRIPTION_ADMINISTRATOR_LIST(state, payload) {
        state.subscriptionListAdministrator = payload
    },
    GET_PATIENTS_ALL_GENDER(state, payload) {
        state.patientGenderData = payload
    },
    SET_SUBSCRIPTION_COPIED(state, payload) {
        state.subscriptionCopied = payload
    },
    SET_SUBSCRIPTION_EDITED(state, payload) {
        state.subscriptionEdited = payload
    },
    SET_SUBSCRIPTION_DRAFTED(state, payload) {
        state.subscriptionDrafted = payload
    },
    SET_SUBSCRIPTION_RANKED(state, payload) {
        state.subscriptionRanked = payload
    },
    SET_CONTACTS_AVATARS(state, { profile, avatar }) {
        Vue.set(profile, 'avatar', avatar)
    },
    CHANGE_DATE(state, payload) {
        state.changedDate = payload
    },
}

export const getters = {}

export const actions = {
    getAllMeasurementsList({ commit }) {
        getMeasurements().then(response => {
            commit('GET_MEASUREMENTS_LIST', response.data)
        })
    },
    getSubscriptionList({ commit }, isTask = false) {
        const profileData = store.getters['common/getUserData']
        let getSubscriptions
        if (profileData.doctor_data.subscription_administrator || isTask) {
            getSubscriptions = getSubscriptionSummaryAll(
                profileData.doctor_data.medical_facility_id
            )
        } else {
            getSubscriptions = getSubscriptionSummary()
        }

        return getSubscriptions
            .then(response => {
                if (response.data.patients) {
                    response.data.patients.forEach(profile => {
                        if (profile.avatar) {
                            getFiles(profile.avatar.min).then(resp => {
                                commit('SET_CONTACTS_AVATARS', {
                                    profile,
                                    avatar: resp,
                                })
                            })
                        }
                    })
                }

                if (response.data.patient_gender_data) {
                    commit(
                        'GET_PATIENTS_ALL_GENDER',
                        response.data.patient_gender_data
                    )
                }
                if (isTask) {
                    commit(
                        'GET_SUBSCRIPTION_ADMINISTRATOR_LIST',
                        response.data.patients
                    )
                }
                commit('GET_SUBSCRIPTION_LIST', response.data.patients)
            })
            .catch(e => {
                console.error(e)
            })
    },
    setSubscriptionCopied({ commit }, id) {
        const profileData = store.getters['common/getUserData']
        let getDetails
        if (profileData.doctor_data.subscription_administrator) {
            getDetails = getSubscriptionAdministratorDetails(id)
        } else {
            getDetails = getSubscriptionDetails(id)
        }
        getDetails.then(response => {
            let appointments = response.data.appointments
            let subscription = {}

            //IMPORTANT: Edited subscription steps' keys should be the same as in subscription
            if (appointments.medications.length) {
                appointments.medications.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.medicationList) {
                            subscription.medicationList = []
                        }

                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }

                        let medication = {
                            name: item.name,
                            dose: item.dose,
                            dose_at_time: item.dose_at_time
                                ? item.dose_at_time
                                : null,
                            note: item.note ? item.note : '',
                            startDate: '',
                            endDate: '',
                            periodicity: setPeriodicity(item.days_periodicity),
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                            description: item.description
                                ? item.description
                                : '',
                            copied: true,
                        }
                        subscription.medicationList.push(medication)
                    }
                })
            }
            if (appointments.diaries.length) {
                appointments.diaries.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.diaryList) {
                            subscription.diaryList = []
                        }

                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }

                        let diary = {
                            name: item.description,
                            periodicity: setPeriodicity(item.days_periodicity),
                            receptions_per_day: item.times_per_day.toString(),
                            startDate: '',
                            endDate: '',
                            action_timestamps: item.appointments_time_array,
                            copied: true,
                        }
                        subscription.diaryList.push(diary)
                    }
                })
            }

            if (appointments.measurements.length) {
                appointments.measurements.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.measurementList) {
                            subscription.measurementList = []
                        }

                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }

                        let measurement = {
                            name: item.configuration.name,
                            periodicity: setPeriodicity(item.days_periodicity),
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                            startDate: '',
                            endDate: '',
                            description: item.comment ? item.comment : '',
                            copied: true,
                        }

                        if (
                            item.configuration.id ===
                            TEMPERATURE_MEASUREMENT_TYPE_ID
                        ) {
                            //temperature
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )

                            measurement.tempLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: true,
                                },
                            ]
                        } else if (
                            item.configuration.id ===
                            PRESSURE_MEASUREMENT_TYPE_ID
                        ) {
                            //pressure
                            if (!measurement.pressLimits) {
                                measurement.pressLimits = []
                            }
                            item.configuration.measures.forEach(measure => {
                                if (measure.id === SYSTOLIC_MEASURE_ID) {
                                    //systolic
                                    measurement.pressLimits[0] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: true,
                                    }
                                }
                                if (measure.id === DIASTOLIC_MEASURE_ID) {
                                    //diastolic
                                    measurement.pressLimits[1] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: true,
                                    }
                                }
                                if (measure.id === PULSE_MEASURE_ID) {
                                    //pulse
                                    measurement.pressLimits[2] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: true,
                                    }
                                }
                            })
                        } else if (
                            item.configuration.id === OXYGEN_MEASUREMENT_TYPE_ID //oxygen saturation
                        ) {
                            if (!measurement.oxygenSaturationLimits) {
                                measurement.oxygenSaturationLimits = []
                            }
                            item.configuration.measures.forEach(measure => {
                                if (measure.id === OXYGEN_MEASURE_ID) {
                                    //oxygen
                                    measurement.oxygenSaturationLimits[0] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        filled: true,
                                    }
                                }
                                if (measure.id === PULSE_MEASURE_ID) {
                                    //pulse
                                    measurement.oxygenSaturationLimits[1] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: true,
                                    }
                                }
                            })
                        } else if (
                            item.configuration.id ===
                            BLOOD_GLUCOSE_LEVEL_MEASUREMENT_TYPE_ID //blood glucose mmol/L
                        ) {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )
                            measurement.bloodGlucoseLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: true,
                                    measure_unit: 'mmol/L',
                                },
                            ]
                        } else if (
                            item.configuration.id ===
                            BLOOD_GLUCOSE_LEVEL_MEASUREMENT_MG_TYPE_ID //blood glucose mg/dl
                        ) {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )
                            measurement.bloodGlucoseLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: true,
                                    measure_unit: 'mg/dl',
                                },
                            ]
                        } else if (
                            item.configuration.id === WEIGHT_KG_TYPE_ID //weight kg
                        ) {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )
                            measurement.weightLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: true,
                                    measure_unit: 'kg',
                                },
                            ]
                        } else if (
                            item.configuration.id === WEIGHT_LB_TYPE_ID //weight lb
                        ) {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )
                            measurement.weightLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: true,
                                    measure_unit: 'lb',
                                },
                            ]
                        } else if (
                            item.configuration.id === FHR_MEASUREMENT_TYPE_ID //Fetal heart rate
                        ) {
                            measurement.fetalLimits = [
                                {
                                    normal_min: item.configuration.measures[0].units[0].normal_min.toString(),
                                    normal_max: item.configuration.measures[0].units[0].normal_max.toString(),
                                    critical_min: item.configuration.measures[0].units[0].critical_min.toString(),
                                    critical_max: item.configuration.measures[0].units[0].critical_max.toString(),
                                    filled: true,
                                },
                            ]
                        } else if (item.configuration.name === 'Ecg') {
                            measurement.ecg_duration =
                                item.configuration.duration
                        }

                        subscription.measurementList.push(measurement)
                    }
                })
            }

            if (appointments.calls.length) {
                appointments.calls.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.videoCallList) {
                            subscription.videoCallList = []
                        }

                        let call = {
                            periodicity: setPeriodicity(item.days_periodicity),
                            startDate: '',
                            endDate: '',
                            custom_dates: '',
                            copied: true,
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                        }
                        subscription.videoCallList.push(call)
                    }
                })
            }

            function setFloat(item) {
                if (parseInt(item) === item) {
                    return `${item}.0`
                } else return item.toString()
            }

            function setPeriodicity(time) {
                if (time === 1) {
                    return 'daily'
                } else if (time === 2) {
                    return 'every_other_day'
                } else {
                    return 'custom'
                }
            }

            commit('SET_SUBSCRIPTION_COPIED', subscription)
        })
    },
    setSubscriptionEdited({ commit, rootState }, id) {
        const profileData = store.getters['common/getUserData']
        let getDetails
        if (profileData.doctor_data.subscription_administrator) {
            getDetails = getSubscriptionAdministratorDetails(id)
        } else {
            getDetails = getSubscriptionDetails(id)
        }
        getDetails.then(response => {
            let subscriptionData = response.data
            let appointments = subscriptionData.appointments
            let subscription = {}

            rootState.doctor.contactsProfiles.forEach(item => {
                if (item.id === subscriptionData.patient_id) {
                    subscription.patient = {
                        name: `${item.name} ${item.surname}`,
                        gender: item.patient_data.gender,
                        age: `${moment().diff(
                            item.patient_data.birthday,
                            'years'
                        )}`,
                        email: item.email,
                    }
                }
            })
            //IMPORTANT: Edited subscription steps' keys should be the same as in subscription
            subscription.subscriptionPostResult = {
                id: subscriptionData.id,
                doctor_id: subscriptionData.doctor_id,
                is_subscription_administrator:
                    subscriptionData.is_subscription_administrator,
                facility_id: subscriptionData.medical_facility_id,
                name: subscriptionData.name,
                patient_id: subscriptionData.patient_id,
                end_date: subscriptionData.end_date,
                start_date: subscriptionData.start_date,
                control_period: subscriptionData?.control_period,
                state: subscriptionData?.state,
            }

            if (appointments.medications.length) {
                appointments.medications.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.medicationList) {
                            subscription.medicationList = []
                        }
                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }
                        let timestamps = item.action_timestamps.map(
                            time => time * 1000
                        )

                        let moments = timestamps.map(d =>
                                moment(new Date(d).setHours('00', '00', '00'))
                            ),
                            momentsDate = timestamps.map(d =>
                                moment(
                                    new Date(d).setHours('00', '00', '00')
                                ).valueOf()
                            ),
                            startDate = moment.min(moments),
                            endDate = moment.max(moments)

                        let medication = {
                            medicationId: item.id,
                            name: item.name,
                            dose: item.dose,
                            dose_at_time: item.dose_at_time
                                ? item.dose_at_time
                                : null,
                            note: item.note ? item.note : '',
                            startDate: moment(startDate).valueOf(),
                            endDate: moment(endDate).valueOf(),
                            periodicity: setPeriodicity(item.days_periodicity),
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                            description: item.description
                                ? item.description
                                : '',
                            custom_dates: '',
                        }

                        if (item.days_periodicity === 3) {
                            medication.custom_dates = [...new Set(momentsDate)]
                        }

                        subscription.medicationList.push(medication)
                    }
                })
            }

            if (appointments.diaries.length) {
                appointments.diaries.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.diaryList) {
                            subscription.diaryList = []
                        }
                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }
                        let timestamps = item.action_timestamps.map(
                            time => time * 1000
                        )

                        let moments = timestamps.map(d =>
                                moment(new Date(d).setHours('00', '00', '00'))
                            ),
                            momentsDate = timestamps.map(d =>
                                moment(
                                    new Date(d).setHours('00', '00', '00')
                                ).valueOf()
                            ),
                            startDate = moment.min(moments),
                            endDate = moment.max(moments)

                        let diary = {
                            diaryId: item.id,
                            name: item.description,
                            periodicity: setPeriodicity(item.days_periodicity),
                            startDate: moment(startDate).valueOf(),
                            endDate: moment(endDate).valueOf(),
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                            custom_dates: '',
                        }

                        if (item.days_periodicity === 3) {
                            diary.custom_dates = [...new Set(momentsDate)]
                        }

                        subscription.diaryList.push(diary)
                    }
                })
            }

            if (appointments.measurements.length) {
                appointments.measurements.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.measurementList) {
                            subscription.measurementList = []
                        }
                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }

                        let timestamps = item.action_timestamps.map(
                            time => time * 1000
                        )

                        let moments = timestamps.map(d =>
                                moment(new Date(d).setHours('00', '00', '00'))
                            ),
                            momentsDate = timestamps.map(d =>
                                moment(
                                    new Date(d).setHours('00', '00', '00')
                                ).valueOf()
                            ),
                            startDate = moment.min(moments),
                            endDate = moment.max(moments)

                        let measurement = {
                            measurementId: item.id,
                            name: item.configuration.name,
                            periodicity: setPeriodicity(item.days_periodicity),
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                            startDate: moment(startDate).valueOf(),
                            endDate: moment(endDate).valueOf(),
                            description: item.comment ? item.comment : '',
                            custom_dates: '',
                            tempLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            pressLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            oxygenSaturationLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            bloodGlucoseLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            weightLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            fetalLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                        }

                        if (item.days_periodicity === 3) {
                            measurement.custom_dates = [...new Set(momentsDate)]
                        }

                        if (item.configuration.name === 'Temperature') {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )

                            measurement.tempLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: true,
                                },
                            ]
                        } else if (
                            item.configuration.id === FHR_MEASUREMENT_TYPE_ID //Fetal heart rate
                        ) {
                            measurement.fetalLimits = [
                                {
                                    normal_min: item.configuration.measures[0].units[0].normal_min.toString(),
                                    normal_max: item.configuration.measures[0].units[0].normal_max.toString(),
                                    critical_min: item.configuration.measures[0].units[0].critical_min.toString(),
                                    critical_max: item.configuration.measures[0].units[0].critical_max.toString(),
                                    filled: true,
                                },
                            ]
                        } else if (item.configuration.name === 'Pressure') {
                            if (!measurement.pressLimits) {
                                measurement.pressLimits = []
                            }
                            item.configuration.measures.forEach(measure => {
                                if (measure.name === 'Systolic') {
                                    measurement.pressLimits[0] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: true,
                                    }
                                }
                                if (measure.name === 'Diastolic') {
                                    measurement.pressLimits[1] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: true,
                                    }
                                }
                                if (measure.name === 'Pulse') {
                                    measurement.pressLimits[2] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: true,
                                    }
                                }
                            })
                        } else if (
                            item.configuration.name === 'Oxygen Saturation'
                        ) {
                            if (!measurement.oxygenSaturationLimits) {
                                measurement.oxygenSaturationLimits = []
                            }
                            item.configuration.measures.forEach(measure => {
                                if (measure.name === 'Oxygen') {
                                    measurement.oxygenSaturationLimits[0] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        filled: true,
                                    }
                                }
                                if (measure.name === 'Pulse') {
                                    measurement.oxygenSaturationLimits[1] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: true,
                                    }
                                }
                            })
                        } else if (
                            item.configuration.name === 'Fetal Heart Rate'
                        ) {
                            measurement.fetalLimits = [
                                {
                                    normal_min: item.configuration.measures[0].units[0].normal_min.toString(),
                                    normal_max: item.configuration.measures[0].units[0].normal_max.toString(),
                                    critical_min: item.configuration.measures[0].units[0].critical_min.toString(),
                                    critical_max: item.configuration.measures[0].units[0].critical_max.toString(),
                                    filled: true,
                                },
                            ]
                        } else if (
                            item.configuration.name === 'Fetal Heart Rate'
                        ) {
                            measurement.fetalLimits = [
                                {
                                    normal_min: item.configuration.measures[0].units[0].normal_min.toString(),
                                    normal_max: item.configuration.measures[0].units[0].normal_max.toString(),
                                    critical_min: item.configuration.measures[0].units[0].critical_min.toString(),
                                    critical_max: item.configuration.measures[0].units[0].critical_max.toString(),
                                    filled: true,
                                },
                            ]
                        } else if (
                            item.configuration.name === 'Blood glucose level'
                        ) {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )
                            let measure_unit =
                                item.configuration.measures[0].units[0].name

                            measurement.bloodGlucoseLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: true,
                                    measure_unit: measure_unit,
                                },
                            ]
                        } else if (item.configuration.name === 'Weight') {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )
                            let measure_unit =
                                item.configuration.measures[0].units[0].name

                            measurement.weightLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: true,
                                    measure_unit: measure_unit,
                                },
                            ]
                        } else if (item.configuration.name === 'Ecg') {
                            measurement.ecg_duration =
                                item.configuration.duration
                        }

                        subscription.measurementList.push(measurement)
                    }
                })
            }

            if (appointments.calls.length) {
                appointments.calls.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.videoCallList) {
                            subscription.videoCallList = []
                        }

                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }

                        let timestamps = item.action_timestamps.map(
                            time => time * 1000
                        )

                        let moments = timestamps.map(d =>
                                moment(new Date(d).setHours('00', '00', '00'))
                            ),
                            momentsDate = timestamps.map(d =>
                                moment(
                                    new Date(d).setHours('00', '00', '00')
                                ).valueOf()
                            ),
                            startDate = moment.min(moments),
                            endDate = moment.max(moments)

                        let call = {
                            videoId: item.id,
                            periodicity: setPeriodicity(item.days_periodicity),
                            action_timestamps: item.appointments_time_array,
                            startDate: moment(startDate).valueOf(),
                            endDate: moment(endDate).valueOf(),
                            schedules: {},
                            custom_dates: '',
                            receptions_per_day: item.times_per_day.toString(),
                        }

                        if (item.days_periodicity === 3) {
                            call.custom_dates = [...new Set(momentsDate)]
                        }
                        subscription.videoCallList.push(call)
                    }
                })
            }

            // if (appointments.supported_calls.length) {
            //     appointments.supported_calls.forEach(item => {
            //         if (item.state !== 'cancelled') {
            //             if (!subscription.supportCallList) {
            //                 subscription.supportCallList = []
            //             }

            //             for (
            //                 let i = 0;
            //                 i < item.appointments_time_array.length;
            //                 i++
            //             ) {
            //                 let timeArr = item.appointments_time_array[i].split(
            //                     ':'
            //                 )
            //                 timeArr[0] = parseInt(timeArr[0])
            //                 timeArr[0] =
            //                     timeArr[0] === 0 || timeArr[0] < 10
            //                         ? `0${timeArr[0]}`
            //                         : timeArr[0]
            //                 item.appointments_time_array[
            //                     i
            //                 ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
            //             }

            //             let timestamps = item.action_timestamps.map(
            //                 time => time * 1000
            //             )

            //             let moments = timestamps.map(d =>
            //                     moment(new Date(d).setHours('00', '00', '00'))
            //                 ),
            //                 momentsDate = timestamps.map(d =>
            //                     moment(
            //                         new Date(d).setHours('00', '00', '00')
            //                     ).valueOf()
            //                 ),
            //                 startDate = moment.min(moments),
            //                 endDate = moment.max(moments)

            //             let call = {
            //                 videoId: item.id,
            //                 periodicity: setPeriodicity(item.days_periodicity),
            //                 action_timestamps: item.appointments_time_array,
            //                 startDate: moment(startDate).valueOf(),
            //                 endDate: moment(endDate).valueOf(),
            //                 schedules: {},
            //                 custom_dates: '',
            //                 receptions_per_day: item.times_per_day.toString(),
            //             }

            //             if (item.days_periodicity === 3) {
            //                 call.custom_dates = [...new Set(momentsDate)]
            //             }
            //             subscription.supportCallList.push(call)
            //         }
            //     })
            // }

            function setPeriodicity(code) {
                switch (code) {
                    case 1:
                        return 'daily'
                    case 2:
                        return 'every_other_day'
                    case 3:
                        return 'custom'
                }
            }

            function setFloat(item) {
                if (parseInt(item) === item) {
                    return `${item}.0`
                } else return item.toString()
            }

            commit('SET_SUBSCRIPTION_EDITED', subscription)
        })
    },
    setSubscriptionDrafted({ commit, rootState, state }, id) {
        const profileData = store.getters['common/getUserData']
        let getDetails
        if (profileData.doctor_data.subscription_administrator) {
            getDetails = getSubscriptionAdministratorDetails(id)
        } else {
            getDetails = getSubscriptionDetails(id)
        }
        getDetails.then(response => {
            let subscriptionData = response.data
            let appointments = subscriptionData.appointments
            let subscription = {}
            // let start = subscriptionData.start_date
            // let end = subscriptionData.end_date

            state.subscriptionList.forEach(item => {
                if (item.id === subscriptionData.patient_id) {
                    subscription.patient = {
                        name: `${item.name} ${item.surname}`,
                        gender: item.patient_data?.gender,
                        age: `${moment().diff(
                            item.patient_data?.birthday,
                            'years'
                        )}`,
                        email: item.email,
                    }
                }
            })

            //IMPORTANT: Edited subscription steps' keys should be the same as in subscription
            subscription.subscriptionPostResult = {
                id: subscriptionData.id,
                doctor_id: subscriptionData.doctor_id,
                facility_id: subscriptionData.medical_facility_id,
                name: subscriptionData.name,
                patient_id: subscriptionData.patient_id,
                end_date: subscriptionData.end_date,
                start_date: subscriptionData.start_date,
                control_period: subscriptionData?.control_period,
                state: subscriptionData?.state,
            }

            if (appointments.medications.length) {
                appointments.medications.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.medicationList) {
                            subscription.medicationList = []
                        }
                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }
                        let timestamps = item.action_timestamps.map(
                            time => time * 1000
                        )

                        let moments = timestamps.map(d =>
                                moment(new Date(d).setHours('00', '00', '00'))
                            ),
                            momentsDate = timestamps.map(d =>
                                moment(
                                    new Date(d).setHours('00', '00', '00')
                                ).valueOf()
                            ),
                            startDate = moment.min(moments),
                            endDate = moment.max(moments)

                        let medication = {
                            medicationId: item.id,
                            name: item.name,
                            dose: item.dose,
                            control_period: item?.control_period,
                            state: item?.state,
                            dose_at_time: item.dose_at_time
                                ? item.dose_at_time
                                : null,
                            note: item.note ? item.note : '',
                            // startDate: moment(start).valueOf(),
                            // endDate: moment(end).valueOf(),
                            startDate: '',
                            endDate: '',
                            periodicity: setPeriodicity(item.days_periodicity),
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                            description: item.description
                                ? item.description
                                : '',
                            custom_dates: '',
                        }

                        if (item.days_periodicity === 3) {
                            medication.custom_dates = [...new Set(momentsDate)]
                        }

                        subscription.medicationList.push(medication)
                    }
                })
            }

            if (appointments.diaries.length) {
                appointments.diaries.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.diaryList) {
                            subscription.diaryList = []
                        }
                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }
                        let timestamps = item.action_timestamps.map(
                            time => time * 1000
                        )

                        let moments = timestamps.map(d =>
                                moment(new Date(d).setHours('00', '00', '00'))
                            ),
                            momentsDate = timestamps.map(d =>
                                moment(
                                    new Date(d).setHours('00', '00', '00')
                                ).valueOf()
                            ),
                            startDate = moment.min(moments),
                            endDate = moment.max(moments)

                        let diary = {
                            diaryId: item.id,
                            control_period: item?.control_period,
                            state: item?.state,
                            name: item.description,
                            periodicity: setPeriodicity(item.days_periodicity),
                            // startDate: moment(startDate).valueOf(),
                            // endDate: moment(endDate).valueOf(),
                            startDate: '',
                            endDate: '',
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                            custom_dates: '',
                        }

                        if (item.days_periodicity === 3) {
                            diary.custom_dates = [...new Set(momentsDate)]
                        }

                        subscription.diaryList.push(diary)
                    }
                })
            }

            if (appointments.measurements.length) {
                appointments.measurements.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.measurementList) {
                            subscription.measurementList = []
                        }
                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }

                        let timestamps = item.action_timestamps.map(
                            time => time * 1000
                        )

                        let moments = timestamps.map(d =>
                                moment(new Date(d).setHours('00', '00', '00'))
                            ),
                            momentsDate = timestamps.map(d =>
                                moment(
                                    new Date(d).setHours('00', '00', '00')
                                ).valueOf()
                            ),
                            startDate = moment.min(moments),
                            endDate = moment.max(moments)

                        let measurement = {
                            measurementId: item.id,
                            name: item.configuration.name,
                            periodicity: setPeriodicity(item.days_periodicity),
                            receptions_per_day: item.times_per_day.toString(),
                            action_timestamps: item.appointments_time_array,
                            // startDate: moment(startDate).valueOf(),
                            // endDate: moment(endDate).valueOf(),
                            startDate: '',
                            endDate: '',
                            description: item.comment ? item.comment : '',
                            custom_dates: '',
                            tempLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            pressLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            oxygenSaturationLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            bloodGlucoseLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            weightLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                            fetalLimits: [
                                {
                                    normal_min: null,
                                    normal_max: null,
                                    critical_min: null,
                                    critical_max: null,
                                    filled: false,
                                },
                            ],
                        }

                        if (item.days_periodicity === 3) {
                            measurement.custom_dates = [...new Set(momentsDate)]
                        }

                        if (item.configuration.name === 'Temperature') {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )

                            measurement.tempLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: false,
                                },
                            ]
                        } else if (
                            item.configuration.id === FHR_MEASUREMENT_TYPE_ID //Fetal heart rate
                        ) {
                            measurement.fetalLimits = [
                                {
                                    normal_min: item.configuration.measures[0].units[0].normal_min.toString(),
                                    normal_max: item.configuration.measures[0].units[0].normal_max.toString(),
                                    critical_min: item.configuration.measures[0].units[0].critical_min.toString(),
                                    critical_max: item.configuration.measures[0].units[0].critical_max.toString(),
                                    filled: false,
                                },
                            ]
                        } else if (item.configuration.name === 'Pressure') {
                            if (!measurement.pressLimits) {
                                measurement.pressLimits = []
                            }
                            item.configuration.measures.forEach(measure => {
                                if (measure.name === 'Systolic') {
                                    measurement.pressLimits[0] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: false,
                                    }
                                }
                                if (measure.name === 'Diastolic') {
                                    measurement.pressLimits[1] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: false,
                                    }
                                }
                                if (measure.name === 'Pulse') {
                                    measurement.pressLimits[2] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: false,
                                    }
                                }
                            })
                        } else if (
                            item.configuration.name === 'Oxygen Saturation'
                        ) {
                            if (!measurement.oxygenSaturationLimits) {
                                measurement.oxygenSaturationLimits = []
                            }
                            item.configuration.measures.forEach(measure => {
                                if (measure.name === 'Oxygen') {
                                    measurement.oxygenSaturationLimits[0] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        filled: false,
                                    }
                                }
                                if (measure.name === 'Pulse') {
                                    measurement.oxygenSaturationLimits[1] = {
                                        normal_min: measure.units[0].normal_min.toString(),
                                        normal_max: measure.units[0].normal_max.toString(),
                                        critical_min: measure.units[0].critical_min.toString(),
                                        critical_max: measure.units[0].critical_max.toString(),
                                        filled: false,
                                    }
                                }
                            })
                        } else if (
                            item.configuration.name === 'Fetal Heart Rate'
                        ) {
                            measurement.fetalLimits = [
                                {
                                    normal_min: item.configuration.measures[0].units[0].normal_min.toString(),
                                    normal_max: item.configuration.measures[0].units[0].normal_max.toString(),
                                    critical_min: item.configuration.measures[0].units[0].critical_min.toString(),
                                    critical_max: item.configuration.measures[0].units[0].critical_max.toString(),
                                    filled: false,
                                },
                            ]
                        } else if (
                            item.configuration.name === 'Fetal Heart Rate'
                        ) {
                            measurement.fetalLimits = [
                                {
                                    normal_min: item.configuration.measures[0].units[0].normal_min.toString(),
                                    normal_max: item.configuration.measures[0].units[0].normal_max.toString(),
                                    critical_min: item.configuration.measures[0].units[0].critical_min.toString(),
                                    critical_max: item.configuration.measures[0].units[0].critical_max.toString(),
                                    filled: false,
                                },
                            ]
                        } else if (
                            item.configuration.name === 'Blood glucose level'
                        ) {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )
                            let measure_unit =
                                item.configuration.measures[0].units[0].name

                            measurement.bloodGlucoseLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: false,
                                    measure_unit: measure_unit,
                                },
                            ]
                        } else if (item.configuration.name === 'Weight') {
                            let min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_min
                            )
                            let max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .normal_max
                            )
                            let critical_max = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_max
                            )
                            let critical_min = setFloat(
                                item.configuration.measures[0].units[0]
                                    .critical_min
                            )
                            let measure_unit =
                                item.configuration.measures[0].units[0].name

                            measurement.weightLimits = [
                                {
                                    normal_min: min,
                                    normal_max: max,
                                    critical_min: critical_min,
                                    critical_max: critical_max,
                                    filled: false,
                                    measure_unit: measure_unit,
                                },
                            ]
                        } else if (item.configuration.name === 'Ecg') {
                            measurement.ecg_duration =
                                item.configuration.duration
                        }

                        subscription.measurementList.push(measurement)
                    }
                })
            }

            if (appointments.calls.length) {
                appointments.calls.forEach(item => {
                    if (item.state !== 'cancelled') {
                        if (!subscription.videoCallList) {
                            subscription.videoCallList = []
                        }

                        for (
                            let i = 0;
                            i < item.appointments_time_array.length;
                            i++
                        ) {
                            let timeArr = item.appointments_time_array[i].split(
                                ':'
                            )
                            timeArr[0] = parseInt(timeArr[0])
                            timeArr[0] =
                                timeArr[0] === 0 || timeArr[0] < 10
                                    ? `0${timeArr[0]}`
                                    : timeArr[0]
                            item.appointments_time_array[
                                i
                            ] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
                        }

                        let timestamps = item.action_timestamps.map(
                            time => time * 1000
                        )

                        let moments = timestamps.map(d =>
                                moment(new Date(d).setHours('00', '00', '00'))
                            ),
                            momentsDate = timestamps.map(d =>
                                moment(
                                    new Date(d).setHours('00', '00', '00')
                                ).valueOf()
                            ),
                            startDate = moment.min(moments),
                            endDate = moment.max(moments)

                        let call = {
                            videoId: item.id,
                            periodicity: setPeriodicity(item.days_periodicity),
                            action_timestamps: item.appointments_time_array,
                            // startDate: moment(startDate).valueOf(),
                            // endDate: moment(endDate).valueOf(),
                            startDate: '',
                            endDate: '',
                            custom_dates: '',
                            receptions_per_day: item.times_per_day.toString(),
                        }

                        if (item.days_periodicity === 3) {
                            call.custom_dates = [...new Set(momentsDate)]
                        }
                        subscription.videoCallList.push(call)
                    }
                })
            }

            function setPeriodicity(code) {
                switch (code) {
                    case 1:
                        return 'daily'
                    case 2:
                        return 'every_other_day'
                    case 3:
                        return 'custom'
                }
            }

            function setFloat(item) {
                if (item === null) {
                    return ''
                } else {
                    if (parseInt(item) === item) {
                        return `${item}.0`
                    } else return item.toString()
                }
            }

            commit('SET_SUBSCRIPTION_DRAFTED', subscription)
        })
    },
    setRankedSubscriptions({ commit }) {
        const profileData = store.getters['common/getUserData']
        let setRanked
        if (profileData.doctor_data.subscription_administrator) {
            setRanked = getSubscriptionAdministratorRanked(
                profileData.doctor_data.medical_facility_id
            )
        } else {
            setRanked = getSubscriptionRanked()
        }
        return setRanked
            .then(response => {
                commit('SET_SUBSCRIPTION_RANKED', response.data.subscriptions)
            })
            .catch(error => {
                console.log(error)
            })
    },
    changeDuration({ commit }, bool) {
        commit('CHANGE_DATE', bool)
    },
}
