import UserSubscription from './user-subscription.js'
import UserGeneral from './user-general.js'
import { Role } from '@/constants/role.js'
const meta = { authorize: [Role.doctor] }

export default [
    {
        path: '/',
        meta,
        component: () => import('@pages/p-user-base'),
        children: [
            {
                path: '',
                name: 'user-main',
                meta,
                component: () => import('@templates/t-main'),
                beforeEnter(to, from, next) {
                    document.body.classList.add('home-page')
                    next()
                },
            },
            {
                path: '/task-info/:id',
                name: 'task-info',
                meta,
                component: () => import('@organisms/o-task-info'),
            },
            UserSubscription,
            UserGeneral,
            {
                path: '/reports',
                name: 'reports',
                meta,
                component: () => import('@templates/t-reports'),
            },
            {
                path: '/chat',
                name: 'chat',
                props: route => ({ query: route.query.id }),
                meta,
                component: () => import('@templates/t-chat'),
            },
            {
                path: '/patients',
                name: 'patients',
                props: route => ({ query: route.query.id }),
                meta,
                component: () => import('@templates/t-patients'),
            },
            {
                path: '/settings',
                name: 'settings',
                meta,
                component: () => import('@templates/t-settings'),
            },
            {
                path: '/video',
                name: 'video',
                meta,
                component: () => import('@templates/t-video'),
            },
        ],
    },
]
