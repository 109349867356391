import { Role } from '@constants/role.js'
const meta = { authorize: [Role.distributor] }

export default [
    {
        path: '/',
        meta,
        component: () => import('@pages/p-distributor-base'),
        children: [
            {
                path: '/facility-list',
                name: 'distributor-main',
                meta,
                component: () => import('@templates/t-distributor-main'),
                redirect: {
                    name: 'distributor-list',
                },
                children: [
                    {
                        path: 'all',
                        name: 'distributor-list',
                        meta,
                        component: () => import('@organisms/o-distributor-main'),
                    },
                    {
                        path: 'create-facility',
                        name: 'facility-create',
                        meta,
                        component: () => import('@organisms/o-distributor-create-facility'),
                    },
                ],
            },
            {
                path: '/facility-list/:id',
                name: 'distributor-detail',
                meta,
                component: () => import('@templates/t-distributor-detail'),
            },
            {
                path: '/facility-statistics',
                name: 'distributor-statistics',
                meta,
                component: () => import('@templates/t-distributor-statistics'),
            },
            {
                path: '/distributor-settings',
                name: 'distributor-settings',
                meta,
                component: () => import('@templates/t-distributor-settings'),
            },
        ],
    },
]
