export const OXYGEN_MEASUREMENT_TYPE_ID = '4bda1b97-5df7-44b7-a4f8-0eec72e553e2'
export const OXYGEN_MEASURE_ID = 'd1b01d1a-91de-40c7-9b6e-bf860f9fd88a'
export const PULSE_MEASURE_ID = '7828f49d-db96-4b2a-b1e1-592e8f207b77'
export const PRESSURE_MEASUREMENT_TYPE_ID =
    '4a4160e8-a77e-40bd-9b1a-c43a54c4782b'
export const SYSTOLIC_MEASURE_ID = 'bce97d78-5671-4db2-b10f-b9fbc031999a'
export const DIASTOLIC_MEASURE_ID = 'ccd5d74a-d82a-46f0-bb4a-65aaf86162ca'
export const BLOOD_GLUCOSE_LEVEL_MEASUREMENT_TYPE_ID =
    '3c4d11ee-0ce4-4acf-8871-a303cccaf651'
export const WEIGHT_KG_TYPE_ID = '1470a81f-2ad9-4025-9d13-e2811c3cb540'
export const WEIGHT_LB_TYPE_ID = 'aa9d9d3c-b4fa-4b0a-b2ed-9974a77259f2'
export const BLOOD_GLUCOSE_LEVEL_MEASUREMENT_MG_TYPE_ID =
    '31ff789c-e9fd-4ce4-b806-791f993ef9e4'
export const TEMPERATURE_MEASUREMENT_TYPE_ID =
    '80c46307-23ad-45ac-bac5-992ac544a60b'
export const FHR_MEASUREMENT_TYPE_ID = 'e60e9883-28d9-4404-be2b-be9dd36c4d02'
export const ECG_MEASUREMENT_TYPE_ID = '80c46307-23ad-45ac-bac5-992ac544a60c'
