import {
    getDoctorGeneralTaskList,
    getFiles,
    getPerformerList,
    getPatientList,
} from '@services/base-service'
import Vue from 'vue'
import { i18n } from '@/i18n'

const translate = i18n.t.bind(i18n)

export const state = {
    generalCurPage: 1,
    listLoaded: false,
    generalSorting: {
        sort: 2,
        order_by: 'planned_at',
    },
    listLimit: 25,
    generalTaskList: [],
    generalFilter: {},
    performerList: [],
    generalDates: [],
    generalCount: 0,
    patientList: [],
    list: [],
    columnsGeneral: [
        'type',
        'planned_at',
        'patient',
        'last_action',
        'state',
        'performer',
    ],
    availableGeneralItems: [
        {
            key: 'type',
            label: 'sm_0229_type',
            sortable: true,
            width: 110,
        },
        {
            key: 'planned_at',
            label: 'sm_0086_date',
            sortable: true,
            disabled: true,
            width: 140,
        },
        {
            key: 'patient',
            label: 'w_patient',
            sortable: true,
            width: 140,
        },
        {
            key: 'last_action',
            label: 'sm_0234_last_action',
            sortable: true,
            width: 110,
        },
        {
            key: 'state',
            label: 'w_status',
            sortable: true,
            width: 110,
        },
        {
            key: 'performer',
            label: 'sm_0236_performer',
            sortable: true,
            width: 110,
        },
    ],
}

export const mutations = {
    GET_AVATAR_GENERAL(state, { elem, avatar }) {
        state.generalTaskList?.map(item => {
            if (elem.id === item.id) {
                Vue.set(item.patient_data, 'avatar_min', avatar)
            }
        })
    },
    GET_AVATAR_PERFORMER(state, { elem, avatar }) {
        state.generalTaskList?.map(item => {
            if (elem.id === item.id) {
                Vue.set(item, 'performer_avatar_min', avatar)
            }
        })
    },
    SET_GENERAL_SORTING(state, data) {
        state.generalSorting.sort = data.value
        state.generalSorting.order_by = data.key
    },
    GET_GENERAL_TASK_LIST(state, data) {
        state.generalTaskList = []
        state.generalTaskList = data
    },
    GET_GENERAL_DATES(state, data) {
        state.generalDates = data
    },
    GET_GENERAL_COUNT(state, data) {
        state.generalCount = data
    },
    SET_LIST_LOADED(state, value) {
        state.listLoaded = value
    },
    SET_GENERAL_FILTER(state, data) {
        state.generalFilter = {}
        if (data.dates[0] || data.dates[1]) {
            state.generalFilter.dates = data.dates
        }
        if (data.last_actions[0] || data.last_actions[1]) {
            state.generalFilter.last_actions = data.last_actions
        }
        if (data.types) {
            state.generalFilter.types = data.types
        }
        if (data.states) {
            state.generalFilter.states = data.states
        }
        if (data.patient_id) {
            state.generalFilter.patient_id = data.patient_id
        }
        if (data.performer_id) {
            state.generalFilter.performer_id = data.performer_id
        }
    },
    SET_CURRENT_PAGE(state, page) {
        state.generalCurPage = page
    },
    UPDATE_GENERAL_COLUMNS(state, columns) {
        state.columnsGeneral = columns
    },
    SET_PERFORMER_LIST(state, data) {
        state.performerList = data
    },
    SET_PATIENT_LIST(state, data) {
        state.patientList = data
    },
}
export const getters = {
    translatedAvailableListGeneral(state) {
        // eslint-disable-next-line no-undef
        const translatedLabels = structuredClone(state.availableGeneralItems)
        return translatedLabels?.map(item => {
            item.label = translate(item.label)
            return item
        })
    },
}

export const actions = {
    // async getGeneralTaskList({ commit }, data) {
    //     await commit('SET_LIST_LOADED', false)
    //     const resp = await getDoctorGeneralTaskList(data)
    //     console.log(resp.data.task_list)
    //     await commit('GET_GENERAL_TASK_LIST', resp.data.task_list)
    //     await commit('SET_LIST_LOADED', true)
    //     // await getDoctorGeneralTaskList(data).then(async response => {
    //     //     const dataList = response.data.task_list
    //     //     await commit('GET_GENERAL_TASK_LIST', dataList)
    //         // await commit('GET_GENERAL_DATES', response.data.dates)
    //         // await commit('GET_GENERAL_COUNT', response.data.count)
    //     //     let performerAvatars = {}
    //     //     if (state.generalTaskList) {
    //     //         state.generalTaskList?.forEach(async elem => {
    //     //             if (elem.patient_data.avatar_min) {
    //     //                 await getFiles(elem.patient_data.avatar_min)
    //     //                     .then(avatar => {
    //     //                         commit('GET_AVATAR_GENERAL', {
    //     //                             elem,
    //     //                             avatar,
    //     //                         })
    //     //                     })
    //     //                     .catch(error => {
    //     //                         console.log(error)
    //     //                     })
    //     //             }
    //     //             if (elem.performer_avatar_min) {
    //     //                 const performerId = elem.performer_id
    //     //                 if (!performerAvatars[performerId]) {
    //     //                     await getFiles(elem.performer_avatar_min)
    //     //                         .then(avatar => {
    //     //                             commit('GET_AVATAR_PERFORMER', {
    //     //                                 elem,
    //     //                                 avatar,
    //     //                             })
    //     //                             performerAvatars[performerId] = avatar
    //     //                         })
    //     //                         .catch(error => {
    //     //                             console.log(error)
    //     //                         })
    //     //                 } else {
    //     //                     commit('GET_AVATAR_PERFORMER', {
    //     //                         elem,
    //     //                         avatar: performerAvatars[performerId],
    //     //                     })
    //     //                 }
    //     //             }
    //     //         })
    //     //     }

    //     //     await commit('SET_LIST_LOADED', true)
    //     // })
    // },
    async getGeneralTaskList({ commit }, data) {
        try {
            commit('SET_LIST_LOADED', false)
            const resp = await getDoctorGeneralTaskList(data)
            commit('GET_GENERAL_TASK_LIST', resp.data.task_list)
            await commit('GET_GENERAL_DATES', resp.data.dates)
            await commit('GET_GENERAL_COUNT', resp.data.count)

            for (const elem of resp.data.task_list) {
                if (elem.patient_data.avatar_min) {
                    const avatar = await getFiles(elem.patient_data.avatar_min)
                    commit('GET_AVATAR_GENERAL', { elem, avatar })
                }

                if (elem.performer_avatar_min) {
                    const performerAvatar = await getFiles(
                        elem.performer_avatar_min
                    )
                    commit('GET_AVATAR_PERFORMER', {
                        elem,
                        avatar: performerAvatar,
                    })
                }
            }

            commit('SET_LIST_LOADED', true)
        } catch (error) {
            console.error(error)
            commit('SET_LIST_LOADED', true)
        }
    },
    async getPerformerList({ commit }) {
        return await getPerformerList('').then(response => {
            let data = response.data
            const newArray = data?.map(item => {
                let dataItem = {
                    label: item.name + ' ' + item.surname,
                    value: item.id,
                }
                return dataItem
            })
            newArray.sort((a, b) => a.label.localeCompare(b.label))
            commit('SET_PERFORMER_LIST', newArray)
        })
    },
    async getPatientList({ commit }, facilityId) {
        return await getPatientList(facilityId, '').then(response => {
            let data = response.data
            const newArray = data?.map(item => {
                let dataItem = {
                    label: item.name + ' ' + item.surname,
                    value: item.id,
                }
                return dataItem
            })
            newArray.sort((a, b) => a.label.localeCompare(b.label))
            commit('SET_PATIENT_LIST', newArray)
        })
    },
    setGeneralSorting({ commit }, data) {
        commit('SET_GENERAL_SORTING', data)
    },
    setGeneralPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page)
    },
    updateGeneralColumns({ commit }, columns) {
        commit('UPDATE_GENERAL_COLUMNS', columns)
    },
    setGeneralFilter({ commit }, data) {
        commit('SET_GENERAL_FILTER', data)
    },
    setLoadedList({ commit }, data) {
        commit('SET_LIST_LOADED', data)
    },
}
