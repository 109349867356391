import { getFiles } from '@services/base-service'

const loadManagerDoctorsAvatars = (payload, setCallback) => {
    let avatarsCount = 0

    payload.forEach(profile => {
        if (profile.avatar) {
            avatarsCount += 1
            getFiles(profile.avatar.max).then(resp => {
                profile.avatar.max = resp
                avatarsCount -= 1
                if (avatarsCount === 0) {
                    setCallback()
                }
            })
        }
    })
    if (avatarsCount === 0) {
        setCallback()
    }
}
export { loadManagerDoctorsAvatars }
