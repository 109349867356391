import { $axios } from './base-service'

export default {
    getFacilityList() {
        const resource = '/distributor/facility'
        return $axios.get(`${resource}`)
    },
    generateReferralLink(id) {
        const resource = `/distributor/facility/${id}/generate-doctor-link`
        return $axios.post(`${resource}`)
    },
    getKitExisted(id) {
        const resource = `/kit/${id}`
        return $axios.get(`${resource}`)
    },
    enableFacilityCreateSubscriptions(id) {
        const resource = `/distributor/facility/${id}/enable`
        return $axios.put(`${resource}`, { id: id })
    },
    disableFacilityCreateSubscriptions(id) {
        const resource = `/distributor/facility/${id}/disable`
        return $axios.put(`${resource}`, { id: id })
    },
    generalInfo({ data, id }) {
        const resource = `/distributor/facility/${id}/info`
        return $axios.put(`${resource}`, data)
    },
    createSpecialization({ id, data }) {
        const resource = `/distributor/facility/${id}/fee`
        return $axios.post(`${resource}`, data)
    },
    editSpecialization({ id, fee_id, data }) {
        const resource = `/distributor/facility/${id}/fee/${fee_id}`
        return $axios.put(`${resource}`, data)
    },
    addPatientToManager({ data }) {
        const resource = `/users/patient`
        return $axios.post(`${resource}`, data)
    },
    removeSpecialization({ id, fee_id }) {
        const resource = `/distributor/facility/${id}/fee/${fee_id}`
        return $axios.delete(`${resource}`)
    },
    getActiveFacilityCounter(id, from, to) {
        const resource = `/distributor/counters`

        let idString = ''
        id.forEach((item, index) => {
            if (id.length - 1 > index) {
                idString += `${item},`
            } else {
                idString += item
            }
        })

        let queries = `?ids=${idString}&billing=monthly`
        if (!!from && !!to) {
            queries += `&from=${from}&to=${to}`
        } else if (!!from && !to) {
            queries += `&from=${from}`
        }
        return $axios.get(`${resource}${queries}`)
    },
    postManager(id, data) {
        const resource = `users/medical-facility-manager/${id}`
        return $axios.post(resource, data)
    },
    getManager(id) {
        const resource = `users/medical-facility-manager/${id}`
        return $axios.get(resource)
    },
    deleteManager(id) {
        const resource = `users/medical-facility-manager/${id}`
        return $axios.delete(resource)
    },
    postFacility(data) {
        const resource = `/distributor/facility`
        return $axios.post(resource, data)
    },
    postRequest(data) {
        const resource = `users/distributor/notify`
        return $axios.post(resource, data)
    },
    getFacilitySubscriptionData(id, year) {
        const resource = `/distributor/facility/${id}/statistics?year=${year}`
        return $axios.get(resource)
    },
    getActiveMedicalFacility(id) {
        const resource = `/distributor/facility/${id}`
        return $axios.get(resource)
    },
}
