import Vue from 'vue'

// Atoms
Vue.component('AAvatar', () => import('./components/atoms/a-avatar'))
Vue.component('ABtnLoader', () => import('./components/atoms/a-btn-loader'))
Vue.component('AButton', () => import('./components/atoms/a-button'))
Vue.component('AButtonLink', () => import('./components/atoms/a-button-link'))
Vue.component('AButtonMedium', () =>
    import('./components/atoms/a-button-medium')
)
Vue.component('ACalendar', () => import('./components/atoms/a-calendar'))
Vue.component('ACalendarSubscription', () =>
    import('./components/atoms/a-calendar-subscription')
)
Vue.component('AChatDoctorInfo', () =>
    import('./components/atoms/a-chat-doctor-info')
)
Vue.component('AChatPatientInfo', () =>
    import('./components/atoms/a-chat-patient-info')
)
Vue.component('AChatPatientLastMessage', () =>
    import('./components/atoms/a-chat-patient-last-message')
)
Vue.component('AChatPatientDraftMessage', () =>
    import('./components/atoms/a-chat-patient-draft-message')
)
Vue.component('AChatPatientSearchMessage', () =>
    import('./components/atoms/a-chat-patient-search-message')
)
Vue.component('ACheckbox', () => import('./components/atoms/a-checkbox'))
Vue.component('ACoolLightbox', () =>
    import('./components/atoms/a-cool-lightbox')
)
Vue.component('ADropdown', () => import('./components/atoms/a-dropdown'))
Vue.component('ADropdownRowWithInput', () =>
    import('./components/atoms/a-dropdown-row-with-input')
)
Vue.component('AInput', () => import('./components/atoms/a-input'))
Vue.component('ALogoLogin', () => import('./components/atoms/a-logo-login'))
Vue.component('AModal', () => import('./components/atoms/a-modal'))
Vue.component('AMonthlyPicker', () =>
    import('./components/atoms/a-monthly-picker')
)
Vue.component('APreloader', () => import('./components/atoms/a-preloader'))
Vue.component('ARightSidebarChart', () =>
    import('./components/atoms/a-right-sidebar-chart')
)
Vue.component('ARightSidebarLineChart', () =>
    import('./components/atoms/a-right-sidebar-line-chart')
)
Vue.component('ASelect', () => import('./components/atoms/a-select2'))
Vue.component('ASelect2', () => import('./components/atoms/a-select2'))
Vue.component('ATab', () => import('./components/atoms/a-tab'))
Vue.component('ATabs', () => import('./components/atoms/a-tabs'))
Vue.component('ATable', () => import('./components/atoms/a-table'))
Vue.component('ATextarea', () => import('./components/atoms/a-textarea'))
Vue.component('ATitleMain', () => import('./components/atoms/a-title-main'))

// Molecules
Vue.component('MCalendarWidget', () =>
    import('./components/molecules/m-calendar-widget')
)
Vue.component('MChatMessageSearch', () =>
    import('./components/molecules/m-chat-message-search')
)
Vue.component('MDropdown', () => import('./components/molecules/m-dropdown'))
Vue.component('MNavMain', () => import('./components/molecules/m-nav-main'))
Vue.component('MNotificationCall', () =>
    import('./components/molecules/m-notification-call')
)
Vue.component('MSelectDropdown', () =>
    import('./components/molecules/m-select-dropdown')
)
Vue.component('MSettingFieldDropdown', () =>
    import('./components/molecules/m-setting-field-dropdown')
)
Vue.component('MSettingFieldEmail', () =>
    import('./components/molecules/m-setting-field-email')
)
Vue.component('MSettingFieldDate', () =>
    import('./components/molecules/m-setting-field-date')
)
Vue.component('MSettingFieldEnlarged', () =>
    import('./components/molecules/m-setting-field-enlarged')
)
Vue.component('MSettingFieldInput', () =>
    import('./components/molecules/m-setting-field-input')
)
Vue.component('MSettingFieldPassword', () =>
    import('./components/molecules/m-setting-field-password')
)
Vue.component('MSettingFieldPhone', () =>
    import('./components/molecules/m-setting-field-phone')
)
Vue.component('MSettingFieldSupport', () =>
    import('./components/molecules/m-setting-field-support')
)
Vue.component('MSettingTerms', () =>
    import('./components/molecules/m-setting-terms')
)
Vue.component('MSettingUserManual', () =>
    import('./components/molecules/m-setting-user-manual')
)
Vue.component('MSimpleWidget', () =>
    import('./components/molecules/m-simple-widget')
)
Vue.component('MPatientsWidget', () =>
    import('./components/molecules/m-patients-widget')
)
Vue.component('MTabs', () => import('./components/molecules/m-tabs'))
Vue.component('MVueAvatar', () => import('./components/molecules/m-vue-avatar'))
Vue.component('MMainLoginBanner', () =>
    import('./components/molecules/m-main-login-banner')
)
Vue.component('MChoseAvatar', () =>
    import('./components/molecules/m-chose-avatar')
)
Vue.component('MPhoneSelect', () =>
    import('./components/molecules/m-phone-select')
)

// Organisms
Vue.component('ODialogues', () => import('./components/organisms/o-dialogues'))
Vue.component('ONavMain', () => import('./components/organisms/o-nav-main'))
Vue.component('ORightSidebar', () =>
    import('./components/organisms/o-right-sidebar')
)
