import {$axios} from './base-service'
import axios from 'axios'

export default {
    login(data) {
        const resource = '/users/login'
        return $axios.post(`${resource}`, data)
    },
    logOutRequest(data) {
        const resource = '/users/logout'
        return $axios.post(`${resource}`, data)
    },
    registerV2(data, code, facility, token) {
        // eslint-disable-next-line no-undef
        const resource = `${config.VUE_APP_BASE_URL}/api/v2/users/doctor/registration?code=${code}&facility=${facility}`
        return $axios.post(`${resource}`, data, {
            headers: {
                'X-Token-Captcha': token,
            },
        })
    },
    checkSmsCode(data) {
        // eslint-disable-next-line no-undef
        const resource = `${config.VUE_APP_BASE_URL}/api/v2/users/doctor/code`
        return $axios.post(`${resource}`, data)
    },
    resendSms(data) {
        // eslint-disable-next-line no-undef
        const resource = `${config.VUE_APP_BASE_URL}/api/v2/users/doctor/code/resend`
        return $axios.post(`${resource}`, data)
    },
    newRegister(data, code, facility) {
        const resource = `/users/doctor/registration?code=${code}&facility=${facility}`
        return $axios.post(`${resource}`, data)
    },
    deleteAccountConfirm() {
        const resource = `/users/patient/delete`
        return $axios.delete(`${resource}`)
    },
    recovery(data) {
        const resource = '/users/password/reset'
        return $axios.post(`${resource}`, data)
    },
    managerActivation(role, token) {
        // eslint-disable-next-line no-undef
        const resourceManager = `${config.VUE_APP_BASE_URL}/api/v1/users/activate/${role}?token=${token}`
        return axios.get(`${resourceManager}`)
    },
    userActivation(role, token) {
        // eslint-disable-next-line no-undef
        const resource = `${config.VUE_APP_BASE_URL}/api/v1/users/${role}/activate?token=${token}`
        return axios.get(`${resource}`)
    },
    patientActivation(token) {
        const resource = `${config.VUE_APP_BASE_URL}/api/v1/users/patient/activate?token=${token}`
        return $axios.get(`${resource}`)
    },
    postNewPassword(token, data) {
        const resource = `${config.VUE_APP_BASE_URL}/api/v1/users/password/apply?token=${token}`
        return $axios.post(resource, data)
    },
}
