import VideoService from '@services/video-user-service.js'

export const state = {
    isCallActive: false,
    activeVideoCall: false, //ToDo: check this variable
    incomingCall: false,
    channel: '',
    sessionId: '',
    userContacts: {},
    callUserId: '',
}

export const mutations = {
    SET_CALL_ACTIVE(state) {
        state.isCallActive = true
    },
    SET_VIDEO_CALL_STATUS(state, status) {
        state.activeVideoCall = status
    },
    SET_CALL_INACTIVE(state) {
        state.isCallActive = false
    },
    SET_INCOMING_CALL_ACTIVE(state) {
        state.incomingCall = true
    },
    SET_INCOMING_CALL_INACTIVE(state) {
        state.incomingCall = false
    },
    SET_PROXY_URL(state, payload) {
        state.channel = payload
    },
    SET_SESSION_ID(state, payload) {
        state.sessionId = payload
    },
    SET_USER_CONTACTS(state, payload) {
        state.userContacts = payload
    },
    SET_CALL_USER_ID(state, userid) {
        state.callUserId = userid
    },
}

export const getters = {
    callState(state) {
        return state.isCallActive
    },
    incomingCallState(state) {
        return state.incomingCall
    },
    callUserContacts(state) {
        return state.userContacts
    },
}

export const actions = {
    setCallUserId({ commit }, id) {
        commit('SET_CALL_USER_ID', id)
    },
    setProxyUrl({ commit }) {
        commit('SET_PROXY_URL', '')
    },
    setincomingCall({ commit }) {
        commit('SET_INCOMING_CALL_ACTIVE')
    },
    startVideoCall({ commit }, data) {
        commit('SET_CALL_ACTIVE')
        if (data.contact_id) {
            commit('SET_CALL_USER_ID', data.contact_id)
            return VideoService.startCall(data.contact_id, data.event_id)
                .then(response => {
                    commit('SET_VIDEO_CALL_STATUS', true)
                    commit('SET_PROXY_URL', response.data.channel)
                    commit('SET_SESSION_ID', response.data.session_id)
                    return response
                })
                .catch(error => {
                    console.log(error)
                    commit('SET_CALL_INACTIVE')
                    commit('SET_INCOMING_CALL_INACTIVE')
                    commit('SET_PROXY_URL', '')
                    commit('SET_CALL_USER_ID', '')
                })
        } else {
            commit('SET_CALL_USER_ID', data)
            return VideoService.startCall(data, '')
                .then(response => {
                    commit('SET_VIDEO_CALL_STATUS', true)
                    commit('SET_PROXY_URL', response.data.channel)
                    commit('SET_SESSION_ID', response.data.session_id)
                    return response
                })
                .catch(error => {
                    console.log(error)
                    commit('SET_CALL_INACTIVE')
                    commit('SET_INCOMING_CALL_INACTIVE')
                    commit('SET_PROXY_URL', '')
                    commit('SET_CALL_USER_ID', '')
                })
        }
    },
    getTurnServers() {
        return VideoService.getTurnServer()
    },
    joinVideoCall({ commit }, data) {
        commit('SET_VIDEO_CALL_STATUS', true)
        commit('SET_INCOMING_CALL_INACTIVE')
        commit('SET_CALL_USER_ID', data)
        commit('SET_CALL_ACTIVE')
    },
    setChannel({ commit }, data) {
        commit('SET_PROXY_URL', data)
    },
    hideVideoCall({ commit }) {
        commit('SET_VIDEO_CALL_STATUS', false)
        commit('SET_INCOMING_CALL_INACTIVE')
        commit('SET_CALL_INACTIVE')
    },
    hideNotificationCall({ commit }) {
        commit('SET_INCOMING_CALL_INACTIVE')
    },
    getDoctorContacts({ commit }) {
        return VideoService.getContacts()
            .then(response => {
                commit('SET_USER_CONTACTS', response.data)
                return response
            })
            .catch(error => {
                console.log(error)
            })
    },
}
