import { $axios } from './base-service'

export default {
    getConversations(messages_per_conversation) {
        messages_per_conversation = messages_per_conversation
            ? messages_per_conversation
            : 1
        const resource = `/chat/conversations?messages_per_conversation=${messages_per_conversation}`
        return $axios.get(`${resource}`)
    },

    sendMessage(data) {
        const resource = '/chat/messages'
        return $axios.post(`${resource}`, data)
    },

    searchMessagesByID(conversationId, id, limit = 1) {
        const resource = `/chat/conversations/${conversationId}/messages?internal_id=${id}&reverse=true&include=true&limit=${limit}`
        return $axios.get(`${resource}`)
    },
    searchMessagesDownByID(conversationId, id, limit = 1) {
        const resource = `/chat/conversations/${conversationId}/messages?internal_id=${id}&reverse=false&include=false&limit=${limit}`
        return $axios.get(`${resource}`)
    },

    searchPatients(data) {
        const resource = `/chat/patients`
        return $axios.get(`${resource}`, data)
    },

    searchNewMessages(conversationId, id, limit = 1) {
        const resource = `/chat/conversations/${conversationId}/messages?internal_id=${id}&reverse=false&include=true&limit=${limit}`
        return $axios.get(`${resource}`)
    },

    searchMessageInChat(conversationId, query) {
        const resource = `/chat/conversations/${conversationId}/search-messages?pattern=${query}`
        return $axios.get(`${resource}`)
    },

    searchUserMessage(query) {
        const resource = `/chat/search/users-message?pattern=${query}`
        return $axios.get(`${resource}`)
    },

    deleteChat(conversation_id) {
        const resource = `/chat/conversations/${conversation_id}`
        return $axios.delete(`${resource}`)
    },

    postOnlineUsersStatus(data) {
        const resource = `/users/online-status`
        return $axios.post(`${resource}`, data)
    },

    putOnlineDoctorStatus(doctor_id) {
        const resource = `/users/online/${doctor_id}`
        return $axios.put(`${resource}`)
    },

    getAllPatients() {
        const resource = `/chat/patients`
        return $axios.get(`${resource}`)
    },

    readMessages(id) {
        return $axios.put(`/chat/messages/${id}/read`)
    },

    getFileLink(url) {
        return $axios.get(url)
    },

    getVideoLink(url) {
        return $axios.get(url)
    },

    postChatFile(url, formData) {
        return $axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Disposition': 'attachment',
            },
        })
    },

    putComment(id, commentMessage) {
        return $axios.put(`/calendar/doctor/events/${id}/comment`, {
            comment: commentMessage,
        })
    },
}
