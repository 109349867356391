import { $axios } from './base-service'

export default {
    getManagerDoctorsProfilesService() {
        return $axios.get('/users/facility-manager/doctors')
    },

    getManagerDoctorsProfilesDetailService(id) {
        return $axios.post('/users/profiles', { contacts: [id] })
    },

    loadUsersProfiles(ids) {
        return $axios.post('/users/profiles', { contacts: ids })
    },

    getPatientsManagerProfile(query) {
        const resource = `/users/profiles?query=${query}`
        return $axios.get(resource)
    },

    getPatientManagerProfileInfo(id) {
        const resource = `manager/patient/${id}`
        return $axios.get(resource)
    },

    loadFacilitySubscriptions({ from, to }) {
        let queries = ''
        if (!!from && !!to) {
            queries = `&from=${from}&to=${to}`
        } else if (!!from && !to) {
            queries = `&from=${from}`
        }
        if (!!from && !to) {
            queries = `&from=${from}`
        }
        const resource = `/distributor/manager/medical-facility/statistics/subscription?billing=monthly${queries}`
        return $axios.get(resource)
    },

    getManagerFacilityDoctors({ from, to }) {
        let queries = ''
        if (!!from && !!to) {
            queries = `&from=${from}&to=${to}`
        } else if (!!from && !to) {
            queries = `&from=${from}`
        }
        if (!!from && !to) {
            queries = `&from=${from}`
        }
        const resource = `/distributor/manager/medical-facility/statistics/doctor?billing=monthly${queries}`
        return $axios.get(resource)
    },

    getFacilityCounters(facilityId) {
        return $axios.get(
            `/distributor/manager/counters?billing_type=monthly&ids=${facilityId}`
        )
    },

    getFacilityStatistic(from, to) {
        let queries = ''
        if (!!from && !!to) {
            queries = `&from=${from}&to=${to}`
        } else if (!!from && !to) {
            queries = `&from=${from}`
        }
        if (!!from && !to) {
            queries = `&from=${from}`
        }
        const resource = `/distributor/manager/medical-facility/statistics/specialty?billing=monthly${queries}`
        return $axios.get(resource)
    },

    generalInfo() {
        return $axios.get(`/manager/facility`)
    },

    generateReferralLink() {
        return $axios.get(`/manager/facility/referral-link`)
    },
    facilityPaidModeActivate(id) {
        const resource = `/facility/${id}/activate`
        return $axios.put(resource)
    },
    putEditPatient({ data }) {
        const resource = '/users/patient/edit'
        return $axios.put(`${resource}`, data)
    },
}
