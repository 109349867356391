import { $axios } from './base-service'

export default {
    passwordCheck(password) {
        const resource = '/users/password/check'
        return $axios.post(resource, {
            password,
        })
    },
    changeEmailApply(token) {
        const resource = `/users/email/apply?token=${token}`
        return $axios.get(resource)
    },
    changeEnlargedMode(data) {
        const resource = `users/doctor/edit`
        return $axios.put(resource, data)
    },
}
