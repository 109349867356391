import { Role } from '@constants/role.js'
const meta = { authorize: [Role.owner] }

export default [
    {
        path: '/',
        meta,
        component: () => import('@pages/p-owner-base'),
        children: [
            {
                path: '/facility-lists',
                name: 'owner-main',
                meta,
                component: () => import('@templates/t-owner-distributors'),
                redirect: {
                    name: 'facility-list',
                },
                children: [
                    {
                        path: 'all',
                        name: 'facility-list',
                        meta,
                        component: () => import('@organisms/o-owner-distributor'),
                    },
                    {
                        path: 'create-distributor',
                        name: 'distributor-create',
                        meta,
                        component: () => import('@organisms/o-owner-create-distributor'),
                    }
                ],
            },
            {
                path: '/facility-lists/:id',
                name: 'owner-detail',
                meta,
                component: () => import('@templates/t-owner-detail'),
            },
            {
                path: '/owner-statistic',
                name: 'owner-statistic',
                meta,
                component: () => import('@templates/t-owner-statistic'),
            },
            {
                path: '/owner-settings',
                name: 'owner-settings',
                meta,
                component: () => import('@templates/t-owner-settings'),
            },
        ],
    },
]
