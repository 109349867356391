import { $axios } from './base-service'

export default {
    getKits(queries) {
        let resource = '/kit/list'
        let data = {
            customer_id: queries.id,
        }
        if (queries.search) {
            data.query = queries.search
        }
        if (queries.sort) {
            data.column_name = queries.sort.name
            data.by_descending = queries.sort.by_descending
        }
        if (queries.page) {
            data.page = queries.page
        }
        if (queries.filter) {
            data.filter = queries.filter
        }
        return $axios.post(`${resource}`, data)
    },
    getKit(id) {
        let resource = `/registered-kit/${id}`
        return $axios.post(`${resource}`, {})
    },
    getFilterOptions(param, id) {
        let resource = '/kit/search'
        return $axios.post(`${resource}`, { parameter_name: param,
            customer_id: id,
        })
    },
    getDistributors() {
        let resource = '/kit-distributor/list'
        return $axios.get(`${resource}`)
    },
    getCustomers(id) {
        let resource = '/kit-distributor/customer/list/'
        return $axios.get(`${resource}${id}`)
    },
    getPatients(id) {
        let resource = `/kit-distributor/customer/patient/list/${id}`
        return $axios.get(`${resource}`)
    },
    moveKit(data) {
        let resource = '/kit/save-move'
        return $axios.post(`${resource}`, data)
    },
}
