import { i18n } from '@/i18n'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { German } from 'flatpickr/dist/l10n/de.js'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { Polish } from 'flatpickr/dist/l10n/pl.js'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import { Ukrainian } from 'flatpickr/dist/l10n/uk.js'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import { Slovak } from 'flatpickr/dist/l10n/sk.js'
import {
    ENGLISH,
    POLISH,
    SPANISH,
    PORTUGUESE,
    GERMAN,
    RUSSIAN,
    UKRAINIAN,
    FRENCH,
    SLOVAK,
    ITALIAN,
} from '@constants/translations'
import { locale as localeIview } from 'view-design'
import viewEn from 'view-design/dist/locale/en-US'
import viewPl from 'view-design/dist/locale/pl-PL'
import viewUk from 'view-design/dist/locale/uk-UA'
import viewEs from 'view-design/dist/locale/es-ES'
import viewRu from 'view-design/dist/locale/ru-RU'
import viewPt from 'view-design/dist/locale/pt-PT'
import viewDe from 'view-design/dist/locale/de-DE'
import viewFr from 'view-design/dist/locale/fr-FR'
import viewIt from 'view-design/dist/locale/it-IT'
import moment from 'moment'

const languages = {
    getDropdownList() {
        const translate = i18n.t.bind(i18n)
        return [
            { value: ENGLISH, label: translate(`w_${ENGLISH}`) },
            { value: POLISH, label: translate(`w_${POLISH}`) },
            { value: UKRAINIAN, label: translate(`w_${UKRAINIAN}`) },
            { value: SPANISH, label: translate(`w_${SPANISH}`) },
            { value: RUSSIAN, label: translate(`w_${RUSSIAN}`) },
            { value: PORTUGUESE, label: translate(`w_${PORTUGUESE}`) },
            { value: GERMAN, label: translate(`w_${GERMAN}`) },
            { value: FRENCH, label: translate(`w_${FRENCH}`) },
            { value: SLOVAK, label: translate(`w_${SLOVAK}`) },
            { value: ITALIAN, label: translate(`w_${ITALIAN}`) },
        ]
    },
    getFlatpickerLocale(lang) {
        let language = {}
        let startWeek = { firstDayOfWeek: 1 }
        if (lang === POLISH) {
            Polish.weekdays.shorthand = [
                'Ni',
                'Po',
                'Wt',
                'Śr',
                'Cz',
                'Pt',
                'So',
            ]
            language = Polish
        } else if (lang === ENGLISH) {
            language = english
            startWeek = { firstDayOfWeek: 0 }
        } else if (lang === UKRAINIAN) {
            language = Ukrainian
        } else if (lang === SPANISH) {
            language = Spanish
        } else if (lang === RUSSIAN) {
            language = Russian
        } else if (lang === PORTUGUESE) {
            language = Portuguese
        } else if (lang === GERMAN) {
            language = German
        } else if (lang === FRENCH) {
            language = French
        } else if (lang === SLOVAK) {
            language = Slovak
        } else if (lang === ITALIAN) {
            language = Italian
        }

        return { ...language, ...startWeek }
    },
    getCalendarLocale(lang) {
        let locale = 'en'
        let startDayOfWeek = 1
        if (lang === ENGLISH) {
            locale = 'en'
            startDayOfWeek = 0
        } else if (lang === POLISH) {
            locale = 'pl'
        } else if (lang === SPANISH) {
            locale = 'es'
        } else if (lang === PORTUGUESE) {
            locale = 'pt'
        } else if (lang === RUSSIAN) {
            locale = 'ru'
        } else if (lang === UKRAINIAN) {
            locale = 'uk'
        } else if (lang === GERMAN) {
            locale = 'de'
        } else if (lang === FRENCH) {
            locale = 'fr'
        } else if (lang === SLOVAK) {
            locale = 'sk'
        } else if (lang === ITALIAN) {
            locale = 'it'
        }

        return { locale, startDayOfWeek }
    },
    loadIviewi18n(lang) {
        if (lang === ENGLISH) {
            localeIview(viewEn)
        } else if (lang === POLISH) {
            localeIview(viewPl)
        } else if (lang === UKRAINIAN) {
            localeIview(viewUk)
        } else if (lang === SPANISH) {
            localeIview(viewEs)
        } else if (lang === RUSSIAN) {
            localeIview(viewRu)
        } else if (lang === PORTUGUESE) {
            localeIview(viewPt)
        } else if (lang === GERMAN) {
            localeIview(viewDe)
        } else if (lang === FRENCH) {
            localeIview(viewFr)
        } else if (lang === ITALIAN) {
            localeIview(viewIt)
        }
    },
    loadMomenti18n(lang, loadedLanguages) {
        let momentLang = ''

        if (lang === ENGLISH) {
            momentLang = 'en-au'
        } else if (lang === POLISH) {
            momentLang = 'pl'
        } else if (lang === UKRAINIAN) {
            momentLang = 'uk'
        } else if (lang === SPANISH) {
            momentLang = 'es'
        } else if (lang === PORTUGUESE) {
            momentLang = 'pt'
        } else if (lang === RUSSIAN) {
            momentLang = 'ru'
        } else if (lang === GERMAN) {
            momentLang = 'de'
        } else if (lang === FRENCH) {
            momentLang = 'fr'
        } else if (lang === SLOVAK) {
            momentLang = 'sk'
        } else if (lang === ITALIAN) {
            momentLang = 'it'
        }

        if (loadedLanguages.includes(lang)) {
            moment.locale(momentLang)
        } else {
            import(
                /* webpackChunkName: "moment-lang-[request]" */ `moment/locale/${momentLang}`
            ).then(() => moment.locale(momentLang))
        }
    },
}

export default languages
