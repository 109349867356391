import { saveState, getSavedState } from '@utils/user-info.js'
import eventService from '@services/events-day-service'
import settingsServices from '@services/settings-doctor-services'
// import store from '@state/store'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import {
    getFacility,
    getFiles,
    postNewTasks,
    getDoctorTasks,
    getDoctorTaskById,
    getPerformerPatientList,
    getHistoryById,
    putTaskInfo,
} from '@services/base-service'
import {
    ECG_MEASUREMENT_TYPE_ID,
    FHR_MEASUREMENT_TYPE_ID,
} from '@constants/measurementsIDs'
import notificationService from '@services/notification-service'

// const translate = i18n.t.bind(i18n)

export const state = {
    medicalFacility: {
        id: '',
        name: '',
        is_active: false,
    },
    isAscendingDoctor: null,
    isMainAscendingDoctor: null,
    witchDesign: null,
    activePatientId: undefined,
    enlargedMode: JSON.parse(getSavedState('enlargedMode')),
    dailyEvents: [],
    eventFromNotify: [],
    videoCallsEvents: [],
    todayVideoCallsEvents: [],
    videoCallList: [],
    newTasksList: [],
    taskHistory: [],
    mainTaskFilter: [],
    newTaskFilter: [],
    inProgressTasksList: [],
    taskById: {},
    performerList: [],
    videoCallLastDate: undefined,
    videoCallStartDate: undefined,
    videoCallListLoad: false,
    activeSubscriptionId: undefined,
    rememberCalendarDate: undefined,
    contactsProfiles: [{}],
    // columnsGeneral: [
    //     'type',
    //     'created_at',
    //     'state',
    //     'location',
    //     'distributor',
    //     'customer',
    // ],
    showAddAvatar: false,
    notificationList: [],
    newNotify: false,
    page: 3,
}

export const mutations = {
    SET_NOTIFICATION_READ_STATE(state, data) {
        state.notificationList = state.notificationList.map(item => {
            if (item.id === data.id) {
                item.read = !item.read
            }
            return item
        })
    },
    SET_ALL_NOTIFICATIONS_READ_STATE(state) {
        state.notificationList.forEach(item => {
            item.read = true
        })
    },
    GET_NOTIFICATION_LIST(state, data) {
        state.notificationList = []
        state.notificationList = data
    },
    GET_NOTIFICATION(state, data) {
        state.notificationList.pop()
        state.notificationList.unshift(data)
    },
    GET_NEW_TASKLIST(state, data) {
        state.newTasksList = data
    },
    GET_TASKLIST(state, data) {
        state.inProgressTasksList = data
    },
    GET_PERFORMER_LIST(state, data) {
        state.performerList = data
    },
    SET_MAIN_PAGE_DESIGN(state, data) {
        state.witchDesign = data
    },
    GET_MAIN_LIST_AVATAR(state, { elem, avatar }) {
        state.inProgressTasksList.map(item => {
            if (elem.id === item.id) {
                Vue.set(item.patient_data, 'avatar_min', avatar)
            }
        })
    },
    GET_AVATAR(state, { elem, avatar }) {
        state.newTasksList.map(item => {
            if (elem.id === item.id) {
                Vue.set(item.patient_data, 'avatar_min', avatar)
            }
        })
    },
    GET_TASK_BY_ID_AVATAR(state, avatar) {
        Vue.set(state.taskById.patient_data, 'avatar', avatar)
    },
    GET_TASK_BY_ID(state, data) {
        state.taskById = data
    },
    GET_NOTIFICATION_LIMIT(state, data) {
        state.notificationList.push(...data)
    },
    SET_ASCENDING_DOCTOR(state, data) {
        state.isAscendingDoctor = data
    },
    SET_MAIN_ASCENDING_DOCTOR(state, data) {
        state.isMainAscendingDoctor = data
    },
    SET_NEW_NOTIFY_STATUS(state, data) {
        state.newNotify = data
    },
    SET_CALENDARE_DATE(state, payload) {
        Vue.set(state, 'rememberCalendarDate', payload)
    },
    SET_PATIENTS_AVATARS(state, { profile, avatar }) {
        state.dailyEvents.map(item => {
            if (profile.id === item.patient_id) {
                Vue.set(item, 'avatar', avatar)
            }
        })
        state.videoCallsEvents.map(item => {
            if (profile.id === item.patient_id) {
                Vue.set(item, 'avatar', avatar)
            }
        })
    },
    CHANGE_ENLARGED_MODE_STATE(state, payload) {
        if (state.enlargedMode !== payload) {
            saveState('enlargedMode', payload)
            state.enlargedMode = payload
        }
    },
    SET_ACTIVE_PATIENT_ID(state, id) {
        Vue.set(state, 'activePatientId', id)
    },
    SET_DAILY_EVENTS(state, { data, fromNotify }) {
        if (data) {
            for (var j = 0; j < data.length; j++) {
                if (
                    data[j].type === 'measurement' &&
                    data[j].details.id !== ECG_MEASUREMENT_TYPE_ID &&
                    data[j].details.id !== FHR_MEASUREMENT_TYPE_ID
                ) {
                    if (data[j].result) {
                        let status = data[j].result.measures.map(
                            (currentValue, index) => {
                                let normal_min,
                                    normal_max,
                                    critical_max,
                                    critical_min

                                data[j].details.measures.forEach(
                                    detailMeasure => {
                                        if (
                                            currentValue.id === detailMeasure.id
                                        ) {
                                            normal_min =
                                                detailMeasure.units[0]
                                                    .normal_min
                                            normal_max =
                                                detailMeasure.units[0]
                                                    .normal_max
                                            critical_max =
                                                detailMeasure.units[0]
                                                    .critical_max
                                            critical_min =
                                                detailMeasure.units[0]
                                                    .critical_min
                                        }
                                    }
                                )

                                let resultItem = currentValue.value

                                let unitStatus
                                if (
                                    resultItem >= normal_min &&
                                    resultItem <= normal_max
                                ) {
                                    unitStatus = 'normal'
                                } else if (
                                    resultItem >= critical_max ||
                                    resultItem <= critical_min
                                ) {
                                    unitStatus = 'critical'
                                } else {
                                    unitStatus = 'deviation'
                                }
                                data[j].result.measures[
                                    index
                                ].measuresStatus = unitStatus
                                return unitStatus
                            }
                        )
                        if (status.indexOf('critical') >= 0) {
                            data[j].eventStatus = 'critical'
                        } else if (
                            status.indexOf('critical') === -1 &&
                            status.indexOf('deviation') >= 0
                        ) {
                            data[j].eventStatus = 'deviation'
                        } else {
                            data[j].eventStatus = 'normal'
                        }
                        if (
                            data[j].state_measure === 'Normal' ||
                            data[j].state_measure === 'Critical' ||
                            data[j].state_measure === 'Deviation'
                        ) {
                            data[j].eventStatus = data[j].state_measure
                        }
                    }
                }
            }
            if (fromNotify) {
                Vue.set(state, 'eventFromNotify', data)
            } else {
                Vue.set(state, 'dailyEvents', data)
            }
        } else {
            Vue.set(state, 'dailyEvents', [])
        }

        if (
            state.contactsProfiles &&
            state.contactsProfiles[0] &&
            state.contactsProfiles[0].id !== undefined
        ) {
            for (let i = 0; i < state.dailyEvents.length; i++) {
                for (let j = 0; j < state.contactsProfiles.length; j++) {
                    const surName = state.contactsProfiles[j]
                        ? state.contactsProfiles[j].surname
                        : ''
                    let name = state.contactsProfiles[j].name + ' ' + surName
                    let email = state.contactsProfiles[j].email
                    let phone = state.contactsProfiles[j].phone
                    if (
                        state.contactsProfiles[j].id ===
                        state.dailyEvents[i].patient_id
                    ) {
                        Vue.set(state.dailyEvents[i], 'name', name)
                        Vue.set(state.dailyEvents[i], 'email', email)
                        Vue.set(state.dailyEvents[i], 'phone', phone)
                        if (
                            state.contactsProfiles[j].avatar &&
                            state.contactsProfiles[j].avatar.min.includes(
                                'blob:'
                            )
                        ) {
                            Vue.set(
                                state.dailyEvents[i],
                                'avatar',
                                state.contactsProfiles[j].avatar.min
                            )
                        }
                    }
                }
            }
        }
    },
    SET_VIDEO_CALLS_EVENTS(state, data) {
        data = data.filter(
            item =>
                item.type === 'video-call' ||
                item.type === 'video-call-unscheduled'
        )
        Vue.set(state, 'videoCallsEvents', data)

        for (let i = 0; i < state.videoCallsEvents.length; i++) {
            for (let j = 0; j < state.contactsProfiles.length; j++) {
                const surName = state.contactsProfiles[j]
                    ? state.contactsProfiles[j].surname
                    : ''
                let name = state.contactsProfiles[j].name + ' ' + surName
                let gender = state.contactsProfiles[j].patient_data.gender
                let birthday = state.contactsProfiles[j].patient_data.birthday
                let firstName = state.contactsProfiles[j].name
                let lastName = state.contactsProfiles[j].surname
                if (
                    state.contactsProfiles[j].id ===
                    state.videoCallsEvents[i].patient_id
                ) {
                    Vue.set(state.videoCallsEvents[i], 'name', name)
                    Vue.set(state.videoCallsEvents[i], 'gender', gender)
                    Vue.set(state.videoCallsEvents[i], 'birthday', birthday)
                    Vue.set(state.videoCallsEvents[i], 'firstName', firstName)
                    Vue.set(state.videoCallsEvents[i], 'lastName', lastName)
                    if (
                        state.contactsProfiles[j].avatar &&
                        state.contactsProfiles[j].avatar.min.includes('blob:')
                    ) {
                        Vue.set(
                            state.videoCallsEvents[i],
                            'avatar',
                            state.contactsProfiles[j].avatar.min
                        )
                        Vue.set(
                            state.videoCallsEvents[i],
                            'avatarMax',
                            state.contactsProfiles[j].avatar.max
                        )
                    }
                }
            }
        }
    },
    SET_AVATAR_BLOCK(state, payload) {
        state.showAddAvatar = payload
    },
    SET_TODAY_VIDEO_CALLS_EVENTS(state, payload) {
        payload = payload.filter(item => item.type === 'video-call')
        Vue.set(state, 'todayVideoCallsEvents', payload)
    },

    SET_EVENT_CHECK_STATE(state, currentId) {
        let filtered = []
        let currentIndex

        state.dailyEvents.forEach((item, index) => {
            if (item.state_check === 'checked') {
                filtered.push(index)
            }
            if (item.id === currentId) {
                currentIndex = index
            }
        })

        Vue.set(state.dailyEvents[currentIndex], 'state_check', 'checked')

        let current = state.dailyEvents.splice(currentIndex, 1)

        if (filtered) {
            state.dailyEvents.splice(
                filtered[filtered.length - 1] + 1,
                0,
                current[0]
            )
        } else {
            state.dailyEvents.unshift(current[0])
        }
    },
    SET_VIDEO_CALL_LIST(state, payload) {
        state.videoCallList = payload
    },
    SET_TASK_STATUS(state, payload) {
        state.newTasksList = state.newTasksList.filter(
            item => item.id !== payload.id
        )
    },
    GET_HISTORY(state, payload) {
        state.taskHistory = payload
    },
    SET_CLEAN_TASK_OBJ(state) {
        state.taskById = {}
    },
    // SET_GENERAL_SORTING(state, data) {
    //     state.generalSorting.sort = data.value
    //     state.generalSorting.order_by = data.key
    // },
    // SET_CURRENT_PAGE(state, page) {
    //     state.generalCurPage = page
    // },
    // UPDATE_GENERAL_COLUMNS(state, columns) {
    //     state.columnsGeneral = columns
    // },
    SET_VIDEO_CALL_LIST_LOAD(state, payload) {
        state.videoCallListLoad = payload
    },
    SET_VIDEO_CALL_LIST_START(state, payload) {
        state.videoCallStartDate = payload
    },
    SET_VIDEO_CALL_LIST_LAST(state, payload) {
        state.videoCallLastDate = payload
    },
    SET_ACTIVE_SUBSCRIPTION_ID(state, id) {
        Vue.set(state, 'activeSubscriptionId', id)
    },

    SET_MEDICAL_FACILITY(state, payload) {
        state.medicalFacility = payload
    },
    SET_MAIN_TASK_FILTER(state, payload) {
        state.mainTaskFilter = payload
    },
    SET_NEW_TASK_FILTER(state, payload) {
        state.newTaskFilter = payload
    },
    SET_CONTACTS_DATA(state, data) {
        // const profileData = store.getters['common/getUserData']
        // console.log(profileData)
        // let newData = JSON.parse(JSON.stringify(data))
        // newData.forEach(item => {
        //     if (!item.participants) {
        //         item.participants = []
        //     }

        //     item.participants.push(profileData.id, item.id)

        //     item.avatar = item.avatar?.min
        //     item.patient_id = item.id
        //     item.full_name = item.name + ' ' + item.surname
        // })
        // console.log(newData)
        Vue.set(state, 'contactsProfiles', data)
    },
    SET_CONTACTS_AVATARS(state, { profileId, avatar, type }) {
        state.contactsProfiles.forEach(profile => {
            if (profile.id === profileId) {
                if (type === 'min') {
                    Vue.set(profile.avatar, 'min', avatar)
                } else if (type === 'medium') {
                    Vue.set(profile.avatar, 'medium', avatar)
                } else {
                    Vue.set(profile.avatar, 'max', avatar)
                }
            }
        })
    },
}

// export const getters = {
//     translatedAvailableList(state) {
//         const translationKeys = {
//             type: 'sm_0229_type',
//             date: 'sm_0086_date',
//             patient: 'w_patient',
//             last_action: 'sm_0234_last_action',
//             status: 'sm_0088_status',
//             performer: 'sm_0236_performer',
//         }

//         return state.availableKits.map(user => {
//             const { key, label, ...rest } = user
//             const translationKey = translationKeys[key]
//             const translatedLabel = i18n.global.t(translationKey)
//             return { key, label: translatedLabel, ...rest }
//         })
//     },
// }

export const actions = {
    setAllNotificationsReadState({ commit }) {
        commit('SET_ALL_NOTIFICATIONS_READ_STATE')
    },
    setNotificationReadState({ commit }, data) {
        commit('SET_NOTIFICATION_READ_STATE', data)
    },
    async getNotificationList({ commit }) {
        return await notificationService
            .getAllNotifications()
            .then(async response => {
                let notificationList = response.data.ListNotifications
                let newNotifyStatus = response.data.IsNewNotify
                await commit('GET_NOTIFICATION_LIST', notificationList)
                commit('SET_NEW_NOTIFY_STATUS', newNotifyStatus)
            })
    },
    addMoreNotifications({ commit }, data) {
        commit('GET_NOTIFICATION_LIMIT', data)
    },

    cleanTaskByIdObj({ commit }) {
        commit('SET_CLEAN_TASK_OBJ')
    },

    async getNotificationById({ commit }, id) {
        return await notificationService
            .getNotification(id)
            .then(async response => {
                await commit(
                    'GET_NOTIFICATION',
                    response.data.ListNotifications[0]
                )
            })
    },
    // setMainPageDesign({ commit }, item) {
    //     commit('SET_MAIN_PAGE_DESIGN', item)
    // },
    async setMainPageDesign(item) {
        try {
            settingsServices.changeEnlargedMode(item)
        } catch (e) {
            console.log(e)
        }
    },
    async getListOfDoctorTasks({ commit }) {
        try {
            const response = await getDoctorTasks()
            let data = response.data
            await commit('GET_TASKLIST', data)

            const avatarsCache = {}

            if (state.inProgressTasksList?.length) {
                for (const elem of state.inProgressTasksList) {
                    if (elem.patient_data.avatar_min) {
                        //Looking for avatar in cache
                        if (!avatarsCache[elem.patient_id]) {
                            const avatar = await getFiles(
                                elem.patient_data.avatar_min
                            )
                            avatarsCache[elem.patient_id] = avatar // Save avatar in cache
                        }
                        commit('GET_MAIN_LIST_AVATAR', {
                            elem,
                            avatar: avatarsCache[elem.patient_id],
                        })
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    },

    async changeTaskStatus({ commit, dispatch }, { id, data }) {
        return await putTaskInfo(id, data).then(async response => {
            let data = response.data
            if (data.patient_data.avatar_min) {
                await getFiles(data.patient_data.avatar_min)
                    .then(avatar => {
                        data.patient_data.avatar_min = avatar
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            commit('SET_TASK_STATUS', data)
            dispatch('getListOfDoctorTasks')
        })
    },

    async getHistory({ commit }, id) {
        return await getHistoryById(id)
            .then(resp => {
                if (resp.data === null) {
                    commit('GET_HISTORY', [])
                } else {
                    commit('GET_HISTORY', resp.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    },
    async getTaskById({ commit }, id) {
        let isAdmin = localStorage.getItem('task_administrator')
        let administrator_string
        if (isAdmin) {
            administrator_string = '?task_administrator'
        } else {
            administrator_string = ''
        }
        return await getDoctorTaskById(id, administrator_string)
            .then(async response => {
                let data = response.data
                commit('GET_TASK_BY_ID', data)
                let avatarMax = state.taskById.patient_data.avatar?.max
                if (avatarMax) {
                    await getFiles(avatarMax).then(async avatar => {
                        await commit('GET_TASK_BY_ID_AVATAR', avatar)
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    },
    async getPerformerList({ commit }, role) {
        return getPerformerPatientList(role).then(response => {
            let data = response.data
            let newResponse = []
            data.map(item => {
                let newData = {
                    id: item.id,
                    specialization_code: item.doctor_data.specialization_code,
                    value: `${item.name} ${item.surname}`,
                    label: `${item.name} ${item.surname}`,
                }
                newResponse.push(newData)
            })
            commit('GET_PERFORMER_LIST', newResponse)
        })
    },
    async getNewListOfTasks({ commit, state }) {
        return await postNewTasks({})
            .then(async response => {
                let data = response.data
                await commit('GET_NEW_TASKLIST', data)
                if (state.newTasksList) {
                    state.newTasksList?.forEach(async elem => {
                        if (elem.patient_data.avatar_min) {
                            await getFiles(elem.patient_data.avatar_min)
                                .then(avatar => {
                                    commit('GET_AVATAR', {
                                        elem,
                                        avatar,
                                    })
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    },
    //нужно добавлять в конец массива
    setNewNotifyStatus({ commit }, status) {
        commit('SET_NEW_NOTIFY_STATUS', status)
    },
    changeEnlargedMode({ commit, state }, payload) {
        if (payload !== state.enlargedMode) {
            let data = {
                magnifier: JSON.parse(payload),
            }
            if (payload) {
                settingsServices.changeEnlargedMode(data)
            } else {
                settingsServices.changeEnlargedMode(data)
            }
        }
        commit('CHANGE_ENLARGED_MODE_STATE', payload)
    },
    setActivePatientId({ commit }, id) {
        commit('SET_ACTIVE_PATIENT_ID', id)
    },
    getHomeDailyEvents({ commit }, timestamp) {
        eventService.getDailyEvents(timestamp).then(response => {
            if (response.data) {
                commit('SET_DAILY_EVENTS', { data: [], fromNotify: false })
                Vue.nextTick(() => {
                    let sortedList = []
                    let checkedList = []
                    let forCheckListCritical = []
                    let forCheckList = []
                    let notForCheckedList = []
                    response.data.forEach(item => {
                        if (item.state_check === 'checked') {
                            checkedList.push(item)
                        }
                        if (item.state_check === 'for-check') {
                            if (item.is_critical) {
                                forCheckListCritical.push(item)
                            } else {
                                forCheckList.push(item)
                            }
                        }
                        if (item.state_check === 'not-for-check') {
                            notForCheckedList.push(item)
                        }
                    })
                    forCheckList = forCheckList.sort((a, b) => {
                        return a.date > b.date ? 1 : -1
                    })
                    sortedList = checkedList.concat(
                        forCheckListCritical,
                        forCheckList,
                        notForCheckedList
                    )
                    commit('SET_DAILY_EVENTS', {
                        data: sortedList,
                        fromNotify: false,
                    })
                })
            } else {
                commit('SET_DAILY_EVENTS', {
                    data: [],
                    fromNotify: false,
                })
            }
        })
        eventService.getVideoCallsEvents(timestamp).then(async response => {
            if (response.data) {
                setTimeout(() => {
                    commit('SET_VIDEO_CALLS_EVENTS', response.data)
                }, 100)
            } else {
                commit('SET_VIDEO_CALLS_EVENTS', [])
            }
        })
    },
    setEventCheckState({ commit }, currentId) {
        eventService
            .putCalendarCheck(currentId)
            .then(() => {
                commit('SET_EVENT_CHECK_STATE', currentId)
            })
            .catch(error => {
                console.log(error)
            })
    },
    setEventlist(commit, data) {
        commit('SET_DAILY_EVENTS', {
            data: data,
            fromNotify: false,
        })
    },
    setCurrentEvent({ commit }, data) {
        commit('SET_DAILY_EVENTS', {
            data: data,
            fromNotify: true,
        })
    },
    updateStatus({ commit, state }, id) {
        eventService.getEventById(id).then(response => {
            let updatedEvents = state.dailyEvents
            updatedEvents.forEach((event, i) => {
                if (event.id === response.data.id) {
                    updatedEvents[i] = response.data
                }
            })
            commit('SET_DAILY_EVENTS', { data: [], fromNotify: false })
            Vue.nextTick(() => {
                let sortedList = []
                let checkedList = []
                let forCheckListCritical = []
                let forCheckList = []
                let notForCheckedList = []
                updatedEvents.forEach(item => {
                    if (item.state_check === 'checked') {
                        checkedList.push(item)
                    }
                    if (item.state_check === 'for-check') {
                        if (item.is_critical) {
                            forCheckListCritical.push(item)
                        } else {
                            forCheckList.push(item)
                        }
                    }
                    if (item.state_check === 'not-for-check') {
                        notForCheckedList.push(item)
                    }
                })
                forCheckList = forCheckList.sort((a, b) => {
                    return a.date > b.date ? 1 : -1
                })
                sortedList = checkedList.concat(
                    forCheckListCritical,
                    forCheckList,
                    notForCheckedList
                )
                commit('SET_DAILY_EVENTS', {
                    data: sortedList,
                    fromNotify: false,
                })
            })
        })
    },
    setTodayVideoList({ commit }, data) {
        commit('SET_TODAY_VIDEO_CALLS_EVENTS', data)
    },
    setLastCalendareDate({ commit }, data) {
        commit('SET_CALENDARE_DATE', data)
    },
    setAvatarBlock({ commit }, data) {
        commit('SET_AVATAR_BLOCK', data)
    },
    setMainTaskFilter({ commit }, data) {
        commit('SET_MAIN_TASK_FILTER', data)
    },
    setAscendingData({ commit }, data) {
        commit('SET_ASCENDING_DOCTOR', data)
    },
    setMainAscendingData({ commit }, data) {
        commit('SET_MAIN_ASCENDING_DOCTOR', data)
    },
    setNewTaskFilter({ commit }, data) {
        commit('SET_NEW_TASK_FILTER', data)
    },
    getVideoCallList({ commit }, data) {
        commit('SET_VIDEO_CALL_LIST_LOAD', false)
        axios
            .all([
                eventService.getNextVideoCallList(data[2]),
                eventService.getPastVideoCallList(data[2]),
            ])
            .then(
                axios.spread((obj1, obj2) => {
                    let response = []
                    let orderedVideoCallList = []
                    let date
                    let firstDate

                    if (obj1.data && obj2.data) {
                        response = obj1.data.concat(obj2.data)
                    } else {
                        if (obj1.data) {
                            response = obj1.data
                        } else {
                            response = obj2.data
                        }
                    }
                    if (response) {
                        date = new Date(response[0].date * 1000)
                        firstDate = `${date.getDate()}${date.getMonth()}${date.getFullYear()}`

                        response = response.sort((a, b) => {
                            return a.date > b.date ? 1 : -1
                        })
                        response.forEach(item => {
                            let date = new Date(item.date * 1000)
                            if (
                                firstDate ===
                                `${date.getDate()}${date.getMonth()}${date.getFullYear()}`
                            ) {
                                if (orderedVideoCallList.length) {
                                    orderedVideoCallList[
                                        orderedVideoCallList.length - 1
                                    ].push(item)
                                } else {
                                    orderedVideoCallList[0] = [item]
                                }
                            } else {
                                firstDate = `${date.getDate()}${date.getMonth()}${date.getFullYear()}`
                                orderedVideoCallList.push([item])
                            }
                        })
                        let newOrderedVideoCallList = []
                        orderedVideoCallList.forEach(item => {
                            let newItem = []
                            item.forEach((el, index) => {
                                if (index !== 0) {
                                    if (item[index].id !== item[index - 1].id) {
                                        newItem.push(el)
                                    }
                                } else {
                                    newItem.push(el)
                                }
                            })
                            newOrderedVideoCallList.push(newItem)
                        })
                        newOrderedVideoCallList = newOrderedVideoCallList.reverse()
                        if (newOrderedVideoCallList.length) {
                            commit(
                                'SET_VIDEO_CALL_LIST',
                                newOrderedVideoCallList
                            )
                            commit(
                                'SET_VIDEO_CALL_LIST_START',
                                newOrderedVideoCallList[0][0].date
                            )
                            commit(
                                'SET_VIDEO_CALL_LIST_LAST',
                                newOrderedVideoCallList[
                                    newOrderedVideoCallList.length - 1
                                ][0].date
                            )
                        }
                    }
                })
            )
    },
    getVideoCallListPast({ commit, state }, $state) {
        eventService
            .getPastVideoCallList(state.videoCallLastDate)
            .then(response => {
                if (response.data.length !== 0) {
                    let orderedVideoCallList = []
                    let firstDate = state.videoCallLastDate
                    response.data = response.data.sort((a, b) =>
                        a.date < b.date ? 1 : -1
                    )
                    response.data.forEach(item => {
                        let dateItem = new Date(item.date * 1000)
                        if (
                            firstDate ===
                            `${dateItem.getDate()}${dateItem.getMonth()}${dateItem.getFullYear()}`
                        ) {
                            orderedVideoCallList[
                                orderedVideoCallList.length - 1
                            ].push(item)
                        } else {
                            firstDate = `${dateItem.getDate()}${dateItem.getMonth()}${dateItem.getFullYear()}`
                            orderedVideoCallList.push([item])
                        }
                    })
                    orderedVideoCallList = orderedVideoCallList.map(item => {
                        return (item = item.sort((a, b) => {
                            return a.date > b.date ? 1 : -1
                        }))
                    })
                    if (
                        state.videoCallList[state.videoCallList.length - 1][0]
                            .id === orderedVideoCallList[0][0].id
                    ) {
                        orderedVideoCallList.splice(0, 1)
                    }
                    if (
                        state.videoCallList[state.videoCallList.length - 1][0]
                            .id ===
                        orderedVideoCallList[orderedVideoCallList.length - 1][0]
                            .id
                    ) {
                        $state.complete()
                    }
                    let newOrderedVideoCallList = state.videoCallList.concat(
                        orderedVideoCallList
                    )
                    commit('SET_VIDEO_CALL_LIST_LOAD', true)
                    commit('SET_VIDEO_CALL_LIST', newOrderedVideoCallList)
                    commit(
                        'SET_VIDEO_CALL_LIST_START',
                        newOrderedVideoCallList[0][0].date
                    )
                    commit(
                        'SET_VIDEO_CALL_LIST_LAST',
                        newOrderedVideoCallList[
                            newOrderedVideoCallList.length - 1
                        ][0].date
                    )
                    if (
                        newOrderedVideoCallList[
                            newOrderedVideoCallList.length - 1
                        ][0].id === response.data[0].id
                    ) {
                        $state.complete()
                    } else {
                        $state.loaded()
                    }
                } else {
                    $state.complete()
                }
            })
            .catch(error => {
                console.log(error)
                $state.complete()
            })
    },
    getVideoCallListNext({ commit, state }, $state) {
        let nextDay =
            moment(state.videoCallStartDate * 1000)
                .add(1, 'days')
                .valueOf() / 1000
        eventService
            .getNextVideoCallList(nextDay)
            .then(response => {
                if (response.data.length) {
                    let orderedVideoCallList = []
                    let firstDate = state.videoCallStartDate
                    response.data = response.data.sort((a, b) =>
                        a.date < b.date ? 1 : -1
                    )
                    response.data.forEach(item => {
                        let dateItem = new Date(item.date * 1000)
                        if (
                            firstDate ===
                            `${dateItem.getDate()}${dateItem.getMonth()}${dateItem.getFullYear()}`
                        ) {
                            orderedVideoCallList[
                                orderedVideoCallList.length - 1
                            ].push(item)
                        } else {
                            firstDate = `${dateItem.getDate()}${dateItem.getMonth()}${dateItem.getFullYear()}`
                            orderedVideoCallList.push([item])
                        }
                    })
                    orderedVideoCallList = orderedVideoCallList.map(item => {
                        return (item = item.sort((a, b) => {
                            return a.date > b.date ? 1 : -1
                        }))
                    })
                    let lastNextDate = state.videoCallList[0][0].id
                    if (lastNextDate === orderedVideoCallList[0][0].id) {
                        orderedVideoCallList.pop()
                    }
                    let newOrderedVideoCallList = orderedVideoCallList.concat(
                        state.videoCallList
                    )
                    commit('SET_VIDEO_CALL_LIST_LOAD', true)
                    commit('SET_VIDEO_CALL_LIST', newOrderedVideoCallList)
                    commit(
                        'SET_VIDEO_CALL_LIST_START',
                        newOrderedVideoCallList[0][0].date
                    )
                    commit(
                        'SET_VIDEO_CALL_LIST_LAST',
                        newOrderedVideoCallList[
                            newOrderedVideoCallList.length - 1
                        ][0].date
                    )
                    if (lastNextDate === newOrderedVideoCallList[0][0].id) {
                        $state.complete()
                    } else {
                        $state.loaded()
                    }
                } else {
                    $state.complete()
                }
            })
            .catch(error => {
                console.log(error)
                $state.complete()
            })
    },
    setActiveSubscriptionId({ commit }, id) {
        commit('SET_ACTIVE_SUBSCRIPTION_ID', id)
    },
    loadMedicalFacility({ commit }) {
        getFacility()
            .then(function(response) {
                commit('SET_MEDICAL_FACILITY', response.data)
            })
            .catch(function(error) {
                console.log(error)
            })
    },
    getContactsAvatars({ commit, state }) {
        state.contactsProfiles.forEach(profile => {
            if (profile.avatar) {
                getFiles(profile.avatar.min).then(resp => {
                    commit('SET_CONTACTS_AVATARS', {
                        profileId: profile.id,
                        avatar: resp,
                        type: 'min',
                    })
                    commit(
                        'chat/SET_PATIENTS_AVATARS',
                        { profile, avatar: resp },
                        { root: true }
                    )
                    commit('SET_PATIENTS_AVATARS', { profile, resp })
                })
                getFiles(profile.avatar.medium).then(resp => {
                    commit('SET_CONTACTS_AVATARS', {
                        profileId: profile.id,
                        avatar: resp,
                        type: 'medium',
                    })
                })
                getFiles(profile.avatar.max).then(resp => {
                    commit('SET_CONTACTS_AVATARS', {
                        profileId: profile.id,
                        avatar: resp,
                        type: 'max',
                    })
                })
            }
        })
    },
}
