import { getListMeasurement } from '@services/measurement-service.js'

export const state = {
    measurements_list: [],
}

export const mutations = {
    SET_MEASUREMENT_LIST(state, measurements) {
        state.measurements_list = measurements
    },
}

export const getters = {}

export const actions = {
    getMeasurementsList({ commit }) {
        getListMeasurement()
            .then(response => {
                commit('SET_MEASUREMENT_LIST', response.data)
            })
            .catch(error => {
                console.log(error)
            })
    },
}
