var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-notifications-api-errors-wrap"},[_c('notifications',{staticClass:"o-notifications-api",attrs:{"duration":7000,"width":280,"position":"","group":"api-errors"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var ref_item_data = ref.item.data;
            var url = ref_item_data.url;
            var method = ref_item_data.method;
return [_c('div',{staticClass:"vue-notification-template vue-notification error"},[_c('span',{staticClass:"title"},[_c('span',[_vm._v("Request Error")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(url))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(method))]),_vm._v(" "),_c('br')])])]}}])}),_c('notifications',{staticClass:"o-notifications-api-error",attrs:{"duration":10000,"width":"100%","position":"","group":"api-error-custom"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var ref_item_data = ref.item.data;
            var text = ref_item_data.text;
            var title = ref_item_data.title;
return [_c('div',{staticClass:"vue-notification-template vue-notification error"},[(title)?_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(title)+" ")]):_vm._e(),(text)?_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()])]}}])}),_c('notifications',{staticClass:"o-notifications-api-info",attrs:{"duration":7000,"width":"100%","position":"","group":"api-info"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var ref_item_data = ref.item.data;
            var text = ref_item_data.text;
            var title = ref_item_data.title;
            var close = ref.close;
return [_c('div',{staticClass:"vue-notification-template vue-notification info"},[(title)?_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(title)+" ")]):_vm._e(),(text)?_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e(),_c('button',{staticClass:"close",on:{"click":close}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }