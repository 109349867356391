<template>
    <div class="o-notifications-api-errors-wrap">
        <notifications
            class="o-notifications-api"
            :duration="7000"
            :width="280"
            position=""
            group="api-errors"
        >
            <template
                slot="body"
                slot-scope="{
                    item: {
                        data: { url, method },
                    },
                }"
            >
                <div class="vue-notification-template vue-notification error">
                    <span class="title">
                        <span>Request Error</span> <br />
                        <span>{{ url }}</span> <br />
                        <span>{{ method }}</span> <br />
                    </span>
                </div>
            </template>
        </notifications>
        <notifications
            class="o-notifications-api-error"
            :duration="10000"
            width="100%"
            position=""
            group="api-error-custom"
        >
            <template
                slot="body"
                slot-scope="{
                    item: {
                        data: { text, title },
                    },
                }"
            >
                <div class="vue-notification-template vue-notification error">
                    <span v-if="title" class="title">
                        {{ title }}
                    </span>
                    <span v-if="text" class="text">
                        {{ text }}
                    </span>
                </div>
            </template>
        </notifications>
        <notifications
            class="o-notifications-api-info"
            :duration="7000"
            width="100%"
            position=""
            group="api-info"
        >
            <template
                slot="body"
                slot-scope="{
                    item: {
                        data: { text, title },
                    },
                    close,
                }"
            >
                <div class="vue-notification-template vue-notification info">
                    <span v-if="title" class="title">
                        {{ title }}
                    </span>
                    <span v-if="text" class="text">
                        {{ text }}
                    </span>
                    <button class="close" @click="close"></button>
                </div>
            </template>
        </notifications>
    </div>
</template>

<script>
import { notificationsComputed } from '@state/helpers'

export default {
    name: 'ONotificationsApi',
    data() {
        return {
            env: process.env,
        }
    },
    computed: {
        ...notificationsComputed,
        canShowSystemNotifications() {
            return false
        },
    },
    watch: {
        error: {
            handler({ url, method, data }) {
                if (!url && !method) {
                    if (data) {
                        this.$notify({
                            // (optional)
                            // Name of the notification holder
                            group: 'api-error-custom',

                            // (optional)
                            // Class that will be assigned to the notification
                            type: 'error',

                            data: {
                                title: data.title,
                                text: data.text,
                            },
                        })
                    }
                } else {
                    if (this.canShowSystemNotifications) {
                        this.$notify({
                            // (optional)
                            // Name of the notification holder
                            group: 'api-errors',

                            // (optional)
                            // Class that will be assigned to the notification
                            type: 'error',

                            data: {
                                url,
                                method,
                                data,
                            },
                        })
                    }
                }
            },
            deep: true,
        },
        info: {
            handler(data) {
                if (data.data) {
                    this.$notify({
                        // (optional)
                        // Name of the notification holder
                        group: 'api-info',

                        // (optional)
                        // Class that will be assigned to the notification
                        type: 'info',

                        data: {
                            title: data.data.title,
                            text: data.data.text,
                        },
                    })
                }
            },
        },
    },
    methods: {
        on() {
            this.$notify
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@design';

body.enlarged-mode {
    .vue-notification-template {
        font-size: 20px;
        line-height: 24px;
    }
    .title {
        line-height: 24px;
    }
}

.o-notifications-api {
    width: 100%;
}

.o-notifications-api-errors-wrap {
    position: fixed;
    right: 3px;
    bottom: 3px;
    z-index: 999;
}

.vue-notification-group {
    position: static !important;
}

.vue-notification-template {
    padding: 20px;
    margin: 25px;
    font-size: 14px;
    background: #ebeffd;
    border: none;
    border-radius: 16px;
    box-shadow: 0 0 8px rgba(84, 148, 235, 0.4);

    .title {
        display: block;
        margin-bottom: 10px;
        line-height: 17px;
        word-break: break-word;
    }

    .text {
        line-height: 16px;
        word-break: break-word; //If long e-mail in notification
    }

    &.info {
        display: flex;
        justify-content: space-between;
        color: #5780f7;
    }

    &.error {
        color: #d84f4d;
        background: #fbd6d5;
        box-shadow: 0 0 4px rgba(255, 62, 62, 0.4);
    }
    .close {
        position: relative;
        top: -1px;
        width: 8px;
        height: 8px;
        margin-left: 14px;
        border: none;
        opacity: 0.5;
        &::after,
        &::before {
            position: absolute;
            width: 1px;
            height: 12px;
            content: '';
            background-color: $color-blue;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
        &:hover {
            opacity: 1;
        }
    }
}
</style>
