import moment from 'moment'
import { $axios } from './base-service'

export default {
    setPeriodicityInfo(days_periodicity) {
        switch (days_periodicity) {
            case 'daily':
                return 1
            case 'every_other_day':
                return 2
            case 'custom':
                return 3
        }
    },

    getTimestamps(item) {
        let dayStart = moment(new Date(item.startDate))
        let dayEnd = moment(new Date(item.endDate))
        let daysLag = dayEnd.diff(dayStart, 'days')
        let actionTimestamps = []

        let nextDay = new Date(dayStart)
        let int = 0

        if (
            item.action_timestamps.length === 0 ||
            item.action_timestamps[0] === undefined
        ) {
            return []
        } else {
            if (item.periodicity === 'daily') {
                for (let i = 0; i <= daysLag; i++) {
                    let day = new Date(nextDay.setDate(nextDay.getDate() + int))
                    for (let j = 0; j < item.receptions_per_day; j++) {
                        if (item.action_timestamps[j].length) {
                            let timeArr = item.action_timestamps[j].split(':')
                            day.setHours(timeArr[0], timeArr[1], 0, 0)
                            let time = moment.utc(day).valueOf() / 1000
                            actionTimestamps.push(time)
                        }
                    }
                    int = 1
                }
            }
            if (item.periodicity === 'every_other_day') {
                for (let i = 0; i <= daysLag; i += 2) {
                    let day = new Date(nextDay.setDate(nextDay.getDate() + int))
                    for (let j = 0; j < item.receptions_per_day; j++) {
                        if (item.action_timestamps[j].length) {
                            let timeArr = item.action_timestamps[j].split(':')
                            day.setHours(timeArr[0], timeArr[1], 0, 0)
                            let time = moment.utc(day).valueOf() / 1000
                            actionTimestamps.push(time)
                        }
                    }
                    int = 2
                }
            }
            if (item.periodicity === 'custom') {
                let customDatesArr = item.custom_dates

                for (let i = 0; i < customDatesArr.length; i++) {
                    let day = new Date(customDatesArr[i])
                    for (let j = 0; j < item.receptions_per_day; j++) {
                        if (item.action_timestamps[j].length) {
                            let timeArr = item.action_timestamps[j].split(':')
                            day.setHours(timeArr[0], timeArr[1], 0, 0)
                            let time = moment.utc(day).valueOf() / 1000
                            actionTimestamps.push(time)
                        }
                    }
                }
            }
        }

        return actionTimestamps
    },

    setTimeArray(item) {
        let time = []
        for (let i = 0; i < item.receptions_per_day; i++) {
            time.push(item.action_timestamps[i])
        }
        if (
            item.action_timestamps.length > 0 &&
            item.action_timestamps[0] !== undefined
        ) {
            for (let i = 0; i < time.length; i++) {
                let timeArr = time[i].split(':')
                timeArr[0] = parseInt(timeArr[0])
                timeArr[0] =
                    timeArr[0] === 0 || timeArr[0] < 10
                        ? `0${timeArr[0]}`
                        : timeArr[0]
                time[i] = `${timeArr[0]}:${timeArr[1]}:${timeArr[2]}`
            }
            if (time[0] === 'NaN:undefined:undefined') {
                return []
            }
        } else {
            return []
        }
        return time
    },

    comparer(otherArray) {
        return function(current) {
            return (
                otherArray.filter(other => {
                    let result = true
                    for (var prop in other) {
                        if (prop === 'startDate' || prop === 'endDate') {
                            if (
                                new Date(other[prop]).getTime() !==
                                new Date(current[prop]).getTime()
                            ) {
                                result = false
                            }
                        } else if (typeof other[prop] === 'object') {
                            if (
                                JSON.stringify(other[prop]) !==
                                JSON.stringify(current[prop])
                            ) {
                                result = false
                            }
                        } else {
                            if (other[prop] !== current[prop]) {
                                result = false
                            }
                        }
                    }
                    return result
                }).length === 0
            )
        }
    },

    setDateErrorOnEdit(date) {
        let subscriptionReloadData = JSON.parse(
            localStorage.getItem('subscriptionReloadData')
        )
        if (subscriptionReloadData && subscriptionReloadData.edited) {
            let a = moment(new Date(date).setHours('00', '00', '00', '00'))
            let b = moment(
                new Date(new Date().setHours('00', '00', '00', '00'))
            )

            //0 - today, 1 - tomorrow. We need tomorrow
            if (a.diff(b, 'days') < 1) {
                return true
            } else {
                return false
            }
        }
    },

    minCustomDateEdited(subscriptionStartDate, startDate) {
        const date = new Date()
        let tomorrowDate = moment([
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
        ]).add(1, 'days')
        let dates = [
            tomorrowDate.valueOf(),
            startDate || new Date(subscriptionStartDate * 1000),
        ]
        let moments = dates.map(d => moment(d))
        return moment.max(moments).valueOf()
    },

    startCalendarMinDateEdited(subscriptionStartDate) {
        const date = new Date()
        let tomorrowDate = moment([
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
        ]).add(1, 'days')
        let dates = [
            tomorrowDate.valueOf(),
            new Date(subscriptionStartDate * 1000).setHours('00', '00', '00'),
        ]
        let moments = dates.map(d => moment(d))
        return moment.max(moments).valueOf()
    },

    startCalendarMinYearEdited(subscriptionStartDate) {
        const date = new Date()
        let tomorrowDate = moment([
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
        ]).add(1, 'days')
        let dates = [
            tomorrowDate.valueOf(),
            new Date(subscriptionStartDate * 1000),
        ]
        let moments = dates.map(d => moment(d))

        return new Date(moment.max(moments).valueOf()).getFullYear()
    },

    endCalendarMinDateEdited(subscriptionStartDate, startDate) {
        const date = new Date()
        let tomorrowDate = moment([
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
        ]).add(1, 'days')
        let dates = [
            tomorrowDate.valueOf(),
            startDate ||
                new Date(subscriptionStartDate * 1000).setHours(
                    '00',
                    '00',
                    '00'
                ),
        ]
        let moments = dates.map(d => moment(d))
        return moment.max(moments).valueOf()
    },

    endCalendarMinYearEdited(subscriptionStartDate, startDate) {
        const date = new Date()
        let tomorrowDate = moment([
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
        ]).add(1, 'days')
        let dates = [
            tomorrowDate.valueOf(),
            new Date(startDate).getFullYear() ||
                new Date(subscriptionStartDate * 1000).getFullYear(),
        ]
        let moments = dates.map(d => moment(d))

        return new Date(moment.max(moments).valueOf()).getFullYear()
    },
}

const getUserEmail = mail => {
    return $axios.get(`/users/patient/search?email=${mail}`)
}

const putSubscription = (id, postData) => {
    return $axios.put(`/subscriptions/${id}`, postData)
}
const postSubscription = postData => {
    return $axios.post(`/subscriptions`, postData)
}
const putConfirm = (id, data) => {
    return $axios.put(`/subscriptions/${id}/confirm`, data)
}
const postDraft = postData => {
    return $axios.post(`/subscriptions-draft`, postData)
}
const putDraft = (id, postData) => {
    return $axios.put(`/subscriptions-draft/${id}`, postData)
}
const cancelSubscription = id => {
    return $axios.delete(`/doctor/subscriptions/${id}/cancel`)
}
const terminateSubscription = id => {
    return $axios.delete(`/doctor/subscriptions/${id}/terminate`)
}
const getSubscriptionUniqueName = query => {
    return $axios.get(`doctor/subscriptions/check-name?name=${query}`)
}
const putEditedSubscription = (id, type, itemId, putData) => {
    return $axios.put(`/subscriptions/${id}/${type}/${itemId}`, putData)
}
const postEditedSubscription = (id, type, postData) => {
    return $axios.post(`/subscriptions/${id}/${type}`, postData)
}
const deleteEditedSubscription = (id, type, itemId) => {
    return $axios.delete(`/subscriptions/${id}/${type}/${itemId}`)
}
const putNotification = (id, axiosType) => {
    return $axios.put(
        `/subscriptions/${id}/notify?appointment_type=${axiosType}`
    )
}

const postDiaries = (id, postData) => {
    return $axios.post(`/subscriptions/${id}/diaries`, postData)
}
const putDiaries = (subscriptionId, diaryId, postData) => {
    return $axios.put(
        `/subscriptions/${subscriptionId}/diaries/${diaryId}`,
        postData
    )
}
const deleteDiaries = (subscriptionId, diaryId) => {
    return $axios.delete(`/subscriptions/${subscriptionId}/diaries/${diaryId}`)
}

const postMeasurements = (id, postData) => {
    return $axios.post(`/subscriptions/${id}/measurements`, postData)
}
const putMeasurements = (subscriptionId, measurementId, postData) => {
    return $axios.put(
        `/subscriptions/${subscriptionId}/measurements/${measurementId}`,
        postData
    )
}
const deleteMeasurements = (subscriptionId, measurementId) => {
    return $axios.delete(
        `/subscriptions/${subscriptionId}/measurements/${measurementId}`
    )
}

const postMedications = (id, postData) => {
    return $axios.post(`/subscriptions/${id}/medications`, postData)
}
const putMedications = (subscriptionId, measurementId, postData) => {
    return $axios.put(
        `/subscriptions/${subscriptionId}/medications/${measurementId}`,
        postData
    )
}
const deleteMedications = (subscriptionId, measurementId) => {
    return $axios.delete(
        `/subscriptions/${subscriptionId}/medications/${measurementId}`
    )
}

const getSchedules = (start_date, end_date) => {
    return $axios.get(`/schedules?from=${start_date}&to=${end_date}`)
}

const postVideoCalls = (id, postData) => {
    return $axios.post(`/subscriptions/${id}/video-calls`, postData)
}
const putVideoCalls = (subscriptionId, videoId, postData) => {
    return $axios.put(
        `/subscriptions/${subscriptionId}/video-calls/${videoId}`,
        postData
    )
}
const deleteVideoCalls = (subscriptionId, videoId) => {
    return $axios.delete(
        `/subscriptions/${subscriptionId}/video-calls/${videoId}`
    )
}

const activateSubscription = token => {
    return $axios.get(`/patient/subscriptions/activate/${token}`)
}

const getMeasurements = () => {
    return $axios.get(`/content/measurements`)
}

const getSubscriptionSummary = () => {
    return $axios.get(`/aggregator/doctor/patients/summary`)
}

const getSubscriptionSummaryAll = facility_id => {
    return $axios.get(
        `/aggregator/health-manager/patients/summary/${facility_id}`
    )
}

const getSubscriptionDetails = id => {
    return $axios.get(`/doctor/subscriptions/get/${id}`)
}

const getSubscriptionAdministratorDetails = id => {
    return $axios.get(
        `/doctor/subscriptions/get/${id}?is_subscription_administrator`
    )
}

const getSubscriptionRanked = () => {
    return $axios.get(`/doctor/subscriptions/ranked`)
}

const getSubscriptionAdministratorRanked = facility_id => {
    return $axios.get(`/doctor/subscriptions/ranked?facility_id=${facility_id}`)
}

const filteredPatients = (value, facility_id) => {
    return $axios.get(
        `users/doctor/search/users?query=${value}&facility_id=${facility_id}`
    )
}

const filteredDoctorForSubscription = value => {
    return $axios.get(`users/doctor/search/users?query=${value}&role=doctor`)
}

export {
    getUserEmail,
    getSubscriptionAdministratorRanked,
    getSubscriptionSummaryAll,
    getSubscriptionAdministratorDetails,
    filteredDoctorForSubscription,
    putSubscription,
    postSubscription,
    putConfirm,
    cancelSubscription,
    terminateSubscription,
    putEditedSubscription,
    postEditedSubscription,
    deleteEditedSubscription,
    putNotification,
    postDiaries,
    putDiaries,
    deleteDiaries,
    postMeasurements,
    putMeasurements,
    deleteMeasurements,
    postMedications,
    putMedications,
    deleteMedications,
    getSchedules,
    postVideoCalls,
    putVideoCalls,
    deleteVideoCalls,
    activateSubscription,
    getMeasurements,
    getSubscriptionSummary,
    getSubscriptionDetails,
    getSubscriptionRanked,
    filteredPatients,
    getSubscriptionUniqueName,
    postDraft,
    putDraft,
}
