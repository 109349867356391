export function getLocale() {
    return getSavedState('auth.locale')
}

export function setLocale(locale) {
    saveState('auth.locale', locale)
}

export function getSavedState(key) {
    return window.localStorage.getItem(key)
}

export function saveState(key, state) {
    window.localStorage.setItem(key, state)
}

export function removeState(key) {
    window.localStorage.removeItem(key)
}
