import { $axios } from './base-service'
import moment from 'moment'

export default {
    getDailyEvents(timestamp) {
        let to =
            moment(timestamp * 1000)
                .add(23, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .valueOf() / 1000
        // eslint-disable-next-line no-undef
        const resource = `${config.VUE_APP_BASE_URL}/api/v2/calendar/events`
        return $axios.get(
            `${resource}?from=${timestamp}&to=${to}&types=diary,medication,measurement`
        )
    },
    getEventById(id) {
        const resource = `/calendar/doctor/event/${id}`
        return $axios.get(`${resource}`)
    },

    // getVideoCallsEvents(timestamp) {
    //     let to =
    //         moment(timestamp * 1000)
    //             .add(23, 'hours')
    //             .add(59, 'minutes')
    //             .add(59, 'seconds')
    //             .valueOf() / 1000
    //     // eslint-disable-next-line no-undef
    //     const resource = `${config.VUE_APP_BASE_URL}/api/v2/calendar/events`
    //     return $axios.get(
    //         `${resource}?from=${timestamp}&to=${to}&types=video-call`
    //     )
    // },
    getVideoCallsEvents(timestamp) {
        let to =
            moment(timestamp * 1000)
                .add(23, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .valueOf() / 1000
        // eslint-disable-next-line no-undef
        const resource = `${config.VUE_APP_BASE_URL}/api/v2/calendar/doctor/events/video-call`
        return $axios.get(
            `${resource}?from=${timestamp}&to=${to}&types=video-call`
        )
    },

    putCalendarCheck(id) {
        return $axios.put(`/calendar/doctor/events/${id}/check`)
    },

    postSingleVideoCall(data) {
        const resource = `schedules/video-calls`
        return $axios.post(`${resource}`, data)
    },

    getNextVideoCallList(date) {
        return $axios.get(
            // eslint-disable-next-line no-undef
            `${config.VUE_APP_BASE_URL}/api/v2/calendar/doctor/events/video-call/days?days=30&types=video-call,video-call-unscheduled&sort=ASC&from=${date}`
        )
    },

    getPastVideoCallList(date) {
        return $axios.get(
            // eslint-disable-next-line no-undef
            `${config.VUE_APP_BASE_URL}/api/v2/calendar/doctor/events/video-call/days?days=30&types=video-call,video-call-unscheduled&sort=DESC&from=${date}`
        )
    },
}
