import { Role } from '@constants/role.js'
const meta = { authorize: [Role.doctor] }

export default {
    path: '/subscription',
    component: () => import('@templates/t-subscription'),
    redirect: {
        name: 'subscription',
    },
    meta,
    children: [
        {
            path: 'all',
            name: 'subscription',
            meta,
            component: () => import('@organisms/o-subscription'),
        },
        {
            path: 'add',
            name: 'subscription.holder',
            meta,
            component: () => import('@organisms/o-subscription-holder'),
            redirect: {
                name: 'subscription.add',
            },
            children: [
                {
                    path: 'subscription',
                    name: 'subscription.add',
                    meta: {
                        type: 'search',
                        authorize: [Role.doctor],
                    },
                    props: true,
                },
            ],
        },
    ],
}
