import DistributorService from '@services/distributor-service.js'
import Vue from 'vue'
// import moment from 'moment'

export const state = {
    medical_facility_list: [],
    currentMedicalFacilityStateEnabled: true,
    currentMedicalIdis2HomeState: false,
    currentMedicalTrialMode: false,
    facilityLoaded: false,
    activeFacility: {},
    trialModeFacility: {
        startDateTrialMode: '',
        endDateTrialMode: '',
        durationTrialMode: 14,
    },
}

export const mutations = {
    GET_MEDICAL_FACILITY_LIST(state, payload) {
        state.facilityLoaded = true
        state.medical_facility_list = []
        state.medical_facility_list = payload
    },
    SET_MEDICAL_FACILITY_CURRENT_STATE(state, payload) {
        state.currentMedicalFacilityStateEnabled = payload
        state.activeFacility.is_active = payload
    },
    SET_IDIS2HOME_FACILITY_DATA(state, payload) {
        state.currentMedicalIdis2HomeState = payload.data.idis_2_home
        state.activeFacility.idis_2_home = payload.data.idis_2_home
    },
    SET_ACTIVE_FACILITY(state, payload) {
        state.activeFacility = {}
        state.activeFacility = payload
    },
    SET_KITS_EXISTED(state, payload) {
        Vue.set(state.activeFacility, 'kits_exist', payload)
        window.localStorage.setItem('kits_exist', payload)
    },
    SET_ACTIVE_FACILITY_DATA(state, { data }) {
        state.activeFacility.name = data.facility_name
        state.activeFacility.contact_person = data.contact_name
        state.activeFacility.contact_phone_number = data.phone_number
        state.activeFacility.email = data.email
        state.activeFacility.language = data.language
    },
    SET_MEDICAL_FACILITY_TRIAL_MODE(state, payload) {
        state.currentMedicalTrialMode = payload
    },
    SET_FACILITY_DATA(state, payload) {
        Vue.set(state, 'activeFacility', payload)
    },
}

export const getters = {
    getMedicalFacilityCurrentState(state) {
        return state.currentMedicalFacilityStateEnabled
    },
    getMedicalFacilityCurrentTrialMode(state) {
        return state.currentMedicalTrialMode
    },
    getMedicalFacilityCurrentIdis2HomeState(state) {
        return state.currentMedicalIdis2HomeState
    },
}

export const actions = {
    async getMedicalFacilityList({ commit }) {
        DistributorService.getFacilityList().then(response => {
            let facilities = JSON.parse(JSON.stringify(response.data))

            let ids = []

            facilities.forEach(item => {
                ids.push(item.id)
            })
            //For BE. When add new facility BE don't return data right away
            if (ids.length) {
                setTimeout(() => {
                    DistributorService.getActiveFacilityCounter(ids).then(
                        resp => {
                            let counters = resp.data.facilities

                            facilities.forEach(item => {
                                counters.forEach(count => {
                                    if (item.id === count.id) {
                                        item.doctors_count = count.doctors_count
                                        item.all_time_subscriptions_count =
                                            count.all_time_subscriptions_count
                                        item.subscriptions_count =
                                            count.subscriptions_count
                                        item.created_at = count.created_at
                                        item.list_specialization_fee =
                                            count.list_specialization_fee
                                    }
                                })
                            })

                            commit('GET_MEDICAL_FACILITY_LIST', facilities)
                        }
                    )
                }, 100)
            } else {
                commit('GET_MEDICAL_FACILITY_LIST', facilities)
            }
        })
    },
    setMedicalFacilityCurrentState({ commit, state }, data) {
        if (data && !state.activeFacility.is_active) {
            DistributorService.enableFacilityCreateSubscriptions(
                state.activeFacility.id
            )
        } else if (!data && state.activeFacility.is_active) {
            DistributorService.disableFacilityCreateSubscriptions(
                state.activeFacility.id
            )
        }
        commit('SET_MEDICAL_FACILITY_CURRENT_STATE', data)
    },
    setActiveFacility({ commit }, facility) {
        commit('SET_ACTIVE_FACILITY', facility)
    },
    setMedicalFacilityTrialMode({ commit }, data) {
        commit('SET_MEDICAL_FACILITY_TRIAL_MODE', data)
    },
    getKitsExist({ commit }, id) {
        DistributorService.getKitExisted(id).then(response => {
            commit('SET_KITS_EXISTED', response.data.is_kit_exist)
        })
    },
    setFacilityTrialMode({ commit }, data) {
        //this.setMedicalFacilityTrialMode()
        commit('SET_TRIAL_MODE', data)
    },
    setIdis2HomeDataFacility({ commit }, data) {
        DistributorService.generalInfo(data)
        commit('SET_IDIS2HOME_FACILITY_DATA', data)
    },
    async setDataToActiveFacility({ commit }, data) {
        await DistributorService.generalInfo(data)
        commit('SET_ACTIVE_FACILITY_DATA', data)
    },
    async UpdateFacility({ commit }, id) {
        await DistributorService.getActiveMedicalFacility(id).then(resp => {
            commit('SET_FACILITY_DATA', resp.data)
        })
    },
}
