import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
    ...mapState('auth', {
        access_token: state => state.access_token,
        refresh_token: state => state.refresh_token,
        storage_token: state => state.storage_token,
        isRememberMe: state => state.isRememberMe,
        websockets_token: state => state.websockets_token,
        role: state => state.role,
        deleteAccountDate: state => state.deleteAccountDate,
    }),
    ...mapGetters('auth', [
        'isAdmin',
        'isDoctor',
        'isDistributor',
        'isManager',
        'isOwner',
    ]),
}

export const authMethods = mapActions('auth', [
    'logIn',
    'logOut',
    'resetPassword',
    'changeRememberMe',
    'resetAuthHeaders',
    'setDeleteAccountDate',
    'setAvatarAccountDate',
    'deleteLogin',
])

export const baseMethods = mapActions('base', ['getSpecialties', 'setCurrency'])

export const baseComputed = {
    ...mapState('base', {
        specialtiesList: state => state.specialtiesList,
        currencies: state => state.currencies,
    }),
    ...mapGetters('base', [
        'getSpecializationById',
        'getSpecializationByCode',
        'getCurrencyById',
    ]),
}

// notifications
export const notificationsComputed = {
    ...mapGetters('notifications', ['error', 'info']),
}

export const notificationsMethods = {
    ...mapActions('notifications', ['setError', 'setInfo']),
}

export const videoComputed = {
    ...mapState('video', {
        isCallActive: state => state.isCallActive,
        activeVideoCall: state => state.activeVideoCall,
        channel: state => state.channel,
        sessionId: state => state.sessionId,
        userContacts: state => state.userContacts,
        callUserId: state => state.callUserId,
        incomingCall: state => state.incomingCall,
    }),
    ...mapGetters(
        'video',
        ['callState'],
        ['callUserContacts'],
        ['incomingCallState']
    ),
}

export const videoMethods = {
    ...mapActions('video', [
        'startVideoCall',
        'joinVideoCall',
        'joinVideoCallWait',
        'getDoctorContacts',
        'setCallUserId',
        'setincomingCall',
        'setProxyUrl',
        'hideVideoCall',
        'setChannel',
        'hideNotificationCall',
        'getTurnServers',
    ]),
}

export const commonComputed = {
    ...mapState('common', {
        userId: state => state.userId,
        allPatients: state => state.allPatients,
        userProfile: state => state.userProfile,
        languagesList: state => state.languagesList,
        has_demo: state => state.has_demo,
        demo_period: state => state.demo_period,
        demo: state => state.demo,
    }),
}

export const commonMethods = {
    ...mapActions('common', [
        'getUserContacts',
        'getAllUsers',
        'setUserProfile',
        'setUserProfileEmail',
        'setUserAvatar',
        'deleteUserAvatar',
        'getLanguages',
        'setOwnerId',
    ]),
}

export const chatComputed = {
    ...mapState('chat', {
        conversations: state => state.conversations,
        responseFiles: state => state.responseFiles,
        searchByMessages: state => state.searchByMessages,
        onlineStatus: state => state.onlineStatus,
        needToSendMessage: state => state.needToSendMessage,
        activeConversationId: state => state.activeConversationId,
        responseConversationsLength: state => state.responseConversationsLength,
        activeConversation: state => state.activeConversation,
        searchHandler: state => state.searchHandler,
        handlerNull: state => state.handlerNull,
        searchHandlerMessage: state => state.searchHandlerMessage,
    }),
    ...mapGetters('chat', ['chatTotalUnread', 'getConversationsId']),
}

export const chatMethods = {
    ...mapActions('chat', [
        'getDoctorConversations',
        'getStatuses',
        'getAllPatients',
        'deleteLastMessage',
        'deleteUserChat',
        'addConversation',
        'setActiveConversation',
        'addVideoUrl',
        'setFirstConversations',
        'throttleGetUser',
        'throttleGetMessage',
        'preloadNewMessages',
        'preloadMessageBottom',
        'getNewMassages',
        'scrollToMessage',
        'preloadSearchMessage',
        'setNewMessageData',
        'setReadMessageData',
        'addNeedToSendMessage',
        'deleteNeedToSendMessage',
    ]),
}

export const eventsComputed = {
    ...mapState('events', {
        measurements_list: state => state.measurements_list,
    }),
}

export const eventsMethods = {
    ...mapActions('events', ['getMeasurementsList']),
}

export const reportsComputed = {
    ...mapState('reports', {
        graphMode: state => state.graphMode,
        activeFilter: state => state.activeFilter,
        activeConversationReportId: state => state.activeConversationReportId,
        activeReportItem: state => state.activeReportItem,
    }),
}

export const reportsMethods = {
    ...mapActions('reports', [
        'setGraphMode',
        'setActiveFilter',
        'setActiveConversationReport',
        'setActiveReportItem',
    ]),
}

export const doctorComputed = {
    ...mapState('doctor', {
        isAscendingDoctor: state => state.isAscendingDoctor,
        isMainAscendingDoctor: state => state.isMainAscendingDoctor,
        mainTaskFilter: state => state.mainTaskFilter,
        newTaskFilter: state => state.newTaskFilter,
        activePatientId: state => state.activePatientId,
        enlargedMode: state => state.enlargedMode,
        dailyEvents: state => state.dailyEvents,
        eventFromNotify: state => state.eventFromNotify,
        videoCallsEvents: state => state.videoCallsEvents,
        todayVideoCallsEvents: state => state.todayVideoCallsEvents,
        videoCallList: state => state.videoCallList,
        videoCallListLoad: state => state.videoCallListLoad,
        activeSubscriptionId: state => state.activeSubscriptionId,
        medicalFacility: state => state.medicalFacility,
        rememberCalendarDate: state => state.rememberCalendarDate,
        contactsProfiles: state => state.contactsProfiles,
        showAddAvatar: state => state.showAddAvatar,
        notificationList: state => state.notificationList,
        newTasksList: state => state.newTasksList,
        inProgressTasksList: state => state.inProgressTasksList,
        taskById: state => state.taskById,
        newNotify: state => state.newNotify,
        performerList: state => state.performerList,
        taskHistory: state => state.taskHistory,
        witchDesign: state => state.witchDesign,
    }),
}

export const doctorMethods = {
    ...mapActions('doctor', [
        'setAscendingData',
        'setMainAscendingData',
        'setMainTaskFilter',
        'setNewTaskFilter',
        'setMainPageDesign',
        'setGeneralSorting',
        'setGeneralPage',
        'changeEnlargedMode',
        'setActivePatientId',
        'reloadTask',
        'getHomeDailyEvents',
        'setEventCheckState',
        'changeTaskStatus',
        'setEventlist',
        'cleanTaskByIdObj',
        'setTodayVideoList',
        'setLastCalendareDate',
        'getVideoCallList',
        'getVideoCallListPast',
        'getVideoCallListNext',
        'getMoreNotifications',
        'addMoreNotifications',
        'setActiveSubscriptionId',
        'setAvatarBlock',
        'loadMedicalFacility',
        'getNotificationList',
        'getNotificationLimit',
        'getNotificationById',
        'getNewListOfTasks',
        'getListOfDoctorTasks',
        'getTaskById',
        'getHistory',
        'getPerformerList',
        'getLimitNotificationById',
        'setNotificationReadState',
        'setAllNotificationsReadState',
        'setTrialExtension',
        'setApprovedStatus',
        'setApprovedStatusFromFacility',
        'setRejectedStatusFromFacility',
        'setNewNotifyStatus',
        'setCanceledStatus',
        'updateStatus',
        'setCurrentEvent',
    ]),
    ...mapGetters('doctor', ['translatedAvailableList']),
}

// distributor
export const distributorComputed = {
    ...mapState('distributor', {
        medical_facility_list: state => state.medical_facility_list,
        facilityLoaded: state => state.facilityLoaded,
        activeFacility: state => state.activeFacility,
        trialModeFacility: state => state.trialModeFacility,
    }),
    ...mapGetters('distributor', [
        'getMedicalFacilityCurrentState',
        'getMedicalFacilityCurrentTrialMode',
        'getMedicalFacilityCurrentIdis2HomeState',
    ]),
}

export const distributorMethods = {
    ...mapActions('distributor', [
        'getMedicalFacilityList',
        'setMedicalFacilityCurrentState',
        'setIdis2HomeDataFacility',
        'setActiveFacility',
        'setDataToActiveFacility',
        'setMedicalFacilityTrialMode',
        'setFacilityTrialMode',
        'getKitsExist',
        'UpdateFacility',
    ]),
}

// subscription
export const subscriptionComputed = {
    ...mapState('subscription', {
        measurementsList: state => state.measurementsList,
        subscriptionList: state => state.subscriptionList,
        patientGenderData: state => state.patientGenderData,
        subscriptionCopied: state => state.subscriptionCopied,
        subscriptionEdited: state => state.subscriptionEdited,
        subscriptionDrafted: state => state.subscriptionDrafted,
        subscriptionRanked: state => state.subscriptionRanked,
        changedDate: state => state.changedDate,
    }),
}

export const subscriptionMethods = {
    ...mapActions('subscription', [
        'getAllMeasurementsList',
        'getSubscriptionList',
        'setSubscriptionCopied',
        'setSubscriptionEdited',
        'setSubscriptionDrafted',
        'setRankedSubscriptions',
        'changeDuration',
    ]),
}

export const managerComputed = {
    ...mapState('manager', {
        managerDoctorsProfiles: state => state.managerDoctorsProfiles,
        activeDoctorProfile: state => state.activeDoctorProfile,
        activeFacility: state => state.activeFacility,
        managerPatients: state => state.managerPatients,
        activePatientProfile: state => state.activePatientProfile,
        activePatientManagerId: state => state.activePatientManagerId,
    }),
    ...mapGetters('manager', ['getMedicalFacilityCurrentState']),
}

export const managerMethods = {
    ...mapActions('manager', [
        'loadManagerDoctorsProfiles',
        'setActiveDoctorProfile',
        'findPatients',
        'setActivePatientManagerId',
        'setFacilityData',
    ]),
}

// Kits

export const kitsComputed = {
    ...mapState('kits', {
        kits: state => state.kits,
        kitsLoaded: state => state.kitsLoaded,
        kitsCurPage: state => state.kitsCurPage,
        totalKits: state => state.totalKits,
        totalPages: state => state.totalPages,
        kitsLimit: state => state.kitsLimit,
        sorting: state => state.kitsSorting,
        kitsFilter: state => state.kitsFilter,
        searchKitsBy: state => state.searchKitsBy,
        activeKit: state => state.activeKit,
        distributorsList: state => state.distributorsList,
        customersList: state => state.customersList,
        columnsKits: state => state.columnsKits,
        availableKits: state => state.availableKits,
    }),
    ...mapGetters('kits', ['availableKitsTranslated']),
}

export const kitsMethods = {
    ...mapActions('kits', [
        'setKitsCurPage',
        'getKitsList',
        'setKitsSorting',
        'setKitsFilter',
        'setSearchQuery',
        'setActiveKit',
        'updateKitsColumns',
    ]),
}

// general
export const doctorGeneralListComputed = {
    ...mapState('generalList', {
        //kits: state => state.kits,
        listLoaded: state => state.listLoaded,
        generalCurPage: state => state.generalCurPage,
        generalCount: state => state.generalCount,
        generalDates: state => state.generalDates,
        performerList: state => state.performerList,
        patientList: state => state.patientList,
        columnsGeneral: state => state.columnsGeneral,
        availableGeneralItems: state => state.availableGeneralItems,
        generalSorting: state => state.generalSorting,
        generalTaskList: state => state.generalTaskList,
        list: state => state.list,
        listLimit: state => state.listLimit,
        generalFilter: state => state.generalFilter,
    }),
    ...mapGetters('generalList', ['translatedAvailableListGeneral']),
}

export const doctorGeneralListMethods = {
    ...mapActions('generalList', [
        'setLoadedList',
        'getGeneralTaskList',
        'setGeneralSorting',
        'getPerformerList',
        'getPatientList',
        'setGeneralPage',
        'setGeneralFilter',
        'updateGeneralColumns',
    ]),
}

export const ownerMethods = {
    ...mapActions('owner', [
        'addDistributor',
        'getDistributor',
        'changeDistributor',
        'setActiveDistributor',
        'setActiveDistributorHomePage',
        'loadFacilities',
        'getFacilityStatistic',
        'getAllFacilityStatistic',
        'setNoFacility',
        'getPeriodStatistic',
        'setActiveFacility',
        'setActiveFacilityKey',
        'setMedicalFacilityCurrentState',
        'setDistributorTrialMode',
        'getNotificationList',
        'getNotificationById',
        'setNotificationReadState',
        'setAllNotificationsReadState',
        'setTrialExtension',
        'setApprovedStatus',
        'setApprovedStatusFromFacility',
        'setRejectedStatusFromFacility',
        'setNewNotifyStatus',
    ]),
}

export const ownerComputed = {
    ...mapState('owner', {
        distributors: state => state.distributors,
        activeDistributor: state => state.activeDistributor,
        activeFacilityStat: state => state.activeFacilityStat,
        activeDistributorId: state => state.activeDistributorId,
        activeFacilityId: state => state.activeFacilityId,
        activeFacility: state => state.activeFacility,
        noFacility: state => state.noFacility,
        activeMedicalFacility: state => state.activeMedicalFacility,
        activeFacilityList: state => state.activeFacilityList,
        medicalFacilityList: state => state.medicalFacilityList,
        currentMedicalFacilityStateEnabled: state =>
            state.currentMedicalFacilityStateEnabled,
        noStatisticDistributor: state => state.noStatisticDistributor,
        statisticOwnerLoading: state => state.statisticOwnerLoading,
        notificationList: state => state.notificationList,
        newNotify: state => state.newNotify,
    }),
    ...mapGetters('owner', [
        'getMedicalFacilityCurrentState',
        'getDistributorTrialMode',
    ]),
}
