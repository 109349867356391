import Auth from './modules/auth.js'
import User from './modules/user.js'
import Distributor from './modules/distributor.js'
import Manager from './modules/manager.js'
import Owner from './modules/owner.js'

export default [
    ...Auth,
    ...User,
    ...Distributor,
    ...Manager,
    ...Owner,

    // otherwise redirect to home
    { path: '*', redirect: '/' },
]
