import ManagerService from '@services/manager-service'
import { loadManagerDoctorsAvatars } from '@utils/loadManagerDoctorsAvatars'
import _ from 'lodash'
// import { getFiles } from '@services/base-service'
import Vue from 'vue'

export const state = {
    managerDoctorsProfiles: [],
    activeDoctorProfile: {},
    activeFacility: {},
    activePatientProfile: {},
    managerPatients: [],
    activePatientManagerId: undefined,
}

export const getters = {
    getMedicalFacilityCurrentState(state) {
        return state.activeFacility.is_active
    },
}

export const mutations = {
    SET_DOCTORS_PROFILES(state, payload) {
        state.managerDoctorsProfiles = payload
    },
    SET_CONTACTS_DATA(state, payload) {
        state.managerPatients = payload
    },
    SET_ACTIVE_PATIENT_MANAGER_ID(state, id) {
        Vue.set(state, 'activePatientManagerId', id)
    },
    SET_ACTIVE_DOCTOR_PROFILE(state, { payload, rootGetters }) {
        if (payload.profiles.length) {
            let profile = payload.profiles[0]
            profile.doctor_data.specializationObj = rootGetters[
                'base/getSpecializationById'
            ](profile.doctor_data.specialization_id)
            state.activeDoctorProfile = profile
        }
    },
    SET_FACILITY_DATA(state, payload) {
        Vue.set(state, 'activeFacility', payload)
    },
    SET_PATIENT_PROFILE_DATA(state, payload) {
        state.activePatientProfile = payload
    },
}

export const actions = {
    async loadManagerDoctorsProfiles({ commit }) {
        try {
            const payload = (
                await ManagerService.getManagerDoctorsProfilesService()
            ).data

            if (payload) {
                loadManagerDoctorsAvatars(payload, function() {
                    commit('SET_DOCTORS_PROFILES', payload)
                })
            }
        } catch (e) {
            console.log(e)
        }
    },
    // throttleGetUser: _.debounce(({ commit, state }, query) => {}),
    async findPatients({ commit }, query) {
        await ManagerService.getPatientsManagerProfile(query).then(response => {
            commit('SET_CONTACTS_DATA', response.data.contacts)
        })
    },
    async setActivePatientManagerId({ commit }, id) {
        await ManagerService.getPatientManagerProfileInfo(id).then(response => {
            commit('SET_PATIENT_PROFILE_DATA', response.data[0])
            commit('SET_ACTIVE_PATIENT_MANAGER_ID', id)
        })
    },
    async setActiveDoctorProfile({ commit, rootGetters }, id) {
        try {
            const payload = (
                await ManagerService.getManagerDoctorsProfilesDetailService(id)
            ).data
            if (payload.profiles.length) {
                loadManagerDoctorsAvatars(payload.profiles, function() {
                    commit('SET_ACTIVE_DOCTOR_PROFILE', {
                        payload,
                        rootGetters,
                    })
                })
            }
        } catch (e) {
            console.log(e)
        }
    },
    async setFacilityData({ commit }) {
        await ManagerService.generalInfo().then(async response => {
            await ManagerService.getFacilityCounters(response.data.id)
                .then(resp => {
                    let data = Object.assign(
                        response.data,
                        resp.data.facilities[0]
                    )
                    commit('SET_FACILITY_DATA', data)
                })
                .catch(() => {
                    commit('SET_FACILITY_DATA', response.data)
                })
        })
    },
}
