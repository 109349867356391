import ChatService from '@services/chat-service'
import Vue from 'vue'
import { getFiles } from '@services/base-service'
import { prepareProfiles } from '@utils/preparation-profiles'
import { determineDate } from '@utils/timeRefactor'
import { i18n } from '@/i18n'
import store from '@state/store'
import router from '@router'
import _ from 'lodash'
const translate = i18n.t.bind(i18n)

export const state = {
    onlineStatus: [],
    searchByMessages: false,
    responseFiles: [],
    conversations: [
        {
            avatar: '',
            full_name: '',
            id: '',
            last_message_files: undefined,
            last_message_status: undefined,
            last_message_text: '',
            last_message_time: undefined,
            messages: [
                {
                    author_id: '',
                    conversation_id: '',
                    draft: undefined,
                    files: [
                        {
                            blob: '',
                            id: '',
                            message_id: '',
                            name: '',
                            newName: '',
                            size: undefined,
                            thumbnail: '',
                            type: undefined,
                            url: '',
                        },
                    ],
                    id: '',
                    state: undefined,
                    text: '',
                    timestamp: undefined,
                },
            ],
            name: '',
            participants: [],
            surname: '',
            unread_messages: undefined,
        },
    ],
    searchHandler: {},
    handlerNull: false,
    searchHandlerMessage: [],
    activeConversationId: undefined,
    responseConversationsLength: undefined,
    incomingMessage: {
        conversation_id: '',
        message_id: '',
        unread_messages: undefined,
    },
    readMessage: {
        conversation_id: '',
        message_id: '',
    },
    conversationIsPreLoading: {},
    conversationIsPreLoadingDown: {},
    isSearch: true,
    activeConversation: {
        avatar: '',
        full_name: '',
        id: '',
        last_message_status: undefined,
        last_message_text: '',
        last_message_time: undefined,
        messages: [],
        name: '',
        participants: [],
        surname: '',
        unread_messages: undefined,
    },
    needToSendMessage: [],
}

export const mutations = {
    GET_CONVERSATIONS(state, { conversations, contactsProfiles }) {
        let preparedConversations = []
        if (state.conversations[0].id) {
            let newConversations = []
            let currentConversationsIds = []
            state.conversations.map(item => {
                currentConversationsIds.push(item.id)
            })
            conversations.map(newConversation => {
                if (!currentConversationsIds.includes(newConversation.id)) {
                    newConversations.push(newConversation)
                }
            })
            const { updatedConversations } = prepareProfiles(
                newConversations,
                contactsProfiles,
                state
            )
            const preparedConversations = updatedConversations
            const currentConversations = JSON.parse(
                JSON.stringify(state.conversations)
            )
            state.conversations = null
            Vue.set(state, 'conversations', [
                ...currentConversations,
                ...preparedConversations,
            ])
        } else {
            state.conversations = null
            const { updatedConversations } = prepareProfiles(
                conversations,
                contactsProfiles,
                state
            )
            preparedConversations = updatedConversations
            Vue.set(state, 'conversations', preparedConversations)
        }
    },
    // SET_CONVERSATION_RESPONSE(state, data) {
    //     state.responseConversationsLength = data
    // },
    SET_CONVERSATION_RESPONSE(state, data) {
        if (
            store.state.common.userProfile.doctor_data
                .subscription_administrator &&
            data === 0
        ) {
            state.responseConversationsLength = true
        } else {
            state.responseConversationsLength = data
        }
    },
    ADD_CONVERSATION(state, payload) {
        let conversationId = payload.conversationId,
            message = payload.message
        for (let i = 0; i < state.conversations.length; i++) {
            if (state.conversations[i].id === conversationId) {
                let isMessageInConversation = false
                if (!state.conversations[i].messages) {
                    Vue.set(state.conversations[i], 'messages', [])
                }
                for (
                    let j = 0;
                    j < state.conversations[i].messages.length;
                    j++
                ) {
                    if (state.conversations[i].messages[j].id === message.id) {
                        isMessageInConversation = true
                    }
                }
                if (!isMessageInConversation) {
                    let day = Vue.options.filters.monthDay(
                        message.timestamp * 1000
                    )

                    let lastMessage
                    if (state.conversations[i].messages.length > 0) {
                        lastMessage =
                            state.conversations[i].messages[
                                state.conversations[i].messages.length - 1
                            ]
                    }
                    if (lastMessage) {
                        let prev_day = Vue.options.filters.monthDay(
                            lastMessage.timestamp * 1000
                        )
                        message.showDateBlock = day !== prev_day
                    } else {
                        message.showDateBlock = true
                    }

                    if (determineDate('isToday', message.timestamp * 1000)) {
                        day = translate('w_today')
                    } else if (
                        determineDate('isYesterday', message.timestamp * 1000)
                    ) {
                        day = translate('w_yesterday')
                    }
                    message.messagesDate = day

                    Vue.set(
                        state.conversations[i].messages,
                        state.conversations[i].messages.length,
                        message
                    )

                    state.conversations[i].last_message_status =
                        state.userId === message.author_id ? message.state : 0
                    Vue.set(
                        state.conversations[i],
                        'last_message_time',
                        message.timestamp
                    )
                    Vue.set(
                        state.conversations[i],
                        'last_message_text',
                        message.text
                    )
                    if (message.files) {
                        if (message.files.length) {
                            let lastKey = +message.files.length - 1
                            Vue.set(
                                state.conversations[i],
                                'last_message_files',
                                message.files[lastKey].type
                            )
                        } else {
                            Vue.set(
                                state.conversations[i],
                                'last_message_files',
                                message.files
                            )
                        }
                    }
                }
            }
        }
    },
    ADD_VIDEO_TO_CONVERSATION(state, payload) {
        let ind = payload.conversation_ind,
            file_ind = payload.file_ind,
            url = payload.url

        Vue.set(
            state.conversations[ind].messages[
                state.conversations[ind].messages.length - 1
            ].files[file_ind],
            'url',
            url
        )
    },

    SET_ACTIVE_CONVERSATION(state, id) {
        Vue.set(state, 'activeConversationId', id)
        let active_dialog = state.conversations.filter(conversation => {
            return conversation.id === id
        })
        Vue.set(state, 'activeConversation', active_dialog[0])
    },
    SHOW_LAST_DATE_FROM_HISTORY(state) {
        state.conversations.filter(conversation => {
            if (conversation.id === state.activeConversationId) {
                conversation.messages[0].showDateBlock = true
            }
        })
    },
    HIDE_LAST_DATE_FROM_HISTORY(state) {
        state.conversations.filter(conversation => {
            if (conversation.id === state.activeConversationId) {
                conversation.messages[0].showDateBlock = false
            }
        })
    },
    SHOW_LAST_DATE_FROM_HISTORY_LAST_MESSAGE(state) {
        state.conversations.filter(conversation => {
            if (conversation.id === state.activeConversationId) {
                Vue.set(
                    conversation.messages[conversation.messages.length - 1],
                    'showDateBlock',
                    true
                )
            }
        })
    },
    HIDE_LAST_DATE_FROM_HISTORY_LAST_MESSAGE(state) {
        state.conversations.filter(conversation => {
            if (conversation.id === state.activeConversationId) {
                Vue.set(
                    conversation.messages[conversation.messages.length - 1],
                    'showDateBlock',
                    false
                )
            }
        })
    },
    SET_CONVERSATION_UNREAD_MESSAGES(state, { conversation, unreadMessages }) {
        state.conversations.forEach(conv => {
            if (conv.id === conversation.id) {
                Vue.set(conversation, 'unread_messages', unreadMessages)
            }
        })
    },
    SET_FIRST_CONVERSATIONS(state, index) {
        function array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                let k = new_index - arr.length + 1
                while (k--) {
                    arr.push(undefined)
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
            return arr
        }

        state.conversations = array_move(state.conversations, index, 0)
    },

    SET_NEW_MESSAGE_DATA(state, data) {
        state.incomingMessage.message_id = data.message_id
        state.incomingMessage.conversation_id = data.conversation_id
        state.incomingMessage.unread_messages = data.unread_messages

        state.conversations.forEach(conversation => {
            if (conversation.id === state.incomingMessage.conversation_id) {
                Vue.set(
                    conversation,
                    'unread_messages',
                    state.incomingMessage.unread_messages
                )
            }
        })
    },
    SET_IS_MESSAGES(state, payload) {
        state.searchByMessages = payload
    },
    ADD_NEW_MESSAGE_TO_CONVERSATION(
        state,
        { conversationId, messages, mess, userId }
    ) {
        Vue.set(messages, messages.length, mess)
        state.conversations.map(conversation => {
            if (conversationId === conversation.id) {
                let lastMessage = conversation.messages
                    ? conversation.messages[conversation.messages.length - 1]
                    : undefined

                if (lastMessage) {
                    conversation.last_message_status =
                        userId === lastMessage.author_id ? lastMessage.state : 0
                    Vue.set(
                        conversation,
                        'last_message_time',
                        lastMessage.timestamp
                    )
                    Vue.set(conversation, 'last_message_text', lastMessage.text)
                    if (lastMessage.files) {
                        if (lastMessage.files.length) {
                            let lastKey = +lastMessage.files.length - 1
                            Vue.set(
                                conversation,
                                'last_message_files',
                                lastMessage.files[lastKey].type
                            )
                        } else {
                            Vue.set(
                                conversation,
                                'last_message_files',
                                lastMessage.files
                            )
                        }
                    }
                }
            }
        })
    },
    SET_READ_MESSAGE_DATA(state, data) {
        state.readMessage.message_id = data.message_id
        state.readMessage.conversation_id = data.conversation_id
    },
    SET_PRELOADING_CONVERSATION_STATE(state, { id, loadingState }) {
        state.conversationIsPreLoading[id] = loadingState
    },
    SET_PRELOADING_DOWN_CONVERSATION_STATE(state, { id, loadingState }) {
        state.conversationIsPreLoadingDown[id] = loadingState
    },
    CONCAT_MESSAGES(state, { messages, activeConversationId }) {
        state.conversations.forEach(conversation => {
            if (conversation.id === activeConversationId) {
                if (
                    messages[messages.length - 1].id ===
                    conversation.messages[conversation.messages.length - 1].id
                ) {
                    Vue.set(conversation, 'messages', messages)
                } else {
                    let result = [...messages, ...conversation.messages]
                    Vue.set(conversation, 'messages', result)
                }
            }
        })
    },
    CONCAT_MESSAGES_DOWN(state, { messages, activeConversationId }) {
        state.conversations.forEach(conversation => {
            if (conversation.id === activeConversationId) {
                if (
                    messages[messages.length - 1].id ===
                    conversation.messages[conversation.messages.length - 1].id
                ) {
                    Vue.set(conversation, 'messages', messages)
                } else {
                    let result = [...conversation.messages, ...messages]
                    Vue.set(conversation, 'messages', result)
                }
            }
        })
    },
    READ_UNREAD_MESSAGES(state) {
        state.activeConversation.unread_messages = 0
    },
    SET_READ_STATUS(state, { conversation_id }) {
        let messages = state.activeConversation.messages
        for (let i = messages.length - 1; i > 0; i--) {
            messages[i].state = 'read'
        }
        state.conversations.map(item => {
            if (item.id === conversation_id) {
                item.last_message_status = 'read'
            }
        })
    },
    ADD_BLOB(state, { conversation, message, file, blob, size }) {
        state.conversations.forEach(convers => {
            if (convers.id === conversation.id) {
                convers.messages.forEach(mess => {
                    if (mess.id === message.id) {
                        mess.files.forEach(message_file => {
                            if (message_file.id === file.id) {
                                Vue.set(file, 'blob', blob)
                                Vue.set(file, 'size', size)
                            }
                        })
                    }
                })
            }
        })
    },
    SET_SENT_STATUS(state, id) {
        state.conversations.forEach(conversation => {
            if (conversation.id === state.activeConversationId) {
                conversation.messages.forEach(mess => {
                    if (mess.id === id) {
                        mess.state = 'sent'
                    }
                })
                if (
                    conversation.messages[conversation.messages.length - 1]
                        .state === 'sent'
                ) {
                    conversation.last_message_status = 'sent'
                }
            }
        })
    },
    DELETE_LAST_MESSAGE(state) {
        state.activeConversation.messages.pop()
    },
    SET_RESPONSE_FILES(state, payload) {
        state.responseFiles.push(payload)
    },
    DELETE_USER_CHAT(state) {
        let index = undefined
        state.conversations.forEach((conversation, i) => {
            if (state.activeConversationId === conversation.id) {
                index = i
            }
        })
        state.conversations.splice(index, 1)
        state.activeConversationId = undefined
    },
    ADD_NEED_TO_SEND_MESSAGE(state, payload) {
        Vue.set(
            state.needToSendMessage,
            state.needToSendMessage.length,
            payload
        )
    },

    DELETE_NEED_TO_SEND_MESSAGE(state) {
        state.needToSendMessage = []
    },
    SET_PATIENTS_AVATARS(state, { profile, avatar }) {
        state.conversations.map(item => {
            if (item.patient_id === profile.id) {
                Vue.set(item, 'avatar', avatar)
            }
        })
    },

    UNIQUE_MESSAGE(state) {
        if (state.activeConversation.messages.length) {
            let newMes = _.uniqBy(
                state.activeConversation.messages,
                'internal_id'
            )
            Vue.set(state.activeConversation, 'messages', newMes)
        }
    },
    GET_SEARCH_USERS_MESSAGES(state, searchHandler) {
        state.searchHandler = searchHandler
    },
    SET_SEARCH_USERS_MESSAGES(state, searchHandler) {
        state.searchHandler = searchHandler
    },
    GET_SEARCH_MESSAGES(state, payload) {
        state.searchHandlerMessage = payload
    },
    SET_HANDLER_NULL(state, payload) {
        state.handlerNull = payload
    },
    DELETE_ITEMS(state) {
        state.searchHandlerMessage = []
    },
    GET_HANDLER_TO_NULL(state) {
        state.searchHandlerMessage = null
    },
    GET_USER_MESSAGE_AVATAR(state, { user, avatar }) {
        state.searchHandlerMessage.map(item => {
            if (user.message_id === item.message_id) {
                Vue.set(item, 'avatar', avatar)
            }
        })
    },
    GET_USER_AVATAR(state, { user, avatar }) {
        if (state.searchHandler.by_participant !== null) {
            state.searchHandler.by_participant.map(item => {
                if (user.patient_id === item.patient_id) {
                    Vue.set(item, 'avatar', avatar)
                }
            })
        }
        if (state.searchHandler.by_messages !== null) {
            state.searchHandler.by_messages.map(item => {
                if (user.patient_id === item.patient_id) {
                    Vue.set(item, 'avatar', avatar)
                }
            })
        }
    },
    SET_SEARCH_LIST(state, { index, data }) {
        Vue.set(state.conversations[index], 'messages', data)
    },
    SET_STATUSES(state, onlineStatus) {
        state.onlineStatus = onlineStatus
    },
}
export const getters = {
    chatTotalUnread(state) {
        if (state.conversations) {
            return state.conversations.reduce(function(
                previousValue,
                currentValue
            ) {
                return (
                    parseInt(previousValue ? previousValue : 0) +
                    parseInt(
                        currentValue.unread_messages
                            ? currentValue.unread_messages
                            : 0
                    )
                )
            },
            0)
        }
    },
    getConversationsId(state) {
        let data = {
            IDs: state.conversations.map(item => item.patient_id),
        }
        return data
    },
    MyFilteredMessages(state) {
        let index = undefined
        if (state.activeConversationId) {
            state.conversations.forEach((conversation, i) => {
                if (state.activeConversationId === conversation.id) {
                    index = i
                }
            })
        }
        return this.conversations[index].messages
    },
}

export const actions = {
    deleteLastMessage({ commit }) {
        commit('DELETE_LAST_MESSAGE')
    },
    throttleGetMessage: _.debounce(({ commit, state }, query) => {
        const activeConversationId = state.activeConversationId
        if (query.length > 0) {
            ChatService.searchMessageInChat(activeConversationId, query).then(
                response => {
                    if (response.data !== null) {
                        let data = response.data
                        let newResponse = []
                        data.map(item => {
                            let newData = {
                                author_id: item.author_id,
                                avatar: item.avatar,
                                internal_id: item.internal_id,
                                last_message_time: item.message_created_at,
                                last_message_text: item.message_text,
                                message_id: item.message_id,
                                name: item.name,
                                number_of_messages: item.number_of_messages,
                                surname: item.surname,
                            }
                            newResponse.push(newData)
                        })
                        commit('GET_SEARCH_MESSAGES', newResponse)
                        state.searchHandlerMessage.forEach(async user => {
                            if (user.avatar !== '') {
                                await getFiles(user.avatar)
                                    .then(avatar => {
                                        commit('GET_USER_MESSAGE_AVATAR', {
                                            user,
                                            avatar,
                                        })
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })
                            }
                        })
                    } else {
                        commit('GET_HANDLER_TO_NULL')
                    }
                }
            )
        } else {
            commit('DELETE_ITEMS')
        }
    }, 500),
    throttleGetUser: _.debounce(({ commit, state }, query) => {
        if (query.length > 0) {
            ChatService.searchUserMessage(query).then(response => {
                let data = response.data
                let handler = {
                    by_participant: [],
                    by_messages: [],
                }
                data.by_participant.map(item => {
                    let by_participant = {
                        avatar: item.messages[0]?.avatar,
                        birthday: '',
                        name: item.messages[0].participant_name,
                        conversation_id: item.id,
                        surname: item.messages[0].participant_surname,
                        subscription_name: item.subscription_name,
                        last_message_files: item.messages[0].last_message_files,
                        last_message_text: item.messages[0].text,
                        last_message_time: item.messages[0].timestamp,
                        patient_id: item.participants[0],
                        unread_messages: item.unread_messages,
                    }
                    handler.by_participant.push(by_participant)
                })
                data.by_messages.map(item => {
                    let by_messages = {
                        avatar: item.messages[0].avatar,
                        birthday: '',
                        name: item.messages[0].participant_name,
                        surname: item.messages[0].participant_surname,
                        message_id: item.messages[0].id,
                        internal_id: item.messages[0].internal_id,
                        conversation_id: item.id,
                        subscription_name: item.subscription_name,
                        last_message_files: item.messages[0].last_message_files,
                        last_message_text: item.messages[0].text,
                        last_message_time: item.messages[0].timestamp,
                        patient_id: item.messages[0].author_id,
                        unread_messages: item.unread_messages,
                    }
                    handler.by_messages.push(by_messages)
                })
                commit('GET_SEARCH_USERS_MESSAGES', handler)
                if (state.searchHandler.by_participant !== null) {
                    state.searchHandler.by_participant.forEach(async user => {
                        if (user.avatar !== null) {
                            await getFiles(user.avatar.min)
                                .then(avatar => {
                                    commit('GET_USER_AVATAR', {
                                        user,
                                        avatar,
                                    })
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        }
                    })
                }
                if (state.searchHandler.by_messages !== null) {
                    state.searchHandler.by_messages.forEach(async user => {
                        if (user.avatar !== null) {
                            await getFiles(user.avatar.min)
                                .then(avatar => {
                                    commit('GET_USER_AVATAR', {
                                        user,
                                        avatar,
                                    })
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        }
                    })
                }

                if (!data.by_participant.length && !data.by_messages.length) {
                    commit('SET_HANDLER_NULL', true)
                } else {
                    commit('SET_HANDLER_NULL', false)
                }
            })
        } else {
            let handler = {
                by_participant: [],
                by_messages: [],
            }
            commit('SET_HANDLER_NULL', false)
            commit('SET_SEARCH_USERS_MESSAGES', handler)
        }
    }, 1000),
    deleteUserChat({ commit }) {
        // commit('DELETE_USER_CHAT')
        ChatService.deleteChat(state.activeConversationId).then(() => {
            commit('DELETE_USER_CHAT')
        })
    },
    getStatuses({ commit, getters, state }) {
        ChatService.postOnlineUsersStatus(getters.getConversationsId).then(
            response => {
                commit('SET_STATUSES', response.data)
                // let resp = response.data
                state.conversations.forEach(elem => {
                    state.onlineStatus.forEach(item => {
                        if (elem.patient_id === item.user_id) {
                            elem.status = item.status
                        }
                    })
                })
            }
        )
    },
    async getDoctorConversations(
        { commit, rootState },
        messages_per_conversation
    ) {
        return ChatService.getConversations(messages_per_conversation)
            .then(response => {
                let conversations = response.data.conversations
                if (conversations && conversations.length) {
                    let counter = 0
                    let haveFiles = false
                    conversations.forEach(conversation => {
                        if (
                            conversation.messages &&
                            conversation.messages.length
                        ) {
                            conversation.messages.forEach(message => {
                                if (message.files) {
                                    haveFiles = true
                                    message.files.forEach(file => {
                                        counter++
                                        if (file.type !== 'file') {
                                            getFiles(file.url, true).then(
                                                resp => {
                                                    let payload = {
                                                        conversation,
                                                        message,
                                                        file,
                                                        blob: resp.url,
                                                        size: resp.size,
                                                    }

                                                    commit('ADD_BLOB', payload)
                                                    if (--counter === 0) {
                                                        commit(
                                                            'SET_CONVERSATION_RESPONSE',
                                                            conversations.length
                                                        )
                                                        commit(
                                                            'GET_CONVERSATIONS',
                                                            {
                                                                conversations: conversations,
                                                                contactsProfiles:
                                                                    rootState
                                                                        .doctor
                                                                        .contactsProfiles,
                                                            }
                                                        )
                                                    }
                                                }
                                            )
                                        } else {
                                            if (--counter === 0) {
                                                commit(
                                                    'SET_CONVERSATION_RESPONSE',
                                                    conversations.length
                                                )
                                                commit('GET_CONVERSATIONS', {
                                                    conversations: conversations,
                                                    contactsProfiles:
                                                        rootState.doctor
                                                            .contactsProfiles,
                                                })
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    })
                    // TODO Refactor case if messages have not any message
                    if (!haveFiles) {
                        commit(
                            'SET_CONVERSATION_RESPONSE',
                            conversations.length
                        )
                        commit('GET_CONVERSATIONS', {
                            conversations: conversations,
                            contactsProfiles: rootState.doctor.contactsProfiles,
                        })
                    }
                } else {
                    console.log(conversations, 'conversations222')
                    let conversationsLength = 0
                    if (conversations) {
                        conversationsLength = conversations.length
                    }

                    if (conversations === undefined) {
                        conversations = []
                    }
                    commit('GET_CONVERSATIONS', {
                        conversations: conversations,
                        contactsProfiles: rootState.doctor.contactsProfiles,
                    })
                    commit('SET_CONVERSATION_RESPONSE', conversationsLength)
                }
                return response.data.conversations
            })
            .catch(error => {
                console.error(error)
            })
    },
    addConversation({ commit, state, dispatch }, payload) {
        let sendFile = []
        if (payload.message.files && payload.message.files.length > 0) {
            for (let i = 0; i < payload.message.files.length; i++) {
                sendFile.push({
                    type: payload.message.files[i].type,
                    name: payload.message.files[i].name,
                })
            }
        }
        let data = {
            conversation_id: payload.conversationId,
            text: payload.message.text,
            files: sendFile.length ? sendFile : undefined,
        }
        return ChatService.sendMessage(data)
            .then(response => {
                let index
                state.conversations.map((item, ind) => {
                    if (item.id === payload.conversationId) {
                        index = ind
                    }
                })
                if (response.data.files && response.data.files.length) {
                    let files = response.data.files
                    files.forEach(item => {
                        commit('SET_RESPONSE_FILES', item)
                    })
                }

                const newMessage = {
                    ...payload.message,
                    internal_id: response.data.internal_id,
                    state: response.data.state,
                }

                commit('ADD_CONVERSATION', {
                    ...payload,
                    message: newMessage,
                })

                commit('SET_FIRST_CONVERSATIONS', index)
                dispatch('setActiveConversation', payload.conversationId)
                commit('SET_SENT_STATUS', payload.message.id)

                return response
            })
            .catch(error => {
                return error.message
            })
    },
    followToMessage({ commit, state }, id) {
        if (id) {
            let active_dialog = state.conversations.filter(conversation => {
                return conversation.id === id
            })
            let message = active_dialog[0].messages[0]
            if (message && message.files) {
                message.files.forEach(file => {
                    if (file.type !== 'file') {
                        getFiles(file.url, true).then(resp => {
                            let payload = {
                                conversation: active_dialog[0],
                                message,
                                file,
                                blob: resp.url,
                                size: resp.size,
                            }
                            commit('ADD_BLOB', payload)
                        })
                    }
                })
            }
        }
    },
    setActiveConversation({ commit, state, dispatch }, id) {
        if (id) {
            let active_dialog = state.conversations.filter(conversation => {
                return conversation.id === id
            })
            let message = active_dialog[0].messages[0]
            if (message && message.files) {
                message.files.forEach(file => {
                    if (file.type !== 'file') {
                        getFiles(file.url, true).then(resp => {
                            let payload = {
                                conversation: active_dialog[0],
                                message,
                                file,
                                blob: resp.url,
                                size: resp.size,
                            }
                            commit('ADD_BLOB', payload)
                        })
                    }
                })
            }
        }

        commit('SET_ACTIVE_CONVERSATION', id)
        dispatch('readUnreadMessages')
    },
    readUnreadMessages({ commit, state, rootState }) {
        let messages = state.activeConversation
            ? state.activeConversation.messages
            : []
        if (messages.length) {
            if (state.activeConversation.unread_messages === 1) {
                if (
                    messages[messages.length - 1].author_id !==
                    rootState.common.userId
                ) {
                    ChatService.readMessages(
                        messages[messages.length - 1].id
                    ).then(() => {
                        commit('READ_UNREAD_MESSAGES')
                    })
                }
            } else if (state.activeConversation.unread_messages > 1) {
                for (let i = messages.length - 1; i > 0; i--) {
                    if (messages[i].author_id !== rootState.common.userId) {
                        ChatService.readMessages(messages[i].id)
                            .then(() => {
                                commit('READ_UNREAD_MESSAGES')
                            })
                            .catch(error => {
                                console.log(error)
                            })
                        break
                    }
                }
            }
        }
    },
    addVideoUrl({ commit }, payload) {
        let conversationId = payload.id

        for (let i = 0; i < state.conversations.length; i++) {
            if (state.conversations[i].id === conversationId) {
                let data = {
                    file_ind: payload.file_ind,
                    conversation_ind: i,
                    url: payload.url,
                }
                commit('ADD_VIDEO_TO_CONVERSATION', data)
            }
        }
    },
    setFirstConversations({ commit }, id) {
        commit('SET_FIRST_CONVERSATIONS', id)
    },
    getNewMassages(
        { state, commit },
        { conversationId, internalId, byMessages }
    ) {
        commit('SET_IS_MESSAGES', byMessages)
        let index = undefined
        let limit = 20
        let internalIdNew
        state.conversations.forEach((item, i) => {
            if (item.id === conversationId) {
                index = i
                if (internalId < 10) {
                    internalIdNew = internalId
                } else {
                    internalIdNew = internalId - 9
                }
                ChatService.searchNewMessages(
                    conversationId,
                    internalIdNew,
                    limit
                ).then(response => {
                    let data = response.data
                    if (data.length >= 1) {
                        let counter = 0
                        const filesArray = []

                        state.conversations.forEach(conversation => {
                            if (conversation.id === conversationId) {
                                let arrLength = data.length
                                data[0].showDateBlock = true
                                data.forEach((mess, index) => {
                                    mess.files = mess.files ? mess.files : []

                                    let date = mess
                                    let day = Vue.options.filters.monthDay(
                                        date.timestamp * 1000
                                    )

                                    let new_key = +index + 1
                                    if (arrLength > new_key) {
                                        let new_date = data[new_key]
                                        let new_day = Vue.options.filters.monthDay(
                                            new_date.timestamp * 1000
                                        )
                                        new_date.showDateBlock = day !== new_day
                                    } else if (arrLength === index + 1) {
                                        let lastCurrentMessage =
                                            conversation.messages[0]
                                        let last_day = Vue.options.filters.monthDay(
                                            lastCurrentMessage.timestamp * 1000
                                        )
                                        if (day === last_day) {
                                            commit(
                                                'HIDE_LAST_DATE_FROM_HISTORY'
                                            )
                                        } else {
                                            commit(
                                                'SHOW_LAST_DATE_FROM_HISTORY'
                                            )
                                        }
                                    }
                                    if (
                                        determineDate(
                                            'isToday',
                                            date.timestamp * 1000
                                        )
                                    ) {
                                        day = translate('w_today')
                                    } else if (
                                        determineDate(
                                            'isYesterday',
                                            date.timestamp * 1000
                                        )
                                    ) {
                                        day = translate('w_yesterday')
                                    } else {
                                        day = Vue.options.filters.monthDay(
                                            date.timestamp * 1000
                                        )
                                    }
                                    mess.messagesDate = day
                                    if (mess.files) {
                                        mess.files.sort((a, b) =>
                                            a.type > b.type ? 1 : -1
                                        )
                                    }
                                    mess.files.forEach(file => {
                                        counter++
                                        if (file.type !== 'file') {
                                            getFiles(file.url, true).then(
                                                resp => {
                                                    let payload = {
                                                        conversation,
                                                        message: mess,
                                                        file,
                                                        blob: resp.url,
                                                        size: resp.size,
                                                    }
                                                    filesArray.push(payload)
                                                    if (--counter === 0) {
                                                        commit(
                                                            'CONCAT_MESSAGES',
                                                            {
                                                                data,
                                                                conversationId,
                                                            }
                                                        )
                                                        filesArray.forEach(
                                                            payload => {
                                                                commit(
                                                                    'ADD_BLOB',
                                                                    payload
                                                                )
                                                            }
                                                        )
                                                    }
                                                }
                                            )
                                        } else {
                                            if (--counter === 0) {
                                                commit('CONCAT_MESSAGES', {
                                                    data,
                                                    conversationId,
                                                })
                                            }
                                        }
                                    })
                                })
                            }
                        })
                        if (!counter) {
                            commit('CONCAT_MESSAGES', {
                                data,
                                conversationId,
                            })
                        }
                        commit('SET_SEARCH_LIST', { index, data })
                        commit('SET_IS_MESSAGES', false)
                    }
                })
            }
        })
    },
    preloadMessageBottom({ commit, state }, $state) {
        const activeConversationId = state.activeConversationId
        if (!state.conversationIsPreLoadingDown[activeConversationId]) {
            let internalId
            let limit
            if (state.activeConversation.messages[0].internal_id > 0) {
                if (
                    state.activeConversation.messages[
                        state.activeConversation.messages.length - 1
                    ].internal_id ===
                    state.activeConversation.messages.length - 1
                ) {
                    $state.complete()
                } else {
                    if (state.activeConversation.messages[0].internal_id < 20) {
                        limit =
                            state.activeConversation.messages[
                                state.activeConversation.messages.length - 1
                            ].internal_id
                    } else {
                        limit = 20
                    }
                    internalId =
                        state.activeConversation.messages[
                            state.activeConversation.messages.length - 1
                        ].internal_id
                    commit('SET_PRELOADING_DOWN_CONVERSATION_STATE', {
                        id: activeConversationId,
                        loadingState: true,
                    })
                    ChatService.searchMessagesDownByID(
                        activeConversationId,
                        internalId,
                        limit
                    )
                        .then(response => {
                            let messages = response.data ? response.data : []
                            if (messages.length >= 1) {
                                let counter = 0
                                const filesArray = []

                                state.conversations.forEach(conversation => {
                                    if (
                                        conversation.id === activeConversationId
                                    ) {
                                        let arrLength = messages.length
                                        messages[0].showDateBlock = true
                                        messages.forEach((mess, index) => {
                                            mess.files = mess.files
                                                ? mess.files
                                                : []

                                            let date = mess
                                            let day = Vue.options.filters.monthDay(
                                                date.timestamp * 1000
                                            )

                                            let new_key = +index + 1
                                            if (arrLength > new_key) {
                                                let new_date = messages[new_key]
                                                let new_day = Vue.options.filters.monthDay(
                                                    new_date.timestamp * 1000
                                                )
                                                new_date.showDateBlock =
                                                    day !== new_day
                                            } else if (
                                                arrLength ===
                                                index + 1
                                            ) {
                                                let lastCurrentMessage =
                                                    conversation.messages[0]
                                                let last_day = Vue.options.filters.monthDay(
                                                    lastCurrentMessage.timestamp *
                                                        1000
                                                )
                                                if (day === last_day) {
                                                    commit(
                                                        'HIDE_LAST_DATE_FROM_HISTORY'
                                                    )
                                                } else {
                                                    commit(
                                                        'SHOW_LAST_DATE_FROM_HISTORY'
                                                    )
                                                }
                                            }
                                            if (
                                                determineDate(
                                                    'isToday',
                                                    date.timestamp * 1000
                                                )
                                            ) {
                                                day = translate('w_today')
                                            } else if (
                                                determineDate(
                                                    'isYesterday',
                                                    date.timestamp * 1000
                                                )
                                            ) {
                                                day = translate('w_yesterday')
                                            } else {
                                                day = Vue.options.filters.monthDay(
                                                    date.timestamp * 1000
                                                )
                                            }
                                            mess.messagesDate = day
                                            if (mess.files) {
                                                mess.files.sort((a, b) =>
                                                    a.type > b.type ? 1 : -1
                                                )
                                                // .reverse()
                                            }
                                            mess.files.forEach(file => {
                                                counter++
                                                if (file.type !== 'file') {
                                                    getFiles(
                                                        file.url,
                                                        true
                                                    ).then(resp => {
                                                        let payload = {
                                                            conversation,
                                                            message: mess,
                                                            file,
                                                            blob: resp.url,
                                                            size: resp.size,
                                                        }
                                                        filesArray.push(payload)
                                                        if (--counter === 0) {
                                                            commit(
                                                                'CONCAT_MESSAGES_DOWN',
                                                                {
                                                                    messages,
                                                                    activeConversationId,
                                                                }
                                                            )
                                                            // dispatch(
                                                            //     'readUnreadMessages'
                                                            // )
                                                            $state.loaded()
                                                            filesArray.forEach(
                                                                payload => {
                                                                    commit(
                                                                        'ADD_BLOB',
                                                                        payload
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    })
                                                }
                                            })
                                        })
                                    }
                                })
                                if (!counter) {
                                    commit('CONCAT_MESSAGES_DOWN', {
                                        messages,
                                        activeConversationId,
                                    })
                                    $state.loaded()
                                }
                                if (limit < 20) {
                                    $state.complete()
                                }
                                // else {
                                //     const hasNewMessages = messages.some(
                                //         message => message.isNewMessage
                                //     )
                                //     if (hasNewMessages) {
                                //         $state.loaded()
                                //     } else {
                                //         $state.complete()
                                //     }
                                // }
                            } else {
                                $state.complete()
                            }
                            commit('SET_PRELOADING_DOWN_CONVERSATION_STATE', {
                                id: activeConversationId,
                                loadingState: false,
                            })
                        })
                        .catch(() => {
                            $state.complete()
                            commit('SET_PRELOADING_DOWN_CONVERSATION_STATE', {
                                id: activeConversationId,
                                loadingState: false,
                            })
                        })
                }
            }
        }
    },
    preloadNewMessages({ commit, state, dispatch }, $state) {
        const activeConversationId = state.activeConversationId
        if (!state.conversationIsPreLoading[activeConversationId]) {
            let internalId
            let limit
            if (state.activeConversation.messages[0].internal_id > 0) {
                if (state.activeConversation.messages[0].internal_id === 1) {
                    $state.complete()
                } else {
                    if (state.activeConversation.messages[0].internal_id < 20) {
                        limit = state.activeConversation.messages[0].internal_id
                    } else {
                        limit = 20
                    }
                    internalId =
                        state.activeConversation.messages[0].internal_id - 1
                    commit('SET_PRELOADING_CONVERSATION_STATE', {
                        id: activeConversationId,
                        loadingState: true,
                    })
                    ChatService.searchMessagesByID(
                        activeConversationId,
                        internalId,
                        limit
                    )
                        .then(response => {
                            let messages = response.data ? response.data : []
                            messages.reverse()
                            if (messages.length >= 1) {
                                let counter = 0
                                const filesArray = []

                                state.conversations.forEach(conversation => {
                                    if (
                                        conversation.id === activeConversationId
                                    ) {
                                        let arrLength = messages.length
                                        messages[0].showDateBlock = true
                                        messages.forEach((mess, index) => {
                                            mess.files = mess.files
                                                ? mess.files
                                                : []

                                            let date = mess
                                            let day = Vue.options.filters.monthDay(
                                                date.timestamp * 1000
                                            )

                                            let new_key = +index + 1
                                            if (arrLength > new_key) {
                                                let new_date = messages[new_key]
                                                let new_day = Vue.options.filters.monthDay(
                                                    new_date.timestamp * 1000
                                                )
                                                new_date.showDateBlock =
                                                    day !== new_day
                                            } else if (
                                                arrLength ===
                                                index + 1
                                            ) {
                                                let lastCurrentMessage =
                                                    conversation.messages[0]
                                                let last_day = Vue.options.filters.monthDay(
                                                    lastCurrentMessage.timestamp *
                                                        1000
                                                )
                                                if (day === last_day) {
                                                    commit(
                                                        'HIDE_LAST_DATE_FROM_HISTORY'
                                                    )
                                                } else {
                                                    commit(
                                                        'SHOW_LAST_DATE_FROM_HISTORY'
                                                    )
                                                }
                                            }
                                            if (
                                                determineDate(
                                                    'isToday',
                                                    date.timestamp * 1000
                                                )
                                            ) {
                                                day = translate('w_today')
                                            } else if (
                                                determineDate(
                                                    'isYesterday',
                                                    date.timestamp * 1000
                                                )
                                            ) {
                                                day = translate('w_yesterday')
                                            } else {
                                                day = Vue.options.filters.monthDay(
                                                    date.timestamp * 1000
                                                )
                                            }
                                            mess.messagesDate = day
                                            if (mess.files) {
                                                mess.files
                                                    .sort((a, b) =>
                                                        a.type > b.type ? 1 : -1
                                                    )
                                                    .reverse()
                                            }
                                            mess.files.forEach(file => {
                                                if (file.type !== 'file') {
                                                    counter++
                                                    getFiles(
                                                        file.url,
                                                        true
                                                    ).then(resp => {
                                                        let payload = {
                                                            conversation,
                                                            message: mess,
                                                            file,
                                                            blob: resp.url,
                                                            size: resp.size,
                                                        }
                                                        filesArray.push(payload)
                                                        if (--counter === 0) {
                                                            commit(
                                                                'CONCAT_MESSAGES',
                                                                {
                                                                    messages,
                                                                    activeConversationId,
                                                                }
                                                            )
                                                            dispatch(
                                                                'readUnreadMessages'
                                                            )
                                                            $state.loaded()
                                                            filesArray.forEach(
                                                                payload => {
                                                                    commit(
                                                                        'ADD_BLOB',
                                                                        payload
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    })
                                                }
                                            })
                                        })
                                    }
                                })
                                if (!counter) {
                                    commit('CONCAT_MESSAGES', {
                                        messages,
                                        activeConversationId,
                                    })
                                    $state.loaded()
                                }
                                if (limit < 20) {
                                    $state.complete()
                                }
                            } else {
                                $state.complete()
                            }
                            commit('SET_PRELOADING_CONVERSATION_STATE', {
                                id: activeConversationId,
                                loadingState: false,
                            })
                            dispatch('readUnreadMessages')
                        })
                        .catch(() => {
                            $state.complete()
                            commit('SET_PRELOADING_CONVERSATION_STATE', {
                                id: activeConversationId,
                                loadingState: false,
                            })
                        })
                }
            } else {
                $state.complete()
            }
        }
    },
    setNewMessageData({ commit, state, rootState }, data) {
        commit('SET_NEW_MESSAGE_DATA', data)
        ChatService.searchMessagesByID(data.conversation_id, data.internal_id)
            .then(response => {
                let messages = response.data ? response.data : []
                state.conversations.forEach(conversation => {
                    if (
                        conversation.id ===
                        state.incomingMessage.conversation_id
                    ) {
                        let counter = 0
                        let arrLength = messages.length

                        messages.forEach((mess, index) => {
                            if (mess.files) {
                                mess.files.forEach(file => {
                                    counter++
                                    if (file.type !== 'file') {
                                        getFiles(file.url)
                                            .then(resp => {
                                                file.blob = resp

                                                if (--counter === 0) {
                                                    commit(
                                                        'ADD_NEW_MESSAGE_TO_CONVERSATION',
                                                        {
                                                            conversationId:
                                                                conversation.id,
                                                            messages:
                                                                conversation.messages,
                                                            mess,
                                                            userId:
                                                                rootState.common
                                                                    .userId,
                                                        }
                                                    )
                                                }
                                            })
                                            .then(() => {
                                                let objDiv = document.querySelector(
                                                    '.chat-history-main'
                                                )
                                                if (objDiv) {
                                                    objDiv.scrollTop =
                                                        objDiv.scrollHeight
                                                }
                                            })
                                    } else {
                                        if (--counter === 0) {
                                            commit(
                                                'ADD_NEW_MESSAGE_TO_CONVERSATION',
                                                {
                                                    conversationId:
                                                        conversation.id,
                                                    messages:
                                                        conversation.messages,
                                                    mess,
                                                    userId:
                                                        rootState.common.userId,
                                                }
                                            )
                                        }
                                    }
                                })
                            } else {
                                commit('ADD_NEW_MESSAGE_TO_CONVERSATION', {
                                    conversationId: conversation.id,
                                    messages: conversation.messages,
                                    mess,
                                    userId: rootState.common.userId,
                                })
                            }

                            let date = mess
                            let day = Vue.options.filters.monthDay(
                                date.timestamp * 1000
                            )

                            let new_key = +index + 1
                            if (arrLength > new_key) {
                                let new_date = messages[new_key]
                                let new_day = Vue.options.filters.monthDay(
                                    new_date.timestamp * 1000
                                )
                                new_date.showDateBlock = day !== new_day
                            } else if (arrLength === index + 1) {
                                if (conversation.messages.length === 1) {
                                    commit(
                                        'SHOW_LAST_DATE_FROM_HISTORY_LAST_MESSAGE'
                                    )
                                } else {
                                    let lastCurrentMessage =
                                        conversation.messages[
                                            conversation.messages.length - 2
                                        ]
                                    let last_day = Vue.options.filters.monthDay(
                                        lastCurrentMessage.timestamp * 1000
                                    )

                                    if (day === last_day) {
                                        commit(
                                            'HIDE_LAST_DATE_FROM_HISTORY_LAST_MESSAGE'
                                        )
                                    } else {
                                        commit(
                                            'SHOW_LAST_DATE_FROM_HISTORY_LAST_MESSAGE'
                                        )
                                    }
                                }
                            }

                            mess.messagesDate = translate('w_today')
                        })

                        let active_dialog = state.conversations.filter(
                            conversation => {
                                return (
                                    conversation.id ===
                                    state.activeConversationId
                                )
                            }
                        )

                        if (active_dialog[0]) {
                            if (
                                active_dialog[0].id === conversation.id &&
                                router.currentRoute.path === '/chat'
                            ) {
                                let messages = conversation.messages

                                if (messages.length) {
                                    commit('UNIQUE_MESSAGE')
                                }

                                if (messages.length) {
                                    for (
                                        let i = messages.length - 1;
                                        i > 0;
                                        i--
                                    ) {
                                        if (
                                            messages[i].author_id !==
                                            rootState.common.userId
                                        ) {
                                            ChatService.readMessages(
                                                messages[i].id
                                            ).catch(error => {
                                                console.log(error)
                                            })
                                            break
                                        }
                                    }
                                }

                                if (conversation.unread_messages) {
                                    commit('SET_CONVERSATION_UNREAD_MESSAGES', {
                                        conversation,
                                        unreadMessages: 0,
                                    })
                                }
                            } else {
                                commit('SET_CONVERSATION_UNREAD_MESSAGES', {
                                    conversation,
                                    unreadMessages:
                                        state.incomingMessage.unread_messages,
                                })
                            }
                        }
                    }
                })
            })
            .then(() => {
                //Scroll
                let objDiv = document.querySelector('.chat-history-main')
                if (objDiv) {
                    objDiv.scrollTop = objDiv.scrollHeight
                }

                //Dialog set first
                let ind = 0
                let lastMessageTime = 0
                state.conversations.forEach((item, index) => {
                    if (item.last_message_time) {
                        if (lastMessageTime < item.last_message_time) {
                            ind = index
                            lastMessageTime = item.last_message_time
                        }
                    }
                })
                commit('SET_FIRST_CONVERSATIONS', ind)
            })
            .catch(error => {
                console.log(error)
            })
    },
    setReadMessageData({ commit }, data) {
        commit('SET_READ_MESSAGE_DATA', data)
        commit('SET_READ_STATUS', data)
    },
    addNeedToSendMessage({ commit }, payload) {
        commit('ADD_NEED_TO_SEND_MESSAGE', payload)
    },
    deleteNeedToSendMessage({ commit }) {
        commit('DELETE_NEED_TO_SEND_MESSAGE')
    },
    uniqueMessage({ commit }) {
        commit('UNIQUE_MESSAGE')
    },
}
