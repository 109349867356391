import { getSpecialtiesList, getCurrencyList } from '@services/base-service'
import { i18n } from '@/i18n'

const translate = i18n.t.bind(i18n)

export const state = {
    specialtiesList: [],
    currencies: [
        {
            active: true,
            code: 'PLN',
            id: 1,
            name: 'Zloty',
            number: '985',
            removed: false,
        },
    ],
}

export const mutations = {
    SET_SPECIALTIES_LIST(state, payload) {
        state.specialtiesList = payload
    },
    SET_CURRENCIES(state, payload) {
        state.currencies = payload
    },
}

export const getters = {
    getSpecializationById: state => id => {
        let specialization = ''
        state.specialtiesList.forEach(spec => {
            if (spec.id === id) {
                specialization = spec
            }
        })
        return specialization
    },
    getSpecializationByCode: state => code => {
        let specialization = {}
        state.specialtiesList.forEach(spec => {
            if (spec.value === code) {
                specialization = spec
            }
        })
        return specialization
    },
    getCurrencyById: state => currencyId => {
        let currencyCurrency = {}
        state.currencies.map(item => {
            if (item.id === currencyId) {
                currencyCurrency = item
            }
        })
        return currencyCurrency
    },
}

export const actions = {
    getSpecialties({ commit }) {
        getSpecialtiesList()
            .then(response => {
                const draftSpecialtiesList = response.data.specializations
                let specialityList = []

                draftSpecialtiesList.forEach(spec => {
                    specialityList.push({
                        label: translate(`${spec.code}`),
                        value: spec.code,
                        id: spec.id,
                    })
                })

                let sortFunction = (a, b) => {
                    return a.label.localeCompare(b.label)
                }
                commit(
                    'SET_SPECIALTIES_LIST',
                    specialityList.sort(sortFunction)
                )
            })
            .catch(error => {
                console.error(error)
            })
    },
    async setCurrency({ commit }) {
        const currencies = (await getCurrencyList()).data.currencies
        commit('SET_CURRENCIES', currencies)
    },
}
