import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta' // https://github.com/declandewet/vue-meta
import NProgress from 'nprogress'
import { Role } from '@constants/role.js'
import store from '@state/store'

import routes from './routes'

Vue.use(VueRouter)
Vue.use(VueMeta, {
    // The component option name that vue-meta looks for meta info on.
    keyName: 'page',
})

const router = new VueRouter({
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: 'history',

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }

        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    NProgress.start()

    const { authorize } = to.meta
    const authRequired = authorize && authorize.length > 0
    const isTokenExists = !!store.state.auth.access_token
    const role = store.state.auth.role

    if (authRequired) {
        if (!isTokenExists || !role || !authorize.includes(role)) {
            // not logged in so redirect to login page with the return url
            return next({ path: '/login', query: { returnUrl: to.path } })
        }

        // check if route is restricted by role
        if (authorize.length && !authorize.includes(role)) {
            // role not authorised so redirect to home page
            if (role === Role.doctor) {
                next({ name: 'user-main' })
            } else if (role === Role.admin) {
                next({ name: 'admin-main' })
            } else if (role === Role.distributor) {
                next({ name: 'distributor-main' })
            } else if (role === Role.manager) {
                next({ name: 'manager-main' })
            } else if (role === Role.owner) {
                next({ name: 'owner-main' })
            }

            return next()
        }
    }
    next()
})
router.afterEach(() => {
    NProgress.done()
})

export default router
