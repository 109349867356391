import Vue from 'vue'
import Vuex from 'vuex'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'
import createPersistedState from 'vuex-persistedstate'

import modules from './modules'

let initialState = {}

Object.keys(modules).forEach(key => {
    initialState[key] = JSON.parse(JSON.stringify(modules[key].state))
})

Vue.use(Vuex)

const storageStage = createPersistedState({
    paths: ['auth'],
})

const store = new Vuex.Store({
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    mutations: {
        RESET_STORE(state) {
            const enlargedMode = state.common.enlargedMode
            Object.keys(state).forEach(key => {
                state[key] = JSON.parse(JSON.stringify(initialState[key]))
            })
            state.common.enlargedMode = enlargedMode
        },
    },
    plugins: [storageStage],
    strict: process.env.NODE_ENV !== 'production',
})

export default store

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
