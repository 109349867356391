import {
    getContacts,
    getLanguagesList,
    getFiles,
    editUserProfile,
    putEmail,
    getAllPatients,
    postAvatars,
    deletetAvatars,
    getOwnerId,
} from '@services/base-service'
import Vue from 'vue'
import store from '@state/store'
import { getLocale, getSavedState } from '@utils/user-info.js'
import { loadLanguageAsync, i18n } from '@/i18n'

const translate = i18n.t.bind(i18n)

export const state = {
    userId: '',
    allPatients: [],
    demo: {},
    has_demo: '',
    demo_period: '',
    userProfile: {
        activated_at: '',
        doctor_data: '',
        email: '',
        id: '',
        is_active: '',
        language: getLocale() ? getLocale() : 'eng',
        magnifier: getSavedState('enlargedMode'),
        name: '',
        role: '',
        surname: '',
    },
    languagesList: [],
}

export const mutations = {
    SET_USER_DATA(state, payload) {
        Vue.set(state, 'userProfile', payload.profile)
        Vue.set(state, 'userId', payload.profile.id)
    },
    SET_OWNER_DATA(state, payload) {
        Vue.set(state, 'userProfile', payload)
        Vue.set(state, 'userId', payload.id)
    },

    SET_DEMO_DATA(state, payload) {
        Vue.set(state, 'has_demo', payload.has_demo)
        Vue.set(state, 'demo_period', payload.demo_period)
        Vue.set(state, 'demo', payload.demo)
    },

    SET_FULL_NAME(state) {
        state.allPatients.forEach(item => {
            item.full_name = item.name + ' ' + item.surname
        })
    },

    GET_ALL_CONVERSATION(state, payload) {
        state.allPatients = payload
        state.allPatients.map(item => {
            Vue.set(
                state.allPatients,
                'full_name',
                `${item.name} ${item.surname}`
            )
        })
    },

    SAVE_LANG_TO_PROFILE(state, lang) {
        if (state.userProfile.language !== lang) {
            Vue.set(state.userProfile, 'language', lang)
        }
    },

    SET_USER_AVATAR(state, payload) {
        Vue.set(state.userProfile, 'avatar', payload)
    },

    SET_USER_PROFILE(state, payload) {
        let key = payload.key
        let subkey = payload.subkey
        let emailAwait = payload.oldEmailValue
        let value = payload.value

        if (subkey) {
            Vue.set(state.userProfile[key], subkey, value)
        } else if (emailAwait) {
            Vue.set(state.userProfile, 'new_email', emailAwait)
        } else {
            if (
                key === 'education' ||
                key === 'specialization_id' ||
                key === 'experience' ||
                key === 'birthday' ||
                key === 'home_page'
            ) {
                Vue.set(state.userProfile.doctor_data, key, value)
            } else {
                Vue.set(state.userProfile, key, value)
            }
        }
        // localStorage.setItem(
        //     'facilityId',
        //     state.userProfile.doctor_data.medical_facility_id
        // )
    },

    SET_LANGUAGES_LIST(state, payload) {
        state.languagesList = payload
    },
}

export const actions = {
    setOwnerId({ commit }) {
        return getOwnerId().then(response => {
            commit('SET_OWNER_DATA', response.data)
        })
    },
    getAllUsers({ commit }) {
        getAllPatients().then(response => {
            // console.log(response, 'response ')
            if (Array.isArray(response.data) || response.data.length) {
                let patients = response.data
                commit('GET_ALL_CONVERSATION', patients)
                commit('SET_FULL_NAME', patients)
            } else {
                console.error('Received data is not an array')
            }
        })
    },
    getUserContacts({ commit, state }, is_patient_block) {
        return getContacts()
            .then(async response => {
                async function getAvatars() {
                    for (let prop in response.data.profile.avatar) {
                        await getFiles(response.data.profile.avatar[prop]).then(
                            resp => {
                                response.data.profile.avatar[prop] = resp
                            }
                        )
                    }
                    commit('SET_USER_DATA', response.data)
                    if (response.data.profile.role === 'distributor') {
                        commit('SET_DEMO_DATA', response.data)
                    }
                    if (
                        response.data.profile.role ===
                        'medical-facility-manager'
                    ) {
                        await commit(
                            'manager/SET_CONTACTS_DATA',
                            response.data.contacts,
                            { root: true }
                        )
                    }
                    if (response.data.profile.role === 'doctor') {
                        localStorage.setItem(
                            'task_administrator',
                            response.data.profile.doctor_data.task_administrator
                        )
                        // let RESP = response.data.contacts
                        // let data = JSON.parse(JSON.stringify(RESP))
                        // if (
                        //     state.userProfile.doctor_data
                        //         .subscription_administrator
                        // ) {
                        //     await data.forEach(item => {
                        //         if (!item.participants) {
                        //             item.participants = []
                        //         }

                        //         item.participants.push(
                        //             state.userProfile.id,
                        //             item.id
                        //         )

                        //         item.avatar = item.avatar?.min
                        //         item.patient_id = item.id
                        //         item.full_name = item.name + ' ' + item.surname
                        //     })
                        // }
                        if (is_patient_block) {
                            await commit(
                                'doctor/SET_CONTACTS_DATA',
                                response.data.contacts,
                                {
                                    root: true,
                                }
                            )
                            store.dispatch('doctor/getContactsAvatars')
                            store.dispatch('chat/getDoctorConversations', 1)
                        } else {
                            await commit(
                                'doctor/SET_CONTACTS_DATA',
                                response.data.contacts,
                                {
                                    root: true,
                                }
                            )
                            store.dispatch('doctor/getContactsAvatars')
                            store.dispatch('chat/getDoctorConversations', 1)
                            if (!response.data.contacts.length) {
                                commit('chat/SET_CONVERSATION_RESPONSE', 0, {
                                    root: true,
                                })
                            }
                        }
                    }
                    return response
                }

                if (response.data) {
                    commit(
                        'doctor/CHANGE_ENLARGED_MODE_STATE',
                        response.data.profile.magnifier,
                        { root: true }
                    )
                    if (
                        state.userProfile.language !==
                        response.data.profile.language
                    ) {
                        await loadLanguageAsync(response.data.profile.language)
                    }

                    getAvatars()
                }
            })
            .catch(error => {
                console.log(error)
            })
    },

    async setUserProfile({ state, commit, rootState }, payload) {
        const setError = status => {
            if (status === 404) {
                store.dispatch('notifications/setError', {
                    error: {
                        data: {
                            text: translate('w_langNotFound'),
                        },
                    },
                })
            }
        }
        let role = rootState.auth.role
        if (role === 'medical-facility-manager') {
            role = 'facility-manager'
        }
        if (payload.key === 'doctor_data') {
            await editUserProfile(role, {
                [payload.subkey]: payload.value,
            }).catch(error => {
                setError(error.response.status)
            })
        } else {
            await editUserProfile(role, { [payload.key]: payload.value }).catch(
                error => {
                    setError(error.response.status)
                }
            )
        }
        if (
            payload.key === 'language' &&
            state.userProfile.language !== payload.value
        ) {
            await loadLanguageAsync(payload.value)
        }
        commit('SET_USER_PROFILE', payload)
    },

    async setUserProfileEmail({ commit }, payload) {
        return await putEmail(payload.token, {
            email: payload.value,
        })
            .then(response => {
                commit('SET_USER_PROFILE', payload)
                return response
            })
            .catch(error => {
                return error.response
            })
    },

    setUserAvatar({ commit }, formData) {
        postAvatars(formData).then(() => {
            return getContacts().then(response => {
                async function getAvatars() {
                    for (let prop in response.data.profile.avatar) {
                        await getFiles(response.data.profile.avatar[prop]).then(
                            resp => {
                                response.data.profile.avatar[prop] = resp
                            }
                        )
                    }
                    commit('SET_USER_AVATAR', response.data.profile.avatar)
                }
                getAvatars()
            })
        })
    },

    deleteUserAvatar({ commit }) {
        deletetAvatars()
        commit('SET_USER_AVATAR', '')
    },

    async getLanguages({ commit }) {
        try {
            let langsList = []
            const draftlanguages = (await getLanguagesList()).data.languages
            draftlanguages.forEach(lang => {
                langsList.push({
                    label: translate(`w_${lang.code}`),
                    value: lang.code,
                    ...lang,
                })
            })
            let sortFunction = (a, b) => {
                return a.code.localeCompare(b.code)
            }
            commit('SET_LANGUAGES_LIST', langsList.sort(sortFunction))
        } catch (e) {
            console.error(e)
        }
    },
}

export const getters = {
    getUserData: state => state.userProfile,
}
