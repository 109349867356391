import { Role } from '@constants/role.js'
const meta = { authorize: [Role.manager] }

export default [
    {
        path: '/',
        meta,
        component: () => import('@pages/p-manager-base'),
        children: [
            {
                path: '/manager-main',
                name: 'manager-main',
                meta,
                component: () => import('@templates/t-manager-main'),
            },
            {
                path: '/manager-statistic',
                name: 'manager-statistic',
                meta,
                component: () => import('@templates/t-manager-statistic'),
            },
            {
                path: '/manager-patients',
                name: 'manager-patients',
                meta,
                component: () => import('@templates/t-manager-patients'),
            },
            {
                path: '/manager-patients/create-patient',
                name: 'patient-create',
                meta,
                component: () => import('@organisms/o-manager-create-patient'),
            },
            {
                path: '/manager-patients/edit/:id',
                name: 'patient-edit',
                meta,
                component: () => import('@organisms/o-manager-create-patient'),
            },
            {
                path: '/doctors-profiles',
                name: 'doctors-profiles',
                meta,
                component: () =>
                    import('@templates/t-manager-doctors-profiles'),
            },
            {
                path: '/manager-kits',
                name: 'manager-kits',
                meta,
                component: () => import('@templates/t-manager-kits'),
                redirect: {
                    name: 'kits-list',
                },
                children: [
                    {
                        path: '/manager-kits/all',
                        name: 'kits-list',
                        meta,
                        component: () => import('@organisms/o-manager-kits'),
                    },
                    {
                        path: '/manager-kits/:id',
                        name: 'kit-info',
                        meta,
                        component: () =>
                            import('@organisms/o-manager-kit-view'),
                    },
                    {
                        path: '/manager-kits/:id/:path:target',
                        name: 'kit-transfer',
                        meta,
                        component: () => import('@organisms/o-kit-transfer'),
                    },
                ],
            },
            {
                path: '/manager-settings',
                name: 'manager-settings',
                meta,
                component: () => import('@templates/t-manager-settings'),
            },
        ],
    },
]
