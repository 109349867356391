import { determineDate } from '@utils/timeRefactor'
import Vue from 'vue'
import { i18n } from '@/i18n'

const translate = i18n.t.bind(i18n)

export function prepareProfiles(conversations, contactsProfiles, state) {
    let updatedConversations = []
    conversations.forEach(conversation => {
        contactsProfiles.forEach(profile => {
            if (conversation.participants.includes(profile.id)) {
                conversation.name = profile.name
                conversation.surname = profile.surname
                conversation.patient_id = profile.id
                conversation.phone = profile.patient_data.phone
                conversation.gender = profile.patient_data.gender
                conversation.email = profile.email
                conversation.activated_at = profile.activated_at
                let avatar = ''
                if (
                    profile.avatar &&
                    profile.avatar.min &&
                    profile.avatar.min.includes('blob:')
                ) {
                    avatar = profile.avatar.min
                }
                conversation.avatar = avatar
                conversation.birthday = profile.patient_data.birthday
                    ? profile.patient_data.birthday
                    : ''

                let arrLength = conversation.messages
                    ? conversation.messages.length
                    : 0

                for (let key in conversation.messages) {
                    let date = conversation.messages[key]
                    let day = Vue.options.filters.monthDay(
                        date.timestamp * 1000
                    )

                    let new_key = +key + 1
                    if (arrLength > new_key) {
                        let new_date = conversation.messages[new_key]
                        let new_day = Vue.options.filters.monthDay(
                            new_date.timestamp * 1000
                        )
                        new_date.showDateBlock = day !== new_day
                    }
                    if (determineDate('isToday', date.timestamp * 1000)) {
                        day = translate('w_today')
                    } else if (
                        determineDate('isYesterday', date.timestamp * 1000)
                    ) {
                        day = translate('w_yesterday')
                    } else {
                        day = Vue.options.filters.monthDay(
                            date.timestamp * 1000
                        )
                    }
                    conversation.messages[key].messagesDate = day
                    if (conversation.messages[key].files) {
                        conversation.messages[key].files
                            .sort((a, b) => (a.type > b.type ? 1 : -1))
                            .reverse()
                    }
                }
                if (conversation.messages[0]) {
                    conversation.messages[0].showDateBlock = true
                }

                conversation.messages = conversation.messages
                    ? conversation.messages
                    : []
                let lastMessage = conversation.messages
                    ? conversation.messages[conversation.messages.length - 1]
                    : undefined

                if (lastMessage) {
                    conversation.last_message_status =
                        state.userId === lastMessage.author_id
                            ? lastMessage.state
                            : 0
                    conversation.last_message_time = lastMessage.timestamp
                    conversation.last_message_text = lastMessage.text
                    if (lastMessage.files) {
                        if (lastMessage.files.length) {
                            let lastKey = +lastMessage.files.length - 1
                            conversation.last_message_files =
                                lastMessage.files[lastKey].type
                        } else {
                            conversation.last_message_files = lastMessage.files
                        }
                    }
                }

                conversation.full_name = `${conversation.name} ${conversation.surname}`
                updatedConversations.push(conversation)
            }
        })
    })
    return { updatedConversations }
}
