import Vue from 'vue'
import moment from 'moment'

// define global filters

// date filters

// example output: moment(new Date()).format('HH:mm') - "16:33"
Vue.filter('time', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('HH:mm')
})

Vue.filter('shortDay', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('ddd')
})

Vue.filter('shortDayMonth', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('MMM D')
})

// example output: moment(new Date()).format('MMMM D') - "October 28"
Vue.filter('monthDay', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('MMMM D')
})

// example output: moment(new Date()).format('D MMMM') - "28 October"
Vue.filter('dateMonth', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('D MMMM')
})

// example output: moment(new Date()).format('D MMMM YYYY') - "28 October 2019"
Vue.filter('dayFull', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('D MMMM YYYY')
})

Vue.filter('universal', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('YYYY-MM-DD')
})

// example output: moment(new Date()).format('D MMMM YYYY') - "28.10.2019"
Vue.filter('dayFullNumbers', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('DD.MM.YYYY')
})

// example output: moment(new Date()).format("DD.MM.YYYY, HH:mm") - "28.10.2019, 16:34"
Vue.filter('timeFull', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('DD.MM.YYYY, HH:mm')
})

// example output: moment(new Date()).format("DD.MM") - "28.10"
Vue.filter('day', function(date) {
    const md = moment(date)
    if (!date || !md) return ''

    return md.format('DD.MM')
})
