import { Role } from '@constants/role.js'
const meta = { authorize: [Role.doctor] }

export default {
    path: '/general',
    name: 'general',
    meta,
    component: () => import('@templates/t-general'),
    redirect: {
        name: 'general-list',
    },
    children: [
        {
            path: '/general/all',
            name: 'general-list',
            meta,
            component: () => import('@organisms/o-general'),
        },
    ],
}
