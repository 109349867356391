import ownerService from '@services/owner-service'
import notificationService from '@services/notification-service'
import Vue from 'vue'
import { getFiles } from '@services/base-service'

export const state = {
    distributors: [],
    activeFacilityStat: {},
    activeDistributor: {},
    activeDistributorId: '',
    activeFacilityId: '',
    activeFacility: {},
    activeMedicalFacility: {},
    activeFacilityList: [],
    noFacility: false,
    medicalFacilityList: [],
    noStatisticDistributor: false,
    statisticOwnerLoading: true,
    currentDistributorTrialMode: false,
    notificationList: [],
    newNotify: false,
}

export const mutations = {
    SET_APPROVED(state, data) {
        state.notificationList.find(
            i => i.id === data.id
        ).data.OwnerNotificationData.status = 'approved'
    },
    SET_APPROVED_FROM_FACILITY(state, data) {
        state.notificationList.find(
            i => i.data.OwnerNotificationData.facility_id === data
        ).data.OwnerNotificationData.status = 'approved'
    },
    SET_REJECTED_FROM_FACILITY(state, data) {
        state.notificationList.find(
            i => i.data.OwnerNotificationData.facility_id === data
        ).data.OwnerNotificationData.status = 'rejected'
    },
    SET_EXTENSION(state, data) {
        state.activeMedicalFacility.trial.trial_extension = data
    },
    CREATE_DISTRIBUTOR(state, data) {
        state.distributors.push(data)
    },
    SET_DISTRIBUTORS(state, data) {
        let distributorsProfilesList = data
        distributorsProfilesList.forEach(item => {
            Vue.set(item, 'isOpened', false)
        })
        state.distributors = distributorsProfilesList
    },
    SET_CONTACTS_AVATARS(state, { profile, avatar, type }) {
        if (type === 'min') {
            Vue.set(profile.avatar, 'min', avatar)
        } else if (type === 'medium') {
            Vue.set(profile.avatar, 'medium', avatar)
        } else {
            Vue.set(profile.avatar, 'max', avatar)
        }
    },
    SET_ACTIVE_DISTRIBUTOR(state, { distributorId, event }) {
        state.distributors.map(item => {
            if (item.id === distributorId) {
                Vue.set(item, 'isOpened', event.status)
            }
        })
    },

    SET_ACTIVE_DISTRIBUTOR_HOME(state, distributor) {
        Vue.set(state, 'activeDistributor', distributor)
    },

    SET_FACILITIES(state, data) {
        /*if (data.facilities) {
            data.facilities = data.facilities.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
        }*/
        state.distributors.map(item => {
            if (item.id === data.distributorId) {
                Vue.set(item, 'facilities', data.facilities)
            }
        })
    },
    SET_FACILITIES_STATISTIC(state, data) {
        state.distributors.map(item => {
            if (item.id === state.activeDistributorId) {
                item.facilities.forEach(facility => {
                    if (facility.id === data.facilityId) {
                        Vue.set(state, 'activeFacility', facility)
                    }
                })
            }
        })
        Vue.set(state, 'activeFacilityId', data.facilityId)
    },
    SET_ACTIVE_FACILITY(state, data) {
        Vue.set(state, 'activeFacility', data)
    },
    SET_ACTIVE_FACILITY_LIST(state, data) {
        Vue.set(state, 'activeFacilityList', data)
    },
    SET_ACTIVE_FACILITY_ID(state, id) {
        Vue.set(state, 'activeFacilityId', id)
    },
    SET_ACTIVE_DISTRIBUTOR_ID(state, id) {
        Vue.set(state, 'activeDistributorId', id)
    },
    SET_EMPTY_DISTRIBUTOR_FACILITY(state, data) {
        state.noFacility = data
    },
    SET_NO_STATISTIC_DISTRIBUTOR(state, data) {
        state.noStatisticDistributor = data
    },
    GET_MEDICAL_FACILITY_LIST(state, payload) {
        state.medicalFacilityList = []
        state.medicalFacilityList = payload
    },
    SET_ACTIVE_MEDICAL_FACILITY(state, payload) {
        state.activeMedicalFacility = {}
        state.activeMedicalFacility = payload
    },
    SET_ACTIVE_MEDICAL_FACILITY_KEY(state, data) {
        Vue.set(state.activeMedicalFacility, data.key, data.value)
    },
    SET_NOTIFICATION_READ_STATE(state, data) {
        state.notificationList = state.notificationList.map(item => {
            if (item.id === data.id) {
                item.read = !item.read
            }
            return item
        })
    },
    SET_ALL_NOTIFICATIONS_READ_STATE(state) {
        state.notificationList.forEach(item => {
            item.read = true
        })
    },
    SET_MEDICAL_FACILITY_CURRENT_STATE(state, payload) {
        state.activeMedicalFacility.is_active = payload
    },
    SET_STATISTIC_OWNER_LOADING(state, payload) {
        state.statisticOwnerLoading = payload
    },
    SET_DISTRIBUTOR_TRIAL_MODE(state, distributorId) {
        state.distributors.filter(item => {
            if (item.id === distributorId) {
                Vue.set(item.trial, 'is_active', false)
            }
        })
    },
    GET_NOTIFICATION_LIST(state, data) {
        state.notificationList = []
        state.notificationList = data
    },
    GET_NOTIFICATION(state, data) {
        state.notificationList.pop()
        state.notificationList.unshift(data)
    },
    SET_NEW_NOTIFY_STATUS(state, data) {
        state.newNotify = data
    },
}

export const getters = {
    getMedicalFacilityCurrentState(state) {
        return state.activeMedicalFacility.is_active
    },
    getDistributorTrialMode(state) {
        return state.currentDistributorTrialMode
    },
    getActiveDistributor(state) {
        return state.activeDistributorId
    },
}

export const actions = {
    setApprovedStatus({ commit }, data) {
        commit('SET_APPROVED', data)
    },
    setApprovedStatusFromFacility({ commit }, data) {
        commit('SET_APPROVED_FROM_FACILITY', data)
    },
    setRejectedStatusFromFacility({ commit }, data) {
        commit('SET_REJECTED_FROM_FACILITY', data)
    },
    setTrialExtension({ commit }, data) {
        commit('SET_EXTENSION', data)
    },
    setActiveFacility({ commit }, facility) {
        commit('SET_ACTIVE_MEDICAL_FACILITY', facility)
    },
    setNotificationReadState({ commit }, data) {
        commit('SET_NOTIFICATION_READ_STATE', data)
    },
    setAllNotificationsReadState({ commit }) {
        commit('SET_ALL_NOTIFICATIONS_READ_STATE')
    },
    setActiveFacilityKey({ commit }, data) {
        commit('SET_ACTIVE_MEDICAL_FACILITY_KEY', data)
    },
    setMedicalFacilityCurrentState({ commit }, data) {
        commit('SET_MEDICAL_FACILITY_CURRENT_STATE', data)
    },
    async addDistributor({ commit, dispatch }, data) {
        try {
            await ownerService.createDistributor(data)
            data.avatar = ''
            data.id = Date.now()
            commit('CREATE_DISTRIBUTOR', data)
            dispatch('getDistributor')
        } catch (error) {
            return error
        }
    },

    async getDistributor({ commit, dispatch }) {
        return await ownerService.getDistributor().then(async response => {
            await commit('SET_DISTRIBUTORS', response.data)
            dispatch('getContactsAvatars')
        })
    },

    getContactsAvatars({ commit, state }) {
        state.distributors.forEach(profile => {
            if (profile.avatar) {
                getFiles(profile.avatar.min).then(resp => {
                    commit('SET_CONTACTS_AVATARS', {
                        profile,
                        avatar: resp,
                        type: 'min',
                    })
                })
                getFiles(profile.avatar.medium).then(resp => {
                    commit('SET_CONTACTS_AVATARS', {
                        profile,
                        avatar: resp,
                        type: 'medium',
                    })
                })
                getFiles(profile.avatar.max).then(resp => {
                    commit('SET_CONTACTS_AVATARS', {
                        profile,
                        avatar: resp,
                        type: 'max',
                    })
                })
            }
        })
    },

    setActiveDistributor({ commit }, { distributorId, event }) {
        commit('SET_ACTIVE_DISTRIBUTOR', { distributorId, event })
    },

    setActiveDistributorHomePage({ commit }, distributor) {
        commit('SET_ACTIVE_DISTRIBUTOR_HOME', distributor)
    },

    //click on arrow
    async loadFacilities({ commit }, distributorId) {
        await ownerService.getFacilities(distributorId).then(response => {
            const facilities = response.data
            if (facilities) {
                const facilities_id = []
                facilities.forEach(facility => {
                    facilities_id.push(facility.id)
                })
                ownerService
                    .getCounters(facilities_id)
                    .then(response => {
                        response.data.facilities.forEach(
                            facilityWithCounters => {
                                facilities.forEach(facility => {
                                    if (
                                        facilityWithCounters.id === facility.id
                                    ) {
                                        facility.subscriptions_count =
                                            facilityWithCounters.subscriptions_count
                                        facility.all_time_subscriptions_count =
                                            facilityWithCounters.all_time_subscriptions_count
                                        facility.doctors_count =
                                            facilityWithCounters.doctors_count
                                        facility.list_specialization_fee =
                                            facilityWithCounters.list_specialization_fee
                                    }
                                })
                            }
                        )
                        commit('SET_FACILITIES', {
                            distributorId,
                            facilities,
                        })
                        commit('GET_MEDICAL_FACILITY_LIST', facilities)
                    })
                    .catch(() => {
                        commit('GET_MEDICAL_FACILITY_LIST', [])
                    })
            }
        })
    },

    //one facility
    async getFacilityStatistic({ commit }, data) {
        await commit('SET_STATISTIC_OWNER_LOADING', true)
        commit('SET_ACTIVE_DISTRIBUTOR_ID', data.distributorId)
        commit('SET_ACTIVE_FACILITY_LIST', null)
        commit('SET_FACILITIES_STATISTIC', { facilityId: data.facilityId })
        commit('SET_ACTIVE_FACILITY_ID', data.facilityId)
        commit('SET_EMPTY_DISTRIBUTOR_FACILITY', false)
        commit('SET_NO_STATISTIC_DISTRIBUTOR', false)
        commit('SET_STATISTIC_OWNER_LOADING', false)
    },

    //common click
    async getAllFacilityStatistic({ commit, dispatch }, data) {
        await commit('SET_STATISTIC_OWNER_LOADING', true)
        commit('SET_NO_STATISTIC_DISTRIBUTOR', true)
        commit('SET_EMPTY_DISTRIBUTOR_FACILITY', false)
        commit('SET_ACTIVE_FACILITY_ID', '')
        commit('SET_ACTIVE_FACILITY', {})
        await dispatch('loadFacilities', data.distributorId)
        ownerService
            .getAllFacilities(data.distributorId, data.from, data.to)
            .then(response => {
                commit('SET_ACTIVE_DISTRIBUTOR_ID', data.distributorId)
                commit('SET_ACTIVE_FACILITY_LIST', response.data.list)
                commit('SET_NO_STATISTIC_DISTRIBUTOR', false)
                setTimeout(() => {
                    commit('SET_STATISTIC_OWNER_LOADING', false)
                }, 500)
            })
            .catch(() => {
                commit('SET_ACTIVE_DISTRIBUTOR_ID', data.distributorId)
                commit('SET_NO_STATISTIC_DISTRIBUTOR', true)

                //To set another activeFacilityList and rerender component
                commit('SET_ACTIVE_FACILITY_LIST', [])
                setTimeout(() => {
                    commit('SET_ACTIVE_FACILITY_LIST', null)
                    commit('SET_STATISTIC_OWNER_LOADING', false)
                }, 100)
            })
    },
    async getPeriodStatistic({ commit }, data) {
        await ownerService
            .getAllFacilities(data.distributorId, data.from, data.to)
            .then(response => {
                commit('SET_ACTIVE_FACILITY_LIST', response.data.list)
                commit('SET_NO_STATISTIC_DISTRIBUTOR', false)
            })
            .catch(() => {
                commit('SET_ACTIVE_FACILITY_LIST', null)
                commit('SET_NO_STATISTIC_DISTRIBUTOR', true)
            })
    },
    setNoFacility({ commit }, id) {
        commit('SET_EMPTY_DISTRIBUTOR_FACILITY', true)
        commit('SET_ACTIVE_DISTRIBUTOR_ID', id)
        commit('SET_ACTIVE_FACILITY_ID', '')
        commit('SET_NO_STATISTIC_DISTRIBUTOR', false)
        commit('SET_ACTIVE_FACILITY_LIST', null)
    },
    setDistributorTrialMode({ commit }, data) {
        commit('SET_DISTRIBUTOR_TRIAL_MODE', data)
    },
    getNotificationList({ commit }) {
        notificationService.getAllNotifications().then(response => {
            let notificationList = response.data.ListNotifications
            let newNotifyStatus = response.data.IsNewNotify
            commit('GET_NOTIFICATION_LIST', notificationList)
            commit('SET_NEW_NOTIFY_STATUS', newNotifyStatus)
        })
    },
    getNotificationById({ commit }, id) {
        notificationService.getNotification(id).then(response => {
            commit('GET_NOTIFICATION', response.data.ListNotifications[0])
        })
    },
    setNewNotifyStatus({ commit }, status) {
        commit('SET_NEW_NOTIFY_STATUS', status)
    },
}
