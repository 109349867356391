import Vue from 'vue'

export const state = {
    graphMode: false,
    activeFilter: undefined,
    activeConversationReportId: undefined,
    activeReportItem: undefined,
}

export const mutations = {
    SET_GRAPH_MODE(state, payload) {
        state.graphMode = payload
    },
    SET_FILTER(state, title) {
        state.activeFilter = title
    },
    SET_ACTIVE_CONVERSATION_REPORT(state, id) {
        Vue.set(state, 'activeConversationReportId', id)
    },
    SET_ACTIVE_REPORT_ITEM(state, id) {
        state.activeReportItem = id
    }
}

export const actions = {
    setGraphMode({ commit }, payload) {
        commit('SET_GRAPH_MODE', payload)
    },
    setActiveFilter({ commit }, title) {
        commit('SET_FILTER', title)
    },
    setActiveConversationReport({ commit }, id) {
        commit('SET_ACTIVE_CONVERSATION_REPORT', id)
    },
    setActiveReportItem({ commit }, id) {
        commit('SET_ACTIVE_REPORT_ITEM', id)
    },
}
