import { $axios } from './base-service'

export default {
    getAllNotifications() {
        const resource = `users/notification`
        return $axios.get(`${resource}`)
    },
    getLimitNotifications(page) {
        const resource = `users/notification/?page=${page}&limit=10`
        return $axios.get(`${resource}`)
    },
    getNotification(id) {
        const resource = `users/notification/${id}`
        return $axios.get(`${resource}`)
    },
    approveExtensionRequest(id) {
        const resource = `users/owner/notify/request/approve/${id}`
        return $axios.post(`${resource}`)
    },
    rejectExtensionRequest(id) {
        const resource = `users/owner/notify/request/reject/${id}`
        return $axios.post(`${resource}`)
    },
    getNotificationRead(id) {
        const resource = `users/notification/edit/${id}`
        return $axios.put(resource)
    },
    getAllNotificationsRead() {
        const resource = `users/notification/edit`
        return $axios.put(resource)
    },
    changeNewNotifyStatus() {
        const resource = `users/notification/view`
        return $axios.put(resource)
    },
}
