import Vue from 'vue'
import VModal from 'vue-js-modal'
import Notifications from 'vue-notification'
import TextareaAutosize from 'vue-textarea-autosize'
import VTooltip from 'v-tooltip'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/title'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markArea'
import 'echarts/lib/component/markPoint'
import Clipboard from 'v-clipboard'
import VShowSlide from 'v-show-slide'
import VueCollapse from 'vue2-collapse'

Vue.component('v-chart', ECharts)
Vue.use(TextareaAutosize)
Vue.use(VModal)
Vue.use(Notifications)
Vue.use(VTooltip)
Vue.use(VShowSlide)
Vue.use(Clipboard)
Vue.use(VueCollapse)
