import kitService from '@/services/kit-service'
import { i18n } from '@/i18n'

const translate = i18n.t.bind(i18n)

export const state = {
    kits: [],
    kitsLoaded: false,
    kitsCurPage: 1,
    totalKits: 0,
    kitsLimit: 25,
    kitsSorting: {
        sort: 1,
        order_by: 'serial_number',
    },
    kitsFilter: {
        status: [],
        patient: '',
    },
    searchKitsBy: '',
    activeKit: {},
    columnsKits: ['serial_number', 'state', 'location', 'patient'],
    availableKits: [
        {
            key: 'serial_number',
            label: 'id_0056_serial_number',
            sortable: true,
            disabled: true,
            width: 110,
        },
        {
            key: 'id',
            label: 'id_0060_kit_id',
            sortable: false,
            width: 140,
        },
        {
            key: 'state',
            label: 'w_status',
            sortable: true,
            width: 110,
        },
        {
            key: 'mac_address',
            label: 'id_0057_mac_address',
            sortable: false,
            width: 110,
        },
        {
            key: 'location',
            label: 'id_0062_location',
            sortable: true,
            width: 110,
        },
        {
            key: 'patient',
            label: 'w_patient',
            sortable: false,
            width: 110,
        },
    ],
}

export const mutations = {
    SET_CURRENT_PAGE(state, page) {
        state.kitsCurPage = page
    },
    SET_TOTAL_KITS(state, value) {
        state.totalKits = value
    },
    SET_KITS_LOADED(state, value) {
        state.kitsLoaded = value
    },
    SET_SEARCH_QUERY(state, data) {
        state.searchKitsBy = data
    },
    GET_KITS_LIST(state, data) {
        state.kits = []
        state.kits = data
    },
    SET_KITS_SORTING(state, data) {
        state.kitsSorting.sort = data.value
        state.kitsSorting.order_by = data.key
    },
    SET_KITS_FILTER(state, data) {
        state.kitsFilter = {}
        state.kitsFilter.status = data.status
        state.kitsFilter.patient = data.patient
    },
    SET_ACTIVE_KIT(state, data) {
        state.activeKit = data
    },
    UPDATE_KITS_COLUMNS(state, columns) {
        state.columnsKits = columns
    },
}

export const getters = {
    availableKitsTranslated(state) {
        // eslint-disable-next-line no-undef
        const translatedLabels = structuredClone(state.availableKits)
        return translatedLabels.map(item => {
            item.label = translate(item.label)
            return item
        })
    },
}

export const actions = {
    async getKitsList({ commit }, queries) {
        commit('SET_KITS_LOADED', false)
        await kitService.getKits(queries).then(resp => {
            commit(
                'GET_KITS_LIST',
                resp.data.kits.map(item => {
                    return {
                        ...item,
                        state:
                            item.state === 'active'
                                ? translate('sm_0052_Active')
                                : item.state === 'amended'
                                ? translate('id_0142_amended')
                                : item.state === 'new'
                                ? translate('id_0110_new')
                                : item.state,
                        location:
                            item.location === 'customer'
                                ? translate('id_0048_customer')
                                : item.location === 'patient'
                                ? translate('w_patient')
                                : item.location,
                    }
                })
            )
            commit('SET_KITS_LOADED', true)
            commit('SET_TOTAL_KITS', resp.data.count)
        })
    },
    setKitsCurPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page)
    },
    setKitsSorting({ commit }, data) {
        commit('SET_KITS_SORTING', data)
    },
    setKitsFilter({ commit }, data) {
        commit('SET_KITS_FILTER', data)
    },
    setSearchQuery({ commit }, data) {
        commit('SET_SEARCH_QUERY', data)
    },
    setActiveKit({ commit }, data) {
        commit('SET_ACTIVE_KIT', data)
    },
    updateKitsColumns({ commit }, columns) {
        commit('UPDATE_KITS_COLUMNS', columns)
    },
}
