import { extend, configure } from 'vee-validate'
import {
    required,
    email,
    min,
    max,
    regex,
    length,
} from 'vee-validate/dist/rules'
import { i18n } from './i18n'

configure({
    defaultMessage: (field, values) => {
        // override the field name
        values._field_ = field
        return i18n.t(`${values._rule_}`, values)
    },
})

const regexPassword = {
    params: ['regexRule'],
    validate: (value, { regexRule }) => {
        return regexRule.test(value)
    },
}

// Add the required rule
extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('regex', regex)
extend('regexPassword', regexPassword)
extend('length', length)
