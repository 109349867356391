import store from '@state/store'

const errorResponseHandler = error => {
    store.dispatch('notifications/setError', {
        error: {
            url: error.config.url,
            method: error.config.method,
            data: error.config.data,
        },
    })
    return Promise.reject(error)
}

const applyBaseInterceptors = axiosInstance => {
    axiosInstance.interceptors.response.use(
        response => response,
        errorResponseHandler
    )
}

export default {
    use(axiosInstance) {
        applyBaseInterceptors(axiosInstance)
    },
}
