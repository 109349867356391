import moment from 'moment'

export function determineDate(condition, timestamp) {
    let today = moment(new Date().setHours(23, 59, 59, 999))
    let inputDate = moment(new Date(timestamp))
    if (condition === 'isToday') {
        return today.diff(inputDate, 'days') === 0
    } else if (condition === 'isYesterday') {
        return today.diff(inputDate, 'days') === 1
    } else if (condition === 'isLastWeek') {
        return (
            today.diff(inputDate, 'days') > 1 &&
            today.diff(inputDate, 'days') <= 7
        )
    }
}
