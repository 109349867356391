import Vue from 'vue'
import App from './app.vue'
import router from '@router'
import store from '@state/store'
import { $axios } from '@services/base-service'
import imageConvert from '@utils/images-converation'
import { i18n } from './i18n.js'

import '@/globalComponents'

Vue.prototype.$axios = $axios
Vue.prototype.$imageConvert = imageConvert

import './main.filters.js'
import './main.globals.js'
import './vee-validate.js'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// Global eventBus
Vue.prototype.$eventBus = new Vue()

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
