import { $axios } from '@services/base-service'
import AuthService from '@services/auth-service.js'
import { Role } from '@/constants/role.js'
import { getFiles } from '@services/base-service'
import { removeState, getLocale } from '@utils/user-info.js'
import store from '@state/store'
import router from '@router'
import { loadLanguageAsync } from '@/i18n'

export const state = {
    access_token: '',
    refresh_token: '',
    storage_token: '',
    websockets_token: '',
    role: '',
    isRememberMe: false,
    deleteAccountDate: {},
}

export const mutations = {
    // here we got user token and information
    SET_CURRENT_USER(
        state,
        { access_token, refresh_token, storage_token, websockets_token, role }
    ) {
        if (state.isRememberMe) {
            state.storage_token = storage_token
        }
        if (role) {
            state.role = role
        }
        if (websockets_token) {
            state.websockets_token = websockets_token
        }

        state.access_token = access_token
        state.refresh_token = refresh_token

        setDefaultAuthHeaders(state)
    },
    CHANGE_REMEMBER_ME(state) {
        state.isRememberMe = !state.isRememberMe
    },
    RESET_AUTH_HEADERS(state) {
        state.access_token = ''
        state.refresh_token = ''
        state.storage_token = ''
        state.websockets_token = ''
        setDefaultAuthHeaders(state)
    },
    SET_DELETE_ACCOUNT_DATE(state, data) {
        state.deleteAccountDate = {}
        state.deleteAccountDate = data
    },
    SET_AVATAR_ACCOUNT_DATE(state, payload) {
        state.deleteAccountDate.avatar_max = payload
    },
}

export const getters = {
    isAdmin(state) {
        return state.role === Role.admin
    },

    isDoctor(state) {
        return state.role === Role.doctor
    },

    isDistributor(state) {
        return state.role === Role.distributor
    },
    isManager(state) {
        return state.role === Role.manager
    },
    isOwner(state) {
        return state.role === Role.owner
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state }) {
        setDefaultAuthHeaders(state)

        // turn on if validation needed
        // dispatch('validate')
    },

    changeRememberMe({ commit }) {
        commit('CHANGE_REMEMBER_ME')
    },

    setDeleteAccountDate({ commit }, data) {
        commit('SET_DELETE_ACCOUNT_DATE', data)
    },

    setAvatarAccountDate({ commit }, data) {
        commit('SET_AVATAR_ACCOUNT_DATE', data)
    },

    // Logs in the current user.
    logIn({ commit }, data) {
        // turn on if validation needed
        return AuthService.login(data).then(response => {
            if (getLocale() !== response.data.language) {
                loadLanguageAsync(response.data.language)
            }
            store.commit('common/SAVE_LANG_TO_PROFILE', response.data.language)
            store.commit(
                'doctor/CHANGE_ENLARGED_MODE_STATE',
                response.data.magnifier
            )

            commit('SET_CURRENT_USER', response.data)
            return response
        })
    },

    deleteLogin({ commit }, data) {
        return AuthService.login(data).then(async response => {
            if (getLocale() !== response.data.language) {
                loadLanguageAsync(response.data.language)
            }
            let data = response.data
            commit('SET_CURRENT_USER', response.data)
            commit('SET_DELETE_ACCOUNT_DATE', data)
            if (data.avatar_max) {
                await getFiles(data.avatar_max).then(avatar => {
                    commit('SET_AVATAR_ACCOUNT_DATE', avatar)
                })
            }
        })
    },

    resetPassword({ commit }, data) {
        commit('SET_CURRENT_USER', data)
    },

    // Logs out the current user.
    logOut({ commit }, data) {
        removeState('subscriptionRequestUser')
        removeState('subscriptionReloadData')
        commit('RESET_STORE', {}, { root: true })
        if (data) {
            AuthService.logOutRequest()
        }
        localStorage.removeItem('task_administrator')
    },

    resetAuthHeaders({ commit }) {
        commit('RESET_AUTH_HEADERS')
    },
}

// ===
// Private helpers
// ===
// Working point variant

function headersDetection(headers) {
    if (
        headers['x-token-access'] &&
        headers['x-token-refresh'] &&
        headers['x-token-storage']
    ) {
        const access_token = headers['x-token-access']
        const refresh_token = headers['x-token-refresh']
        const storage_token = headers['x-token-storage']

        store.commit('auth/SET_CURRENT_USER', {
            access_token,
            refresh_token,
            storage_token,
        })
    }
}

$axios.interceptors.response.use(
    response => {
        if (response.status === -1) {
            console.log('---response.status', response.status)
        }
        if (response && response.headers) {
            headersDetection(response.headers)
        }

        return response
    },
    error => {
        if (error.response && error.response.status === -1) {
            console.error('---response.status', error.response.status)
        }
        const status = error.response ? error.response.status : 0
        const originalRequest = error.config

        if (status === 401) {
            if (!originalRequest._retry) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        if (
                            store.state.auth.access_token &&
                            originalRequest.headers['x-token-access'] !==
                                store.state.auth.access_token
                        ) {
                            originalRequest.headers['x-token-access'] =
                                store.state.auth.access_token
                            originalRequest.headers['x-token-refresh'] =
                                store.state.auth.refresh_token
                            if (store.state.auth.isRememberMe) {
                                originalRequest.headers['x-token-storage'] =
                                    store.state.auth.storage_token
                            }
                            originalRequest._retry = true
                            resolve($axios(originalRequest))
                        } else {
                            if (store.state.auth.access_token) {
                                store.dispatch('auth/logOut')
                                store.commit('auth/RESET_AUTH_HEADERS')
                                router.push({ path: '/login' })
                            } else {
                                reject()
                            }
                        }
                    }, 2000)
                })
            } else {
                if (store.state.auth.access_token) {
                    store.dispatch('auth/logOut')
                    store.commit('auth/RESET_AUTH_HEADERS')
                    router.push({ path: '/login' })
                }
            }
        } else if (error.response && error.response.headers) {
            headersDetection(error.response.headers)
        }

        return Promise.reject(error)
    }
)

function setDefaultAuthHeaders(state) {
    $axios.defaults.headers.common['x-token-access'] = state.access_token
        ? state.access_token
        : ''
    $axios.defaults.headers.common['x-token-refresh'] = state.refresh_token
        ? state.refresh_token
        : ''
    if (state.isRememberMe) {
        $axios.defaults.headers.common['x-token-storage'] = state.storage_token
            ? state.storage_token
            : ''
    }
}
