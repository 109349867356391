import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { $axios } from '@services/base-service'
import { getLocale, setLocale } from '@utils/user-info.js'
import engMessages from '@/lang/eng.json'
import languages from '@utils/languages'
import { DEFAULT_LANGUAGE } from '@constants/translations'

Vue.use(VueI18n)

const locale = getLocale() || DEFAULT_LANGUAGE

const i18n = new VueI18n({
    fallbackLocale: DEFAULT_LANGUAGE,
    formatFallbackMessages: true,
    pluralizationRules: {
        rus: choice => {
            const teen = choice > 9 && choice < 20
            let endNumber = choice
                .toString()
                .substr(choice.toString().length - 1, 1)

            if (choice === 1 || (endNumber === '1' && !teen)) {
                return 0
            }
            if (
                !teen &&
                (endNumber === '2' || endNumber === '3' || endNumber === '4')
            ) {
                return 1
            }
            return 2
        },
        ukr: choice => {
            const teen = choice > 9 && choice < 20
            let endNumber = choice
                .toString()
                .substr(choice.toString().length - 1, 1)

            if (choice === 1 || (endNumber === '1' && !teen)) {
                return 0
            }
            if (
                !teen &&
                (endNumber === '2' || endNumber === '3' || endNumber === '4')
            ) {
                return 1
            }
            return 2
        },
        pol: choice => {
            const teen = choice > 9 && choice < 20
            let endNumber = choice
                .toString()
                .substr(choice.toString().length - 1, 1)

            if (choice === 1) {
                return 0
            }
            if (
                !teen &&
                (endNumber === '2' || endNumber === '3' || endNumber === '4')
            ) {
                return 1
            }
            return 2
        },
        fra: choice => {
            if (choice === 0 || choice === 1) {
                return 1
            } else {
                return 0
            }
        },
    },
})

const loadedLanguages = []

const setI18nLanguage = lang => {
    setLocale(lang)
    languages.loadIviewi18n(lang)
    languages.loadMomenti18n(lang, loadedLanguages)
    i18n.locale = lang
    $axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
}

const loadLanguageAsync = async lang => {
    if (i18n.locale === lang) {
        // If the same language
        setI18nLanguage(lang)
    } else if (loadedLanguages.includes(lang)) {
        // If the language was already loaded
        setI18nLanguage(lang)
    } else {
        // If the language hasn't been loaded yet
        try {
            const messages = await import(
                /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`
            )
            const cleanedMessages = {}
            for (const item in messages.default) {
                if (
                    messages.default[item] &&
                    messages.default[item].trim() !== ''
                ) {
                    //non-breaking hyphen &#8209;
                    cleanedMessages[item] = messages.default[item].replace(
                        'E-mail',
                        'E‑mail'
                    )
                }
            }
            const messagesWithFallbackTranslation = Object.assign(
                {},
                engMessages,
                cleanedMessages
            )
            i18n.setLocaleMessage(lang, messagesWithFallbackTranslation)

            loadedLanguages.push(lang)
            setI18nLanguage(lang)
        } catch (e) {
            i18n.setLocaleMessage(DEFAULT_LANGUAGE, engMessages)
            setI18nLanguage(DEFAULT_LANGUAGE)
        }
    }
}

loadLanguageAsync(locale)

export { i18n, loadLanguageAsync }
