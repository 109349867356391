import axios from 'axios'
import BaseInterceptors from './interseptors/base-interseptors'

// logic to set your local or production domain
if (location.hostname === 'localhost') {
    // eslint-disable-next-line no-undef
    config.VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL
    // eslint-disable-next-line no-undef
    config.VUE_APP_CENTRIFUGE_URL = process.env.VUE_APP_CENTRIFUGE_URL
}

// eslint-disable-next-line no-undef
const baseDomain = `${config.VUE_APP_BASE_URL}`
const baseURL = `${baseDomain}/api/v1`
const callURL = `${baseDomain}/api/v2`

// TODO change after gateway BE implementation
const baseURLTOChange = baseURL

const $axios = axios.create({
    baseURL,
})

const $callAxios = axios.create({
    baseURL: callURL,
})

const axiosInstanceToChange = axios.create({
    baseURL: baseURLTOChange,
})

const axiosInstanceToChangeCall = axios.create({
    callURL: baseURLTOChange,
})

const getContacts = () => {
    const resource = '/users/profiles'
    return $axios.get(`${resource}`)
}

const getAllPatients = () => {
    const resource = `/chat/patients`
    return $axios.get(`${resource}`)
}

const getLanguagesList = () => {
    const resource = 'content/languages'
    return $axios.get(`${resource}`)
}

const getSpecialtiesList = () => {
    const resource = 'content/specializations'
    return $axios.get(`${resource}`)
}

const getCurrencyList = () => {
    const resource = `/content/currencies`
    return $axios.get(resource)
}
const getFiles = (url, size = false, onDownloadProgress) => {
    return $axios
        .get(url, {
            responseType: 'arraybuffer',
            onDownloadProgress: onDownloadProgress,
        })
        .then(response => {
            let blob = new Blob([response.data])
            let url = window.URL || window.webkitURL

            if (size) {
                return {
                    url: url.createObjectURL(blob),
                    blobSize: blob.size,
                }
            } else {
                return url.createObjectURL(blob)
            }
        })
}

const getFile = id => {
    const resource = `/data/files/${id}`
    return $axios.get(resource, { responseType: 'arraybuffer' })
}

const getUserProfile = () => {
    const resource = `/users/profiles`
    return $axios.get(resource)
}

const editUserProfile = (role, data) => {
    return $axios.put(`/users/${role}/edit`, data)
}

const putEmail = (token, data) => {
    return $axios.put(`/users/email?token=${token}`, data)
}

const putPassword = data => {
    return $axios.put(`users/password/update`, data)
}

const postAvatars = data => {
    return $axios.post(`/data/avatars`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': 'attachment',
        },
    })
}

const deletetAvatars = () => {
    return $axios.delete(`/data/avatars`)
}

const getFacility = () => {
    return $axios.get(`/medical-facility/doctor/facility`)
}

const postNewTasks = (filter_type, by_descending) => {
    return $axios.post(`/calendar/doctor/tasks/new`, filter_type, by_descending)
}

const getDoctorTasks = () => {
    return $axios.get(`/calendar/doctor/tasks/performed`)
}

const getDoctorTaskById = (id, administrator_string) => {
    return $axios.get(`/calendar/doctor/tasks/get/${id}${administrator_string}`)
}

const getDoctorGeneralTaskList = data => {
    return $axios.post(`calendar/doctor/tasks/list`, data)
}

const getHistoryById = id => {
    return $axios.get(`/calendar/doctor/tasks/history/${id}`)
}

const putTaskInfo = (id, data) => {
    return $axios.put(`/calendar/doctor/tasks/${id}`, data)
}

const postCallHistory = (id, data) => {
    return $axios.post(`/calendar/doctor/tasks/call-history/${id}`, data)
}

const getPerformerPatientList = role => {
    return $axios.get(`/users/doctor/search/users?query=&role=${role}`)
}

const getPerformerList = query => {
    return $axios.get(`users/doctor/search/users?role=doctor&query=${query}`)
}

const getPatientList = (facility_id, query) => {
    return $axios.get(
        `/users/doctor/search/users?facility_id=${facility_id}&for_task_list&query=${query}`
    )
}

const postUserProfile = data => {
    const resource = `/users/profiles`
    return $axios.post(resource, data)
}

const getEndingSubscriptions = () => {
    return $axios.get(`/doctor/subscriptions/ending?days=5`)
}

const getOwnerId = () => {
    return $axios.get(`/users/owner`)
}

BaseInterceptors.use($axios)
BaseInterceptors.use($callAxios)
BaseInterceptors.use(axiosInstanceToChange)
BaseInterceptors.use(axiosInstanceToChangeCall)

export {
    postCallHistory,
    postNewTasks,
    getDoctorTasks,
    getDoctorTaskById,
    getDoctorGeneralTaskList,
    getHistoryById,
    putTaskInfo,
    getPerformerPatientList,
    axiosInstanceToChange,
    axiosInstanceToChangeCall,
    getAllPatients,
    $axios,
    $callAxios,
    getContacts,
    getCurrencyList,
    getLanguagesList,
    getSpecialtiesList,
    getFiles,
    getFile,
    getUserProfile,
    editUserProfile,
    putEmail,
    putPassword,
    postAvatars,
    deletetAvatars,
    getPerformerList,
    getPatientList,
    getFacility,
    postUserProfile,
    getEndingSubscriptions,
    getOwnerId,
}

export default { $axios, $callAxios }
