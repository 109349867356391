import { $axios } from './base-service'

export default {
    createDistributor(data) {
        const resource = `users/distributor`
        return $axios.post(`${resource}`, data)
    },
    getDistributor() {
        const resource = `users/owner/distributor`
        return $axios.get(`${resource}`)
    },
    getFacilities(distributorId) {
        const resource = `owner/distributor/${distributorId}`
        return $axios.get(`${resource}`)
    },
    getAllFacilities(distributorId, from, to) {
        const resource = `owner/distributor/${distributorId}/statistics`
        let queries = ''
        if (!!from && !!to) {
            queries += `?from=${from}&to=${to}`
        } else if (!!from && !to) {
            queries += `?from=${from}`
        }
        return $axios.get(`${resource}${queries}`)
    },
    getFacilityById(id) {
        const resource = `/owner/facility/${id}`
        return $axios.get(`${resource}`)
    },
    generateReferralLink(id) {
        const resource = `/owner/facility/${id}/generate-doctor-link`
        return $axios.post(`${resource}`)
    },
    getCounters(id, from, to) {
        const resource = `/owner/counters`

        let idString = ''
        id.forEach((item, index) => {
            if (id.length - 1 > index) {
                idString += `${item},`
            } else {
                idString += item
            }
        })

        let queries = `?ids=${idString}&billing=monthly`
        if (!!from && !!to) {
            queries += `&from=${from}&to=${to}`
        } else if (!!from && !to) {
            queries += `&from=${from}`
        }
        return $axios.get(`${resource}${queries}`)
    },
    getManagerInfo(medicalId) {
        return $axios.get(`users/medical-facility-manager/${medicalId}`)
    },
    getFacilitySubscriptionData(id, year) {
        const resource = `/owner/facility/${id}/statistics?year=${year}`
        return $axios.get(resource)
    },
    changeDistributor(id, data) {
        const resource = `/owner/facility/${id}/change-distributor`
        return $axios.put(resource, data)
    },
}
