// TODO replace due to ISO code
export const DEFAULT_LANGUAGE = 'eng'
export const ENGLISH = 'eng'
export const POLISH = 'pol'
export const SPANISH = 'spa'
export const PORTUGUESE = 'por'
export const GERMAN = 'deu'
export const RUSSIAN = 'rus'
export const UKRAINIAN = 'ukr'
export const FRENCH = 'fra'
export const SLOVAK = 'slo'
export const ITALIAN = 'ita'
